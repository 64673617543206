import { types } from "../types/types";


export const alertaReducer = (state = {},action) => {

    switch (action.type){
    
        case types.alertaIncidenciaDiaAnterior:
            return{
                ...state,
                dataDiaAnterior : action.contingut.dataDiaAnterior,
                missatge_ca : action.contingut.missatge_ca,
                missatge_es : action.contingut.missatge_es,
                tipusMissatge: action.contingut.tipusMissatge, 
                existeixError: action.contingut.existeixError,
                hores :action.contingut.hores,
                minuts:action.contingut.minuts
            }
        default:
            return state;
    }

}