import React from 'react'
import { useTranslation } from 'react-i18next';
import { HeaderIdioma } from './HeaderIdioma';
import { LogoCapcaleraGlobal } from './LogoCapcaleraGlobal'

export const HeaderPublic = () => {
  const [t, i18n] = useTranslation('generic');
  // const [idioma, setIdioma] = useState('ca');

  // const changeLanguage = () => {
  //   if(idioma=='ca'){
  //     i18n.changeLanguage('es');
  //     setIdioma('es');
  //   }else{
  //     i18n.changeLanguage('ca');
  //     setIdioma('ca');
  //   }
  // }
    return (
      
      <header id="header" className="navbar navbar-height navbar-light mb-3">
     
      <div className="container">   
    
        <LogoCapcaleraGlobal />
        <HeaderIdioma />
        
        </div>
        </header>
    )
}
