import { types } from "../types/types"
import axios from 'axios'
import { crearErrorAction,crearErrorTotOkAction,crearTotOkAction, uiAmagarJornadaCompletaAction, uiJornadaCompletaRealitzadaAction } from "./ui"
import { convertirHoresaSegons } from "../Helpers/funcionsDates"





export const recuperarIncidenciesDiaConsultat = (token,data) =>{
    //Aquesta funció (Middleware), retorna un callback. Just quan finalitza la petició, execitarà l'acció 
    return (dispatch) =>{   
        var dadesFormulari = {
            'dataConsulta': data
        };
        
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody
            };        
        fetch(process.env.REACT_APP_DOMINI_API_PORTALCOMB + '/incidencia/dia/list',requestOptions)

        .then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json();
           
            if (response.status != 200) {
                // get error message from body or default to response status
                //const error = (data && data.message) || response.status;
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
            }else
            {            
                //Fem el dispatch de l'acció login amb les dades rebudes del servei
                if(data.dia !=undefined)
                {
                    let { dia,incidencies } = data;
                    dispatch(IncidenciaDiaConsultat(data));
                }else
                {
                    //El servei d'aunteticació reporta un error de login
                    
                }  
            }
            //element.innerHTML = data.id;
        })
        .catch(error => {
           // element.parentElement.innerHTML = `Error: ${error}`;
            dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
            console.error('There was an error!', error);
        });
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
       
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


} 
export const recuperarFixatgesDiaConsultat = (token,data) =>{
    //Aquesta funció (Middleware), retorna un callback. Just quan finalitza la petició, execitarà l'acció 
    return (dispatch) =>{   
        var dadesFormulari = {
            'dataConsulta': data
        };
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody
            };
            
        fetch(process.env.REACT_APP_DOMINI_API_PORTALCOMB + '/fixatge/dia/list',requestOptions)

        .then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json();
           
            if (response.status != 200) {
                // get error message from body or default to response status
                //const error = (data && data.message) || response.status;
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
            }else
            {             
                //Fem el dispatch de l'acció login amb les dades rebudes del servei
                //data.ok
                if(data.dia !=undefined)
                {
                    let { dia,incidencies } = data;
                    dispatch(FixatgeDiaConsultat(data));
                }else
                {
                    //El servei d'aunteticació reporta un error de login
                    
                }  
            }
            //element.innerHTML = data.id;
        })
        .catch(error => {
           // element.parentElement.innerHTML = `Error: ${error}`;
            dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
            console.error('There was an error!', error);
        });
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
       
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


} 


 export const  recuperarFixatgeAxios =   (token) => {
    return async dispatch =>{
        
        const peticioApi = axios.create({
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json', 'X-token-portalcomb' : token  },
            withCredentials: true,
            baseURL: 'https://www.portalcomb.cat.pleskdom.org/pc-api'
         })
        
               
            peticioApi.get(`/fixatge/dia/list` )
            .then(response => {
                
              
              let data = response.data;
             
              //new Promise((resolve, reject) => setTimeout(() => resolve(), 1500));
              
              // check for error response
              if (response.status != 200) {
                  // get error message from body or default to response status
                  //const error = (data && data.message) || response.status;
                  //dispatch(crearErrorAction("Error del servei de recollida de marcatges: " +  JSON.stringify(data)));
                  return Promise.resolve(false);
                  
              }else
              {
                  //Fem el dispatch de l'acció login amb les dades rebudes del servei
                  if(data.ok)
                  {
                      let { token } = data;
                      let { idT,idE,nom,cognom,sexe  } = data.contingut;
                      /*
                      dispatch(recuperarLogin(token,idT,idE,nom,cognom,sexe));
                      dispatch(uiFinalitzarCarregaAction());
                      */
                      return Promise.resolve(true);
                  }else
                  {
                      //El servei d'aunteticació reporta un error de login
                      //dispatch(crearErrorAction(data.msg));
                      //dispatch(uiFinalitzarCarregaAction());

                      return Promise.resolve(false);
                  }  
              }

              //dispatch(uiFinalitzarCarregaAction());
              //element.innerHTML = data.id;
          })
          .catch(error => {
             // element.parentElement.innerHTML = `Error: ${error}`;
             // dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
              console.error('There was an error!', error);
              //dispatch(uiFinalitzarCarregaAction());
              return Promise.reject()
          });
            //TODO - Crear un fitxer on guardem constants segons l'entorn 
       
      
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


}
export const recuperarFixatgesAsync = (token) =>{
    //Aquesta funció (Middleware), retorna un callback. Just quan finalitza la petició, execitarà l'acció 
    return (dispatch) =>{
        
        //dispatch(uiIniciarCarregaAction());      
                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
        
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                mode: 'cors'
            };
            //TODO - Crear un fitxer on guardem constants segons l'entorn 
        fetch( process.env.REACT_APP_DOMINI_API_PORTALCOMB + '/fixatge/dia/list',requestOptions)
            .then(async response => {
                
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                
        
                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    //const error = (data && data.message) || response.status;
                    //dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                   
                    let { fixatges } = data;
                    if(fixatges !== undefined && fixatges.length >0)
                    {
                       
                        if(fixatges[fixatges.length -1].IdTipus_Fixatge === "1" || fixatges[fixatges.length -1].IdTipus_Fixatge === "2")
                        {
                            dispatch(JornadaFinalitzada(false));
                            dispatch(JornadaIncidencia(false));
                            dispatch(JornadaIniciada(true));
                        }else if(fixatges[fixatges.length -1].IdTipus_Fixatge === "3" || fixatges[fixatges.length -1].IdTipus_Fixatge === "4")
                        {
                            dispatch(JornadaFinalitzada(true));
                            dispatch(JornadaIncidencia(false));
                            dispatch(JornadaIniciada(false));
                        }else
                        {
                            dispatch(JornadaFinalitzada(false));
                            dispatch(JornadaIncidencia(true));
                            dispatch(JornadaIniciada(false));
                        }
                         //Aquest estat ens serveix per el contador d'hores de la jornada.
                    }else
                    {
                            dispatch(JornadaFinalitzada(false));
                            dispatch(JornadaIncidencia(false));
                            dispatch(JornadaIniciada(false));
                    }
                    dispatch(ConsultaFixatgeJornada(fixatges));
                }

                //dispatch(uiFinalitzarCarregaAction());
                //element.innerHTML = data.id;
            })
            .catch(error => {
               // element.parentElement.innerHTML = `Error: ${error}`;
               // dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
                console.error('There was an error!', error);
               // dispatch(uiFinalitzarCarregaAction());
            });
      
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


}
export const recuperarIncidenciesAsync = (token) =>{
    
    //Aquesta funció (Middleware), retorna un callback. Just quan finalitza la petició, execitarà l'acció 
    return (dispatch) =>{
        
        //dispatch(uiIniciarCarregaAction());      
                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
        
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                mode: 'cors'
            };
            //TODO - Crear un fitxer on guardem constants segons l'entorn 
        fetch( process.env.REACT_APP_DOMINI_API_PORTALCOMB + '/incidencia/dia/list',requestOptions)
            .then(async response => {
                
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                
        
                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    //const error = (data && data.message) || response.status;
                    //dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    let { incidencies } = data;
                    dispatch(ConsultaIncidenciaJornada(incidencies));
                }

                //dispatch(uiFinalitzarCarregaAction());
                //element.innerHTML = data.id;
            })
            .catch(error => {
               // element.parentElement.innerHTML = `Error: ${error}`;
                dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
                //console.error('There was an error!', error);
               // dispatch(uiFinalitzarCarregaAction());
            });
      
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


}

//mira aquet incidencia
export const addFixatgeAsync = (tipusFixatge,token,idioma='ca',saltaPortalComb = '1') =>{
 
    return (dispatch) =>{
        //dispatch(uiIniciarCarregaAction());      
        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
        var dadesFormulari = {
            'tipusFixatge': tipusFixatge
        };
        //Preparem els parametres post per enviar
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
        
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody
            };
            //TODO - Crear un fitxer on guardem constants segons l'entorn 
        fetch(process.env.REACT_APP_DOMINI_API_PORTALCOMB + '/fixatge/add',requestOptions)
            .then(async response => {
              
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
               
                //Anem a recuperar els fixatges, després de fer la crida al servei add
                dispatch(recuperarFixatgesAsync(token));
        
                // check for error response
                if (!response.ok) {

                    // get error message from body or default to response status
                    //const error = (data && data.message) || response.status;
                    //dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    //Fem el dispatch de l'acció login amb les dades rebudes del servei
                    if(data.ok)
                    {
                        //window.location.href = "https://oficinavirtual2.comb.cat/spog/virtualoffice/bookmark";
                        if(tipusFixatge == 1 || tipusFixatge == 2 ){
                            if(saltaPortalComb ==1 ){
                                //Fem que trigui 3 segons, per veure el missatge de que tot OK
                                setTimeout(function() { window.open('https://www.portalcomb.cat/', '_blank'); }, 2000);

                                
                            }
                        }
                        if(idioma =="ca"){
                            dispatch(crearTotOkAction(data.msg));
                        }else if(idioma =="es"){
                            dispatch(crearTotOkAction(data.msg_es));
                        }
                        
                        
                        //let { token } = data;
                        //let { idT,idE,nom,cognom,sexe  } = data.contingut;
                        //dispatch(login(token,idT,idE,nom,cognom,sexe));
                    }else
                    {
                        if(idioma =="ca"){
                            dispatch(crearErrorTotOkAction(data.msg));
                        }else if(idioma=="es"){
                            dispatch(crearErrorTotOkAction(data.msg_es));
                        }
                        //El servei d'aunteticació reporta un error de login
                        //dispatch(crearErrorAction(data.msg));
                    }  
                }

                //dispatch(uiFinalitzarCarregaAction());
                //element.innerHTML = data.id;
            })
            .catch(error => {
                
               // element.parentElement.innerHTML = `Error: ${error}`;
                //dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
                console.error('There was an error!', error);
                //dispatch(uiFinalitzarCarregaAction());
            });
      
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


}
export const recuperarFixatgesSetmanaActual = (token) =>{
    //Aquesta funció (Middleware), retorna un callback. Just quan finalitza la petició, execitarà l'acció 
    return (dispatch) =>{
        //dispatch(ConfigUsuariRecuperarInfoIniciat(true));
        //dispatch(uiIniciarCarregaAction());      
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                mode: 'cors'
            };
            //TODO - Crear un fitxer on guardem constants segons l'entorn 
        fetch( process.env.REACT_APP_DOMINI_API_PORTALCOMB + '/fixatge/setmana/actual',requestOptions)
            .then(async response => {
               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                
                
                // check for error response
                if (!response.ok) {
                    
                    // get error message from body or default to response status
                    //const error = (data && data.message) || response.status;
                    //dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    if(data.ok)
                    {
                       
                        dispatch(ConsultarFixatgesSetmanaActualFinalitzat(true,data.contingut));
                    }else
                    {

                    }
                }

                //dispatch(uiFinalitzarCarregaAction());
                //element.innerHTML = data.id;
            })
            .catch(error => {
               // element.parentElement.innerHTML = `Error: ${error}`;
               // dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
                console.error('There was an error!', error);
               // dispatch(uiFinalitzarCarregaAction());
            });
      
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


}
export const recuperarIncidenciesSetmanaActual = (token) =>{
    //Aquesta funció (Middleware), retorna un callback. Just quan finalitza la petició, execitarà l'acció 
    return (dispatch) =>{
        //dispatch(ConfigUsuariRecuperarInfoIniciat(true));
        //dispatch(uiIniciarCarregaAction());      
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                mode: 'cors'
            };
            //TODO - Crear un fitxer on guardem constants segons l'entorn 
        fetch( process.env.REACT_APP_DOMINI_API_PORTALCOMB + '/incidencia/setmana/actual',requestOptions)
            .then(async response => {
               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                
                
                // check for error response
                if (!response.ok) {
                    
                    // get error message from body or default to response status
                    //const error = (data && data.message) || response.status;
                    //dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    if(data.ok)
                    {
                       
                        dispatch(ConsultarIncidenciesSetmanaActual(true,data.contingut));
                    }else
                    {

                    }
                }

                //dispatch(uiFinalitzarCarregaAction());
                //element.innerHTML = data.id;
            })
            .catch(error => {
               // element.parentElement.innerHTML = `Error: ${error}`;
               // dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
                console.error('There was an error!', error);
               // dispatch(uiFinalitzarCarregaAction());
            });
      
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


}





export const iniciConsultaFixatgeJornada = () => {
    return async (dispatch) =>{
        
       // dispatch(desloginarAction());
        //Crear l'acció per desloginar l'usuari via API
        //dispatch(logout());
    }
}
export const JornadaComplerta = (tipusFixatge,token,idioma='ca',saltaPortalComb = '1') =>{
    //Aquesta funció (Middleware), retorna un callback. Just quan finalitza la petició, execitarà l'acció 
    return (dispatch) =>{   
        var dadesFormulari = {
            'tipusFixatge': tipusFixatge
        };
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody
            };
            
        fetch(process.env.REACT_APP_DOMINI_API_PORTALCOMB + '/fixatge/complet',requestOptions)

        .then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json();

            //Anem a recuperar els fixatges, després de fer la crida al servei add
            dispatch(recuperarFixatgesAsync(token));
            dispatch(uiAmagarJornadaCompletaAction())  
           
            if (response.status != 200) {
                // get error message from body or default to response status
                //const error = (data && data.message) || response.status;
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
            }else
            {     
                if(data.ok)
                    {
                        dispatch(uiAmagarJornadaCompletaAction())  
                        //window.location.href = "https://oficinavirtual2.comb.cat/spog/virtualoffice/bookmark";
                        if(tipusFixatge == 1 || tipusFixatge == 2 ){
                            if(saltaPortalComb ==1 ){
                                //Fem que trigui 3 segons, per veure el missatge de que tot OK
                                setTimeout(function() { window.open('https://www.portalcomb.cat/', '_blank'); }, 2000);     
                            }
                        }
                        if(idioma =="ca"){
                            dispatch(crearTotOkAction(data.msg));
                        }else if(idioma =="es"){
                            dispatch(crearTotOkAction(data.msg_es));
                        }
                        
                        
                        //let { token } = data;
                        //let { idT,idE,nom,cognom,sexe  } = data.contingut;
                        //dispatch(login(token,idT,idE,nom,cognom,sexe));
                    }else
                    {
                        if(idioma =="ca"){
                            dispatch(crearErrorTotOkAction(data.msg));
                        }else if(idioma=="es"){
                            dispatch(crearErrorTotOkAction(data.msg_es));
                        }
                        //El servei d'aunteticació reporta un error de login
                        //dispatch(crearErrorAction(data.msg));
                    }   
            }
            //element.innerHTML = data.id;
        })
        .catch(error => {
           // element.parentElement.innerHTML = `Error: ${error}`;
            dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
            console.error('There was an error!', error);
        });
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
       
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


} 

//Quan finalitza la crida del servei correctament, marquem l'estat cpConsultarFixatgeJornada
export const ConsultaFixatgeJornada = (llistatFixatges) =>{
    return {
        type: types.cpConsultarFixatgeJornada,
        llistatFixatges
    }

}

export const IncidenciaDiaConsultat = (incidenciaDiaConsultat) =>{
    return {
        type: types.cpDiaIncidenciaConsultat,
        incidenciaDiaConsultat
    }

}
export const FixatgeDiaConsultat = (fixatgeDiaConsultat) =>{
    return {
        type: types.cpDiaFixatgeConsultat,
        fixatgeDiaConsultat
    }

}

export const ConsultaIncidenciaJornada = (llistatIncidencies) =>{
    return {
        type: types.cpConsultarIncidenciaJornada,
        llistatIncidencies
    }

}

export const JornadaIniciada = (iniciada) =>{
    return {
        type: types.cpJornadaIniciada,
        iniciada
    }

}

export const JornadaFinalitzada = (finalitzada) =>{
    return {
        type: types.cpJornadaFinalitzada,
        finalitzada
    }

}

export const JornadaIncidencia = (incidencia) =>{
    return {
        type: types.cpJornadaIncidencia,
        incidencia
    }

}

export const ConsultarFixatgesSetmanaActualFinalitzat = (finalitzat,llistatFixatgesSetmana) =>{
    return {
        type: types.cpConsultarFixatgeSetmanaActual,
        finalitzat,
        llistatFixatgesSetmana
    }
    
}
export const ConsultarIncidenciesSetmanaActual = (finalitzat,llistatIncidenciesSetmana) =>{
    return {
        type: types.cpConsultarIncidenciesSetmanaActual,
        finalitzat,
        llistatIncidenciesSetmana
    }
    
}
