import { types } from "../types/types";

export const authReducer = (state = {},action) => {

    switch (action.type){
        case types.login:
            return{
                ...state,
                tokenJwt : action.contingut.tokenJwt,
                idT : action.contingut.idT,
                idE : action.contingut.idE,
                nom: action.contingut.nom, 
                cognom: action.contingut.cognom,
                sexe: action.contingut.sexe,
                admin: action.contingut.admin,
                resp: action.contingut.resp
            }
            break;
        case types.logout:
            return{
                
            }
            break;
        case types.recuperarLogin:
            return{
                ...state,
                tokenJwt : action.contingut.tokenJwt,
                idT : action.contingut.idT,
                idE : action.contingut.idE,
                nom: action.contingut.nom, 
                cognom: action.contingut.cognom,
                sexe: action.contingut.sexe,
                admin: action.contingut.admin,
                resp: action.contingut.resp
            }
        case types.renovarToken:
                return{
                    ...state,
                    tokenJwt : action.contingut.tokenJwt,
                    idT : action.contingut.idT,
                    idE : action.contingut.idE,
                    nom: action.contingut.nom, 
                    cognom: action.contingut.cognom,
                    sexe: action.contingut.sexe,
                    admin: action.contingut.admin,
                    resp: action.contingut.resp
                }
        default:
            return state;
    }

}