//Aquestes accions, com son sincrones, es poden executar directament

import { types } from "../types/types"

export const crearErrorAction = ( missatgeError) =>{
    return {
        type: types.uiCrearError,
        payload: missatgeError
    }

}
export const uiAmagarCalendarModal = () =>{
    return {
        type: types.uiAmagarCalendarModal,
    }

}
export const uiMostraCalendarModal = () =>{
    return {
        type: types.uiMostraCalendarModal,
    }

}
export const crearTotOkAction = ( missatgeOk) =>{
    return {
        type: types.uiCrearOk,
        payload: missatgeOk
    }

}
export const esborrarOkAction = () =>{
    return {
        type: types.uiEsborrarOk
    }

}
export const crearErrorTotOkAction = ( missatgeOk) =>{
    return {
        type: types.uiCrearOkError,
        payload: missatgeOk
    }

}
export const esborrarErrorOkAction = () =>{
    return {
        type: types.uiEsborrarOkError
    }

}

export const esborrarErrorAction = () =>{
    return {
        type: types.uiEsborrarError
    }

}
export const uiIniciarCarregaAction = ( ) =>{
    return {
        type: types.uiIniciarCarrega,
        payload: true
    }
}

export const uiFinalitzarCarregaAction = ( ) =>{
    return {
        type: types.uiFinalitzarCarrega,
        payload: false
    }
}

export const uiMostraIncidenciesAction = ( ) =>{
    return {
        type: types.uiMostraIncidencies,
        payload: true
    }
}

export const uiAmagarIncidenciesAction = ( ) =>{
    return {
        type: types.uiAmagarIncidencies,
        payload: false
    }
}

export const uiMostraJornadaCompletaAction = ( ) =>{
    return {
        type: types.uiMostraJornadaCompleta,
        payload: true
    }
}

export const uiAmagarJornadaCompletaAction = ( ) =>{
    return {
        type: types.uiAmagarJornadaCompleta,
        payload: false
    }
}
export const uiMostraIncidenciesRestaHoresAction = ( ) =>{
    return {
        type: types.uiMostraIncidenciesRestaHores,
        payload: true
    }
}

export const uiMostraIncidenciesRestaHoresFiJornadaAction = ( ) =>{
    return {
        type: types.uiMostraIncidenciesRestaHoresFiJornada,
        payload: true
    }
}

export const uiAmagarIncidenciesRestaHoresAction = ( ) =>{
    return {
        type: types.uiAmagarIncidenciesRestaHores,
        payload: false
    }
}


export const uiAmagarIncidenciesRestaHoresFiJornadaAction = ( ) =>{
    return {
        type: types.uiAmagarIncidenciesRestaHoresFiJornada,
        payload: false
    }
}
export const uiMostraDiaConsultatAction = (dia ) =>{
    return {
        type: types.uiMostraDiaConsultat,
        payload: dia
    }
}

export const uiAmagarDiaConsultatAction = ( ) =>{
    return {
        type: types.uiAmagarDiaConsultat,
        payload: false
    }
}

export const uiResponsable = (resp) =>{
    return {
        type: types.uiResponsable,
        payload: resp
        
    }
}



