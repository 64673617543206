export const types = {

    login : '[auth] Login',
    logout : '[auth] Logout',
    recuperarLogin: '[auth] Iniciar Recuperar Sessió Login',
    recuperarLoginFinish: '[auth] Fi Recuperar Sessió Login',
    renovarToken : '[auth] Renovar Token Usuari',


    uiCrearError: '[UI] Crear Error',
    uiCrearOk: '[UI] Crear tot Ok',
    uiEsborrarOk: '[UI] Esborrar Ok',
    uiCrearOkError: '[UI] Crear tot Ok Error',
    uiEsborrarOkError: '[UI] Esborrar Ok Eroor',
    uiEsborrarError: '[UI] Esborrar Error',
    uiIniciarCarrega: '[UI] Iniciar càrrega',
    uiFinalitzarCarrega: '[UI] Finalitzar càrrega',
    uiMostraIncidencies:'[UI] Mostra Incidencia',
    uiAmagarIncidencies:'[UI] Amagar Incidencies',
    uiMostraJornadaCompleta:'[UI] Mostra JornadaCompleta',
    uiAmagarJornadaCompleta:'[UI] Amagar JornadaCompleta',
    uiMostraIncidenciesRestaHores:'[UI] Mostra Incidencia Resta Hores',
    uiMostraIncidenciesRestaHoresFiJornada:'[UI] Mostra Incidencia Resta Hores Final Jornada',
    uiAmagarIncidenciesRestaHores:'[UI] Amagar Incidencies Resta Hores ',
    uiAmagarIncidenciesRestaHoresFiJornada:'[UI] Amagar Incidencies Resta Hores Final Jornada',
    uiMostraDiaConsultat:'[UI] Mostra DiaConsultat',
    uiAmagarDiaConsultat:'[UI] Amagar DiaConsultat',
    uiMostraCalendarModal:'[UI] Mostra Modal Detall Dia Calendar',
    uiAmagarCalendarModal:'[UI] Amagar Modal Detall Dia Calendar',
    uiResponsable:'[UI] Responsable',

    
    alertaIncidenciaDiaAnterior:'[Alerta] Mostra Incidencia Dia Anterior',


    calendariIncidenciaSemanaConsultada:'[Calendari]  Incidencia Setmana Consultada',
    calendariIncidenciaMesConsultat:'[Calendari]  Incidencia Mes Consultat',
    calendariFixatgesSemanaConsultada:'[Calendari]  Fixatges Setmana Consultada',
    calendariFixatgesMesConsultat:'[Calendari]  Fixatges Mes Consultat',
    calendariInfoFixatgesMesConsultat:'[Calendari]  Info Fixatges Mes Consultat',
    calendariVacancesMesConsultat:'[Calendari]  Vacances Mes Consultat',
    calendariVacancesInfo:'[Calendari]  Vacances Info ',
    calendariIniciMesConsultat:'[Calendari]  Inici Mes Consultat',
    calendariFinalMesConsultat:'[Calendari]  Fianl Mes Consultat',
 
    


    cpJornadaIniciada: '[CP] Jornada iniciada?',
    cpJornadaFinalitzada: '[CP] Fi Jornada',
    cpJornadaIncidencia: '[CP] Inici / Fi Jornada amb un estat incidencia',
    cpVerificarEstatJornada: '[CP] Verificar estat jornada',
    cpConsultarFixatgeJornada: '[CP] Consultar Fixatge Jornada',
    cpConsultarIncidenciaJornada: '[CP] Consultar Incidencia Jornada',
    cpConsultarFixatgeSetmanaActual: '[CP] Consultar Fixatges Setmana Actual',
    cpConsultarIncidenciesSetmanaActual: '[CP] Consultar Incidencies Setmana Actual',
    cpDiaIncidenciaConsultat: '[CP] Dia Incidencia Consultat',
    cpDiaFixatgeConsultat: '[CP] Dia Fixatge Consultat',



    configUsuariRecuperarInfo: '[ConfigUsuari] Iniciar recuperar Informació de usuari al servei',
    configUsuariRecuperarInfoFinalitzada: '[ConfigUsuari] Fi Recuperació Informació de usuari al servei',
    
    configAplicacioRecuperarInfo: '[ConfigAplicacio] Iniciar recuperar Informació de configuració de l\'aplicació',
    configAplicacioRecuperarDicIncidencies: '[ConfigAplicacio] Iniciar recuperar Informació DIC Incidencies',
    configAplicacioRecuperarDicIncidenciaCalendari: '[ConfigAplicacio] Iniciar recuperar Informació DIC Incidencies Calendari',
    configAplicacioRecuperarInfoFinalitzada: '[ConfigAplicacio] Fi Informació de configuració de l\'aplicació',

    
}

// reeucers store actions