import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { desloginarAction, iniciarPeticioLoginAdminAsincronaAxios, iniciDesloginar } from '../../actions/auth';
import { recuperarInfoVacancesEntreDates, recuperarVacancesEntreDates } from '../../actions/configCalendariAction';
import { uiResponsable } from '../../actions/ui';
import { RecuperarInfoVacances } from '../CalendariVacances/RecuperarInfoVacances';

export const LoginResp = () => {
    const dispatch = useDispatch(); //Per despachar accions
    const state = useSelector(state => state); // Això retorna l'esta actual de l'aplicació, per poder accedir a tot el contingut emmagatzemat allà
    const { ui } = useSelector(state => state); // Per desestructuració d¡objectes, podem agafar l'objecte desitjat del nostre state
    const { loading, msgError } = useSelector(state => state.ui); //Per desestructuració, podem agafar propietats d'objectes directament
    const { auth } = useSelector(state => state);
    //Farem servir useEffect. Es una eina de REact que permet executar una acció automàticament, afegint una condició de dependencia d'estat o no
    const [primerCop, setPrimerCop] = useState(true);       
    const [msgError2, setMsgError2] = useState(false);   
    const navegacio = useNavigate();
    let params = useParams();
    console.log(params);
   

    useEffect(() => {
        if(params.tokenAcces === "1" ){
           
            dispatch(uiResponsable(true));
            navegacio('/calendariVacances');
  
          }
        

       
      }, [params]);
      
    useEffect(() => {
        //Mirem si la propietat estaLoginat canvia. Si es així, redireccionem a la part privada
       if(auth.tokenJwt !== undefined)
       {
      
        navegacio('/');
        setMsgError2(false);
       }
       
      }, [auth]);

  return (
    <div>LoginResp</div>
  )
}
