import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import Moment from 'react-moment'
import 'moment-timezone';
import {useTranslation} from "react-i18next";

import { useDispatch, useSelector } from 'react-redux';
import { recuperarFixatgesAsync, recuperarIncidenciesAsync } from '../../actions/controlPresenciaActions';
import { TipusFixatge } from './TipusFixatge';


export const LlistatFixatgesDia = (props) => {


const dispatch = useDispatch(); //Per despachar accions
const state = useSelector(state => state); // Això retorna l'esta actual de l'aplicació, per poder accedir a tot el contingut emmagatzemat allà
const { ui } = useSelector(state => state); // Per desestructuració d¡objectes, podem agafar l'objecte desitjat del nostre state
const { loading, msgError } = useSelector(state => state.ui); //Per desestructuració, podem agafar propietats d'objectes directament
const { auth } = useSelector(state => state);
const { cp } = useSelector(state => state);
const [fiRevisarFixatges, setfiRevisarFixatges] = useState(false);
const [t, i18n] = useTranslation('generic');
let listItems = "";


const {dataAvui} = (props); 

useEffect(() => {
    dispatch(recuperarFixatgesAsync(auth.tokenJwt));
    dispatch(recuperarIncidenciesAsync(auth.tokenJwt));
    setfiRevisarFixatges(true);
  }, [auth]);

//

if(fiRevisarFixatges === false)
{
   return(
       <h3>Estem revisant els fixatges diaris... </h3>
   )

}else{

    if(cp.llistatFixatges !== undefined){
  
        listItems = cp.llistatFixatges.map((d) => 
        <li className='list-checked-item' key={d.IdFixatge}>
            <TipusFixatge idTipusFixatge={Number(d.IdTipus_Fixatge)} idTipusIncidencia={Number(d.IdTipusIncidencia_Fixatge)} /> - <Moment format="HH:mm" date={d.DataHora_Fixatge} /> 
           
        </li>);
       
    }

    //const listItems = cp.llistatFixatges.map((d) => <li key={d.DataHora_Fixatge}>{d.DataHora_Fixatge}</li>);
   
}

    return (
        <>
             <hr />
             <h4 className="card-title mt-3">{t('ControlPresencia_LlistatFixatgesDia.title')} <Moment format="DD/MM/YYYY" date={dataAvui} />  </h4>
            <ul className='list-checked list-checked-soft-bg-primary list-checked-lg mb-6 mt-4'>
                { listItems }
            </ul>
        
        </>
    )


}


LlistatFixatgesDia.propTypes = {
    dataAvui: PropTypes.instanceOf(Date)
}
