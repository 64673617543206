import { types } from "../types/types";


export const calendariReducer = (state = {},action) => {

    switch (action.type){
    
        case types.calendariIncidenciaSemanaConsultada:
            return{
                ...state,
                llistatIncidenciaSemanaConsultada: action.llistatIncidenciaSemanaConsultada             
            }

        case types.calendariIncidenciaMesConsultat:
            return{
                ...state,
                llistatIncidenciaMesConsultat: action.llistatIncidenciaMesConsultat             
            }

        case types.calendariFixatgesSemanaConsultada:
            return{
                ...state,
                llistatFixatgesSemanaConsultada: action.llistatFixatgesSemanaConsultada             
            }

        case types.calendariFixatgesMesConsultat:
            return{
                ...state,
                llistatFixatgesMesConsultat: action.llistatFixatgesMesConsultat             
            }
        case types.calendariInfoFixatgesMesConsultat:
            return{
                ...state,
                infoFixatgesMesConsultat: action.infoFixatgesMesConsultat             
            }
        case types.calendariVacancesMesConsultat:
            return{
                ...state,
                vacancesMesConsultat: action.vacancesMesConsultat             
            }
        case types.calendariVacancesInfo:
            return{
                ...state,
                vacancesInfo: action.vacancesInfo             
            }
        case types.calendariIniciMesConsultat:
            return{
                ...state,
                inici: action.inici             
            }
        case types.calendariFinalMesConsultat:
            return{
                ...state,
                final: action.final             
            }
        default:
            return state;
    }

}