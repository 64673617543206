
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FormulariIncidencia } from './FormulariIncidencia';
import 'moment-timezone';
import { alertaIncidenciaDiaAnterior } from '../../actions/configIncidenciaAction';
import Modal from 'react-modal';




export const ModalDiaAnterior = () => {
    const [t, i18n] = useTranslation('generic');
    const { alerta } = useSelector(state => state);
    const dispatch = useDispatch();
    const [retu, setRetu] = useState(false);
    //console.log("ueo")
    useEffect(() => {
      if (alerta.existeixError) {
  
        setRetu(true)
      }
    }, [alerta]);
  
    const onclick = (e) => {
      setRetu(false)
      dispatch(alertaIncidenciaDiaAnterior()) 
  }
                  
  const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
};


const closeModal = () => {
    // TODO: cerrar el modal
   
}


  if (alerta.existeixError != undefined && retu == true) {

    return (
      <div>
             <Modal id="modalDiaAnterior"
          isOpen={ true }
          onRequestClose={ closeModal }
          style={ customStyles }
          closeTimeoutMS={ 200 }
          className="modal fade bd-example-modal-xl show"
          ariaHideApp={false}
          overlayClassName="modal-fondo"
        >
              <button type="button" onClick={onclick} className="btn-close align-right" data-bs-dismiss="modal" aria-label="Close"></button>
              <div className='modal-body'>
               <h3 className='mt-3 text-aqua' >{alerta.missatge_ca} </h3>
            
           
           
              <FormulariIncidencia diaConsultat={alerta.dataDiaAnterior} hores={alerta.hores} minuts={alerta.minuts} />
              </div>


        </Modal>
              
            
           
          
        </div>

    );
  } else {
    return (
      <></>
    )

  }
}
