import React, { useEffect, useState } from 'react'
import {useTranslation} from "react-i18next";

import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment'
import 'moment-timezone';
import { recuperarFixatgesSetmanaActual, recuperarIncidenciesSetmanaActual } from '../../actions/controlPresenciaActions';
import { ContadorJornadaHoresSetmana } from './ContadorJornadaHoresSetmana';
import {convertirSegonsAMinuts, convertirHoresaSegons,convertirSegonsaHoresSenseFormat24 } from '../../Helpers/funcionsDates'
import { ResumTotalHoresSetmana } from './ResumTotalHoresSetmana';


export const HoresTotalsSetmana = () => {
    const dispatch = useDispatch(); //Per despachar accions
    const state = useSelector(state => state);
    const { configUsuari } = useSelector(state => state);
    const { cp,ui } = useSelector(state => state);
    const { incidenciaRestaHores,incidenciaRestaHoresFiJornada} = ui;
    const { llistatFixatgesSetmana} = cp;
    const { auth } = useSelector(state => state);
    const [t, i18n] = useTranslation('generic');
    let hores = "35";
    let diesSetmanaLlista;
    let horesRestants;
    let sumaComputHores = 0;
    let computSegons = 0;
   let computHores = 0;
   let computMinuts = 0;
    

    
    //Per saber el dia de la setmana actual

    var curr = new Date; // get current date
    var first = curr.getDate() - curr.getDay() +1; // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6

    
    useEffect(() => {
        dispatch(recuperarFixatgesSetmanaActual(auth.tokenJwt));
        dispatch(recuperarIncidenciesSetmanaActual(auth.tokenJwt));
      }, [auth,incidenciaRestaHores,incidenciaRestaHoresFiJornada]);

 

if(configUsuari.contingut !== undefined && cp.llistatFixatgesSetmana !== undefined)
{
    
    cp.llistatFixatgesSetmana.fixatges_setmanals.forEach(d => {
        //si es laborable i no es ni vacances ni festiu entra
        if(d.esLaborable && d.idTipusAlerta!=1 && d.idTipusAlerta!==2 && d.dia >= configUsuari.contingut.iniciControlPresencia){
            sumaComputHores= sumaComputHores + d.computHores/5/60;   
        }
    });
    computSegons = convertirHoresaSegons(sumaComputHores);
  //per 1000 ja que la funcio es va fer en milisewgons XD
    
    computHores = convertirSegonsaHoresSenseFormat24(computSegons*1000);
    computMinuts = convertirSegonsAMinuts(computSegons*1000);
   


    //horesRestants = RetornaHores(llistatFixatgesSetmana,hores,t);
          
    diesSetmanaLlista = cp.llistatFixatgesSetmana.fixatges_setmanals.map((d) => 

        <ContadorJornadaHoresSetmana key={ d.dia } diaAmbFixatges={ d } hores={d.computHores/60} ></ContadorJornadaHoresSetmana>
    );

    return (
        <div  className='col-md-12'>
            <h5 className='mt-3'>{t('ResumHores.total')} {computHores} {t('Generics.hores')} {t('Generics.caracIoY')} {computMinuts} {t('Generics.minuts')}</h5>
            <p>{t('ResumHores.resum')} <Moment format="DD/MM/YYYY" date={ cp.llistatFixatgesSetmana.dia_inici_setmana } />   al <Moment format="DD/MM/YYYY" date={ cp.llistatFixatgesSetmana.dia_final_setmana } /> </p>
            {/*<p><strong>{t('Generics.dia')}</strong></p>*/}
            

            <div className="table-responsive">
                <table className="table">
                    <thead>
                    <tr>
                       
                        <th scope="col"> {t('Generics.dia')} </th>
                        <th scope="col">{t('Generics.hores')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    { diesSetmanaLlista }
                    </tbody>
                </table>
                </div>
                <br />
        <ResumTotalHoresSetmana llistatFixatgesSetmana={llistatFixatgesSetmana} hores={sumaComputHores} />
        </div>
    )
}else
{
    return (
    <p>Recuperant dades de config usuari</p>
    )

}
}
