


//Aquest reducer servirà per despachar accions referents als missatges d'error dels formularis

import { types } from "../types/types"

const initialState = {

    loading: false,
    msgError: null,
    msgOkError: null,
    incidencia: false,
    vistaJornadaCompleta:false,
    incidenciaRestaHores: false,
    incidenciaRestaHoresFiJornada: false,
    diaConsultat: false,
    msgOk: null,
    diaAnterior: false,
    modalCalendar: false,
    resp:false

}


export const uiReducer = (state = initialState, action) =>{

    switch (action.type){
        case types.uiCrearError:
            return{
                ...state,
                msgError: action.payload                
            }
        case types.uiCrearOk:
            return{
                ...state,
                msgOk: action.payload                
            }
        case types.uiCrearOkError:
            return{
                ...state,
                msgOkError: action.payload                
            }
        case types.uiEsborrarOk:
            return{
                ...state,
                msgOk: null               
            }
        case types.uiEsborrarOkError:
            return{
                ...state,
                msgOkError: null               
            }
        case types.uiEsborrarError:
            return{
                ...state,
                msgError: null               
            }
        case types.uiIniciarCarrega:
            return{
                ...state,
                loading: action.payload              
            }
        case types.uiFinalitzarCarrega:
            return{
                ...state,
                loading: action.payload             
            }
        case types.uiMostraIncidencies:
            return{
                ...state,
                incidencia: action.payload             
            }
        case types.uiAmagarIncidencies:
            return{
                ...state,
                incidencia: action.payload             
            }
        case types.uiMostraJornadaCompleta:
            return{
                ...state,
                vistaJornadaCompleta: action.payload             
            }
        case types.uiAmagarJornadaCompleta:
            return{
                ...state,
                vistaJornadaCompleta: action.payload             
            }
        case types.uiMostraIncidenciesRestaHores:
            return{
                ...state,
                incidenciaRestaHores: action.payload             
            }
        case types.uiAmagarIncidenciesRestaHores:
            return{
                ...state,
                incidenciaRestaHores: action.payload             
            }
        case types.uiMostraIncidenciesRestaHoresFiJornada:
            return{
                ...state,
                incidenciaRestaHoresFiJornada: action.payload             
            }
        case types.uiAmagarIncidenciesRestaHoresFiJornada:
            return{
                ...state,
                incidenciaRestaHoresFiJornada: action.payload             
            }
        case types.uiMostraDiaConsultat:
            return{
                ...state,
                diaConsultat: action.payload             
            }
        case types.uiAmagarDiaConsultat:
            return{
                ...state,
                diaConsultat: action.payload             
            }
        case types.uiMostraCalendarModal:
            return{
                ...state,
                modalCalendar: true             
            }
        case types.uiAmagarCalendarModal:
            return{
                ...state,
                modalCalendar: false             
            }
        case types.uiResponsable:
            return{
                ...state,
                resp: action.payload             
            }
        
        
        default:
            return state;

    }
}