import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import Moment from 'react-moment'
import 'moment-timezone';
import {useTranslation} from "react-i18next";

import { useDispatch, useSelector } from 'react-redux';
import { recuperarIncidenciesAsync } from '../../actions/controlPresenciaActions';
import { TipusIncidencia } from './TipusIncidencia';
import { convertirSegonsaHores, convertirSegonsAMinuts, numeroSegonsEntreDates } from '../../Helpers/funcionsDates';


export const LlistatIncidenciesDia = (props) => {


const dispatch = useDispatch(); //Per despachar accions
const state = useSelector(state => state); // Això retorna l'esta actual de l'aplicació, per poder accedir a tot el contingut emmagatzemat allà
const { ui } = useSelector(state => state); // Per desestructuració d¡objectes, podem agafar l'objecte desitjat del nostre state
const { loading, msgError } = useSelector(state => state.ui); //Per desestructuració, podem agafar propietats d'objectes directament
const { auth } = useSelector(state => state);
const { cp } = useSelector(state => state);
const [fiRevisarIncidencia, setfiRevisarIncidencia] = useState(false);
const [t, i18n] = useTranslation('generic');
let listItems = "";
let segonsJustificats = 0;
let horesVali = 0;
let minutsVali = 0;
let segonsNoJustificats = 0;
let horesNoVali = 0;
let minutsNoVali = 0;





const {dataAvui} = (props); 

useEffect(() => {
    dispatch(recuperarIncidenciesAsync(auth.tokenJwt));
    setfiRevisarIncidencia(true);
  }, [auth]);

//

if(fiRevisarIncidencia === false)
{
   return(
       <h1>Estem revisant les incidencies diaris... </h1>
   )

}else{

    if(cp.llistatIncidencies !== undefined){
  
        listItems = cp.llistatIncidencies.map((d) => 
        <li key={d.IdIncidenciaFix}>
            <TipusIncidencia IdTipusIncidencia_IncidenciaFix={Number(d.IdTipusIncidencia_IncidenciaFix)}  /> - <Moment format="HH:mm" date={d.DataInici_IncidenciaFix} />   <Moment format="HH:mm" date={d.DataFi_IncidenciaFix} /> 
            
            {d.ValidatResponsable_IncidenciaFix == "1" && d.Justificat_IncidenciaFix == "1" ?
            <p>Validat i justificat</p>
            :
                d.ValidatResponsable_IncidenciaFix == "1" && d.Justificat_IncidenciaFix != "0"  ?
                <p>Validat per responsable</p>
                :<p>Pendent de validació</p>}
        </li>);
        cp.llistatIncidencies.forEach((e) => {
            if(e.Justificat_IncidenciaFix){
                segonsJustificats = segonsJustificats + numeroSegonsEntreDates(e.DataInici_IncidenciaFix,e.DataFi_IncidenciaFix);
            }else{
                segonsNoJustificats = segonsNoJustificats + numeroSegonsEntreDates(e.DataInici_IncidenciaFix,e.DataFi_IncidenciaFix);
            }
            
            
        });
        horesVali =  convertirSegonsaHores(segonsJustificats);
        minutsVali =  convertirSegonsAMinuts(segonsJustificats);

        horesNoVali =  convertirSegonsaHores(segonsNoJustificats);
        minutsNoVali =  convertirSegonsAMinuts(segonsNoJustificats);
       
    }

    //const listItems = cp.llistatFixatges.map((d) => <li key={d.DataHora_Fixatge}>{d.DataHora_Fixatge}</li>);
   
}



    return (
        <>
            <ul>
                { listItems }
                <p>Un total de {horesVali} hores i {minutsVali} minuts validats</p>
                <p>Un total de {horesNoVali} hores i {minutsNoVali} minuts No validats</p>
            </ul>
        
        </>
    )


}


LlistatIncidenciesDia.propTypes = {
    dataAvui: PropTypes.instanceOf(Date)
}

