import React, { useEffect, useState } from 'react'
import { useDispatch,useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { iniciDesloginar } from '../../actions/auth'
import { recuperarConfigUsuari } from '../../actions/configUsuariAction';
import { renovarTokenAction } from '../../actions/auth';
import { recuperarConfigAplicacio } from '../../actions/configAplicacioAction';
import { useTranslation } from 'react-i18next';

export const UsuariLoginat = () => {
    const dispatch = useDispatch();
    const navegacio = useNavigate();
    const { auth } = useSelector(state => state);
    const [t, i18n] = useTranslation('generic');
    const [contadorRenovarToken, setContadorRenovarToken] = useState(new Date());


    useEffect(() => {
      if(auth.tokenJwt!== undefined){
        dispatch(recuperarConfigUsuari(auth.tokenJwt));   
        dispatch(recuperarConfigAplicacio(auth.tokenJwt));
        }else{
          navegacio('/login',{replace:true})
        }
      }, [auth]);

      useEffect(() => {
        //S'executa cada 5 minuts per renovar el token Auth
        const interval = setInterval(
          () => setContadorRenovarToken(new Date()),
          60000*5
          // 60000*5
        );
          
        dispatch(renovarTokenAction(auth.tokenJwt));  
        return () => {
          clearInterval(interval);
        }
      }, [contadorRenovarToken]);



const desloginarUsuari = () =>{
    dispatch(iniciDesloginar());
    navegacio('/login',{replace:true})
}

    return (

        <div className="mb-0">
            <div className="row">
                <div className="col-6 col-md-7">
                  {auth.admin ==0 ?
                   <p className="align-left mt-3"><i className="bi-person-circle me-1"></i> { auth.nom + " " + auth.cognom }  </p>
                  :<p className="align-left mt-3">(ADMIN) <i className="bi-person-circle me-1"></i>{ auth.nom + " " + auth.cognom }  </p>
                  }
                </div>
                <div className="col-6 col-md-5 align-right">
                        <button className="btn btn-primary btn-sm m-1" onClick={ desloginarUsuari } ><i className="bi bi-box-arrow-right me-2"></i>Logout</button>
                </div>
            </div>
         </div>

     



    )
} 