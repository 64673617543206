import React from 'react'
import { TipusIncidencia } from '../ControlPresencia/TipusIncidencia';
import { TipusIncidenciaCalendar } from './TipusIncidenciaCalendar';
import {useTranslation} from "react-i18next";

export const CalendarEvent = ({ event }) => {
    const [t, i18n] = useTranslation('generic');
    const { titol, Observacions_IncidenciaFix,idTipusAlerta,isMine,existeixAlerta,esLaborable } = event;

     <TipusIncidenciaCalendar idTipusAlerta={Number(event.idTipusAlerta)}  />

    return (
        <div>
            <strong>     <TipusIncidencia IdTipusIncidencia_IncidenciaFix={Number(event.IdTipusIncidencia_IncidenciaFix)}  />  </strong>
            { !isMine?
                !existeixAlerta?
                    esLaborable?
                    <p>{t('Calendari.diaComplet')}</p>
                    :<p>{t('Calendari.Finde')}</p>
                :<p><TipusIncidenciaCalendar idTipusAlerta={Number(event.idTipusAlerta)}  /></p>
            :null

            }
        </div>
    )
}
