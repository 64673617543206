import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

export const HeaderIdioma = () => {
  const [t, i18n] = useTranslation('generic');
  const [idioma, setIdioma] = useState('ca');


  //funcio per cambiar d'idioma
  const changeLanguage = () => {
    if(idioma=='ca'){
      i18n.changeLanguage('es');
      setIdioma('es');
    }else{
      i18n.changeLanguage('ca');
      setIdioma('ca');
    }
  }
    return (
      <div className='col-md-1 align-right'>
    <p>
        <a className="idioma" onClick={() => changeLanguage()} href="#">{t('Generics.idioma')}</a>
    </p>
    </div>
    )
}
