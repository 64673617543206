import React from 'react'

export const FooterPublic = () => {
    return (
        <>
<footer className="container py-4">
  <div className="row align-center">
    <div className="col-md-12  mb-3 mb-md-0">
      <p className="fs-5 mb-0">© 2022 Col·legi Oficial de Metges de Barcelona</p>
    </div>

    
  </div>
</footer>

        </>
    )
}
