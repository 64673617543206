import { useReducer } from 'react'
import { Provider } from 'react-redux';

import { AppRouter } from './routers/AppRouter'
import { store } from './store/store';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import generics_es from "./translations/es/generics.json";
import generics_ca from "./translations/ca/generics.json";




i18next.init({
    interpolation: { escapeValue: false }, 
    lng: 'ca',
    resources: {
        ca: {
            generic: generics_ca               // 'common' is our custom namespace
        },
        es: {
            generic: generics_es
        },
    },
});



export const FixatgeApp = () => {



    return (
        
        <Provider store={store}>
            <I18nextProvider i18n={i18next}>
                <AppRouter />
            </I18nextProvider>
         </Provider>
      
    )
}
