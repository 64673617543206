import { types } from "../types/types";

export const controlPresenciaReducer = (state = {},action) => {

    switch (action.type){
        case types.cpConsultarFixatgeJornada:
            return {...state,
                
                llistatFixatges : action.llistatFixatges
            
        }
            break;
        case types.cpDiaFixatgeConsultat:
            return {...state,
                
                fixatgeDiaConsultat : action.fixatgeDiaConsultat,
               
            
        }
            break;
        case types.cpDiaIncidenciaConsultat:
            return {...state,
                
                incidenciaDiaConsultat : action.incidenciaDiaConsultat,
               
            
        }
            break;
        case types.cpConsultarIncidenciaJornada:
            return {...state,
                    
                llistatIncidencies : action.llistatIncidencies
                
        }
             break;
        case types.cpJornadaIniciada:
            return {...state,
                
                jornadaIniciada : action.iniciada
               
            }
         case types.cpJornadaFinalitzada:
            return {...state,
               
                jornadaFinalitzada : action.finalitzada
            }
        case types.cpJornadaIncidencia:
            return {...state,
                jornadaIncidencia : action.incidencia
            }
        case types.cpConsultarFixatgeSetmanaActual:
            return {...state,
                
                llistatFixatgesSetmana : action.llistatFixatgesSetmana
            
        }
        case types.cpConsultarIncidenciesSetmanaActual:
            return {...state,
                
                llistatIncidenciesSetmana : action.llistatIncidenciesSetmana
            
        }
        
            //cpConsultarFixatgeSetmanaActual
        default:
            return state;
    }

}