import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ContadorHoresJornada } from '../ControlPresencia/ContadorHoresJornada';
import { FormulariFiJornada } from '../ControlPresencia/FormulariFiJornada';
import { FormulariIniciJornada } from '../ControlPresencia/FormulariIniciJornada'
import { HoresTotalsSetmana } from '../ControlPresencia/HoresTotalsSetmana';
import { LlistatFixatgesDia } from '../ControlPresencia/LlistatFixatgesDia'
import { Modal } from '../Incidencia/Modal';
import ModalReact from 'react-modal';
import { ModalDiaAnterior } from '../Incidencia/ModalDiaAnterior';
import { MenuPrincipal } from '../ui/MenuPrincipal';
import { UsuariLoginat } from '../ui/UsuariLoginat';
import { LlistatIncidenciesDia } from '../ControlPresencia/LlistatIncidenciesDia'
import { esborrarErrorOkAction, esborrarOkAction, uiAmagarIncidenciesAction, uiAmagarIncidenciesRestaHoresAction, uiAmagarJornadaCompletaAction, uiMostraDiaConsultatAction, uiMostraJornadaCompletaAction } from '../../actions/ui';
import { DetallDiaScreen } from '../DetallDia/DetallDiaScreen';
import { useNavigate } from 'react-router-dom';
import { RecuperarInfoCalendar } from '../Calendar/RecuperarInfoCalendar';
import { RecuperarInfoVacances } from '../CalendariVacances/RecuperarInfoVacances';
import { FinalMesConsultat, IniciMesConsultat, recuperarFixatgesEntreDates, recuperarIncidenciaEntreDates, recuperarInfoEntreDates, recuperarInfoVacancesEntreDates, recuperarVacancesEntreDates } from '../../actions/configCalendariAction';
import { recuperarDicFixatgesCalendari } from '../../actions/configAplicacioAction';
import { JornadaComplerta } from '../../actions/controlPresenciaActions';
import { FormulariJornadaCompleta } from '../ControlPresencia/FormulariJornadaCompleta';

export const RegistreScreen = () => {

    const dataAvui = new Date();
    const { cp,auth,ui,calendari,configUsuari,jornadaCompleta} = useSelector(state => state);
    const { msgOk,diaConsultat,msgOkError } = useSelector(state => state.ui);
    
    const { jornadaIniciada,jornadaFinalitzada,jornadaIncidencia, llistatFixatges } = cp;
    const [t, i18n] = useTranslation('generic');
    const [formulariMostra, setFormulariMostra] = useState(<FormulariIniciJornada />);
    const [primeraCarrega, setPrimeraCarrega] = useState(false);
    const [admin, setAdmin] = useState(false);
    const dispatch = useDispatch(); 
    const navegacio = useNavigate();
    let i=0;
    let avui = new Date()
    let final = "";
    let inici = "";

    if(jornadaIniciada && !primeraCarrega )
    {
        setFormulariMostra(<FormulariFiJornada />);
        setPrimeraCarrega(true);
    }
   
   
    const customStyles = {
        content : {
          top                   : '50%',
          left                  : '50%',
          right                 : 'auto',
          bottom                : 'auto',
          marginRight           : '-50%',
          transform             : 'translate(-50%, -50%)'
        }
    };
    

    const closeModal = () => {
        // TODO: cerrar el modal
       
    }

    const handleChangeDiaComplet=()=>{
      if(ui.vistaJornadaCompleta){
        dispatch(uiAmagarJornadaCompletaAction())
    }else{
        dispatch(uiMostraJornadaCompletaAction())
    }

    }
    if(auth.admin ===1 && !admin ){
      setAdmin(true);
    }

useEffect(() => {
  if(admin){
    navegacio('/calendar');
    dispatch(RecuperarInfoCalendar(auth,ui,calendari))
  }
  if(ui.resp){
    navegacio('/calendariVacances');
    dispatch(recuperarInfoVacancesEntreDates(auth.tokenJwt))
    dispatch(RecuperarInfoVacances(auth))
  }
}, [admin])

            
//use
    useEffect(() => {
        if(jornadaIniciada )
        {
            setFormulariMostra(<FormulariFiJornada />);
        }
        if(jornadaFinalitzada )
        {
            setFormulariMostra(<FormulariIniciJornada />);
        }
        if(ui.vistaJornadaCompleta)
        {
            setFormulariMostra(<FormulariJornadaCompleta />);
        }else{

          if(jornadaIniciada )
        {
            setFormulariMostra(<FormulariFiJornada />);
        }
        if(jornadaFinalitzada )
        {
            setFormulariMostra(<FormulariIniciJornada />);
        }
        }
  
      }, [jornadaIniciada,jornadaFinalitzada,jornadaIncidencia,ui.vistaJornadaCompleta]);
     
      if(msgOk !== null){
        
        setTimeout(()=>dispatch(esborrarOkAction()),10000); 
      }    
      if(msgOkError !== null){
          setTimeout(()=>dispatch(esborrarErrorOkAction()),10000);
      }      
        
     

    return (




        
        <div className="position-relative" >   

       
        <ModalDiaAnterior></ModalDiaAnterior>

        <div className="card card-shadow card-login">
              <div className="row">
                <div className="col-md-6">
                  <div className="card-body">
                  <div className='visible-xs'>
                    <UsuariLoginat />
                    <hr />
                        </div>
                      <MenuPrincipal />
                      <div className="">
                      <div>  
                         
                       
                        
                        </div>  


                        { msgOkError &&  //això es una condició, si es diferent de null
                        <div className="alert alert-danger" role="alert">
                        { msgOkError}
                        </div>           
                        }
                        { msgOk &&  //això es una condició, si es diferent de null
                        <div className="alert alert-success" role="alert">
                        { msgOk}
                        </div>           
                        }
                            <div className="mb-5">
                                <h3 className="card-title">{t('Header.RegistreScrentitle')}</h3>
                                
                            </div>
                        </div>
                        { formulariMostra }
                        {configUsuari.contingut !=undefined?
                                configUsuari.contingut.jornadaCompleta==1 && !jornadaIniciada?
                                
                                <div class="row"><p>Jornada completa <button type="submit" onClick={ handleChangeDiaComplet } class="btn btn-outline-primary btn-sm m-1 col-md-4">Registrar Jornada</button> </p></div>
                                
                                :null:null}
                        <LlistatFixatgesDia dataAvui={ dataAvui } />
                        {configUsuari.contingut !=undefined?
                        <ContadorHoresJornada contingut={configUsuari.contingut}/>
                          :null
                        }
                        
                    </div>
                </div>
                <div className="col-md-6 d-md-flex flex-column bg-soft-primary p-8 p-md-5 bg-comb">
                    <div className='hidden-xs'>
                    <UsuariLoginat />
                    <hr />
                        </div>
                        
                     {diaConsultat == false ?
                     <HoresTotalsSetmana  /> 
                     :<DetallDiaScreen dataAvui={ dataAvui }  dia={diaConsultat}/> 
                     }       
                </div>

          </div>
        </div>
        <figure className="position-absolute top-0 end-0 zi-n1 d-none d-sm-block mt-n7 me-n10 width4rem">
              <img className="img-fluid" src="../assets/svg/components/pointer-up.svg" alt="Image Description" />
            </figure>
           
            <figure className="position-absolute bottom-0 start-0 d-none d-sm-block ms-n10 mb-n10 width15rem">
              <img className="img-fluid" src="../assets/svg/components/curved-shape.svg" alt="Image Description" />
            </figure>
        </div>
    )
}
