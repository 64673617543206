import React, { useEffect, useState } from 'react'
import { useDispatch,useSelector } from 'react-redux';
import {  NavLink, useNavigate } from 'react-router-dom'
import { iniciDesloginar } from '../../actions/auth'
import { recuperarConfigUsuari } from '../../actions/configUsuariAction';
import { renovarTokenAction } from '../../actions/auth';
import { recuperarConfigAplicacio } from '../../actions/configAplicacioAction';
import { useTranslation } from 'react-i18next';
import { FinalMesConsultat, IniciMesConsultat, recuperarFixatgesEntreDates, recuperarIncidenciaEntreDates, recuperarInfoEntreDates, recuperarInfoVacancesEntreDates } from '../../actions/configCalendariAction';
import moment from 'moment'
import { RecuperarInfoCalendar } from '../Calendar/RecuperarInfoCalendar';

export const MenuPrincipal = () => {

    const dispatch = useDispatch();
    const navegacio = useNavigate();
    const { alerta } = useSelector(state => state);
    const { auth,ui,calendari } = useSelector(state => state);
    const [t, i18n] = useTranslation('generic');
    const [contadorRenovarToken, setContadorRenovarToken] = useState(new Date());
    let avui = new Date()
    let final = "";
    let inici = "";

    useEffect(() => {
        dispatch(recuperarConfigUsuari(auth.tokenJwt));   
        dispatch(recuperarConfigAplicacio(auth.tokenJwt));

      }, [auth]);

      useEffect(() => {
        //S'executa cada 5 minuts per renovar el token Auth
        const interval = setInterval(
          () => setContadorRenovarToken(new Date()),
          60000*5
        );
          
            dispatch(renovarTokenAction(auth.tokenJwt));   
        return () => {
          clearInterval(interval);
        }
      }, [contadorRenovarToken]);

const handleConsultarCalendari = () =>{
    dispatch(RecuperarInfoCalendar(auth,ui,calendari))
}
const handleConsultarCalendariVaca = () =>{
    dispatch(recuperarInfoVacancesEntreDates(auth.tokenJwt))
}

const desloginarUsuari = () =>{
    dispatch(iniciDesloginar());
    navegacio('login',{replace:true})
}
//eliminem la inf un cop sortim del calendari
const onClickEliminarMesConsultat = () =>{
    dispatch(IniciMesConsultat());
    dispatch(FinalMesConsultat());
    dispatch(recuperarFixatgesEntreDates());
    dispatch(recuperarIncidenciaEntreDates());
    dispatch(recuperarInfoEntreDates());
}

    return (


<div className='text-center mb-5'>
{ auth.resp == 1 && ui.resp == true?
 <NavLink 
 className={ ({isActive}) => ' btn btn-white btn-sm m-1 ' + (isActive ? 'active' : '')} 
 to="/calendariVacances"
 onClick={handleConsultarCalendariVaca}
>
{t('Header.CalendariVac')}
</NavLink>
:
<>
    {auth.admin==0?
        <NavLink 
                className={ ({isActive}) => 'btn btn-white btn-sm m-1 ' + (isActive ? 'active' : '')} 
                to="/"
                onClick={onClickEliminarMesConsultat}
            >
            {t('Header.ControlPresencia')} 

            </NavLink>
    :null}
                       
     
          
            <NavLink 
                className={ ({isActive}) => ' btn btn-white btn-sm m-1 ' + (isActive ? 'active' : '')} 
                to="/calendar"
                onClick={handleConsultarCalendari}
            >
               {t('Header.Historic')}
            </NavLink>
            {auth.admin==0?
            <NavLink 
                className={ ({isActive}) => 'btn btn-white btn-sm m-1 ' + (isActive ? 'active' : '')} 
                to="/manuals"
                onClick={onClickEliminarMesConsultat}
            >
               {t('Header.Manuals')}
            </NavLink>
            :<NavLink 
            className={ ({isActive}) => 'btn btn-white btn-sm m-1 ' + (isActive ? 'active' : '')} 
            to="/preferencies"
>
           {t('Header.Preferecies')}
            </NavLink>}
            
           
            </>}
  
</div>


    )
}