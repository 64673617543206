import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addFixatgeAsync } from '../../actions/controlPresenciaActions';
import { crearTotOkAction, uiAmagarDiaConsultatAction, uiAmagarIncidenciesAction, uiMostraIncidenciesAction } from '../../actions/ui';
import { FormulariIncidencia } from '../Incidencia/FormulariIncidencia';


//Aquest formulari es mostrarà només si l'estat del dia actual encara està marcat com pendent de rebre informació de inici de jornada
export const FormulariIniciJornada = (props) => {

    const [t, i18n] = useTranslation('generic');
    const dispatch = useDispatch(); //Per despachar accions
    const {ui} = useSelector(state => state);
    const {incidencia,diaConsultat} = ui;
    const { auth,configAplicacio,configUsuari } = useSelector(state => state);
    const [radioTipusControlPresencia, setradioTipusControlPresencia] = useState('');


 const handleChange =(e) => {
    
    setradioTipusControlPresencia(e.target.value)
  }

  const handleChangeIncidencia = () =>{
     
    if(incidencia){

        dispatch(uiAmagarIncidenciesAction())

    }else{
        dispatch(uiMostraIncidenciesAction())
        dispatch(uiAmagarDiaConsultatAction())
    }
  }

  


const enviarControlPresenciaEntrada = (e) =>
    {
        e.preventDefault();
    
        
        if(radioTipusControlPresencia !== '')
        {
            dispatch(addFixatgeAsync(radioTipusControlPresencia,auth.tokenJwt,i18n.language,configUsuari.contingut.saltaUrlPortalComb));
        }else{
            dispatch(crearTotOkAction(t('FormulariFiIniciJornada.msgOkNoSelec')))

        }
       
           //Todo Cridar a API de login. SI tot es OK, crearem l'acció de Login amb les dades de l'usuari rebudes del servei
          // dispatch(iniciarPeticioLoginAsincronaAxios(email,clau));
 
          //dispatch(iniciarPeticioLoginAsincrona(email,clau));
       
        
        
        //Per navegar sense restriccions d'accés
        //navegacio('/'); //Per navegar entre screens del projecte
    
        //navegacio('/',{ replace: true }); // Això serveix per refrescar l'historic de navegació i evitar que l'usuari pugui navegar amb els botons del navegador
    }
   


    return (
        <>
            <div className='row'>
            <div className='col-md-4 offset-md-3'>
                <div className="form-check mb-3">
                <input type="radio" onChange={handleChange} id="tipusRegistreInicial_Presencial" className="form-check-input" name="tipusRegistreInicial" value={1} />
                <label className="form-check-label" htmlFor="tipusRegistreInicial_Presencial">{t('FormulariFiIniciJornada.presencial')}</label>
                </div>
            </div>
            <div className='col-md-4'>
                <div className="form-check mb-3">
                <input type="radio" onChange={handleChange} id="tipusRegistreInicial_Teletreball" className="form-check-input" name="tipusRegistreInicial" value={2} />
                <label className="form-check-label" htmlFor="tipusRegistreInicial_Teletreball">{t('FormulariFiIniciJornada.teletreball')}</label>
                </div>
            </div>
            </div>
            <div className='mb-3 text-center'>
            <button type="button" onClick={ enviarControlPresenciaEntrada } className="btn btn-primary btn-sm m-1">{t('FormulariFiIniciJornada.enviar')}</button>
            </div>
            <div className='row'>
            <p>{t('FormulariFiIniciJornada.PregIncidencia')}<button type="submit" onClick={ handleChangeIncidencia }  className="btn btn-outline-primary btn-sm m-1 col-md-4">{t('FormulariFiIniciJornada.incidencia')}</button></p>
            {
                incidencia && !diaConsultat ? <FormulariIncidencia /> : null
            }
            </div>

        </>
    )
}
