import React from 'react'
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

//Aquest component retornarà el tipus de fixatge, segons el id proporcionat a les propietats
export const TipusIncidencia = (props) => {
    const [t, i18n] = useTranslation('generic');
    const { configAplicacio} = useSelector(state => state);
    const{dicIncidencies} = (configAplicacio);
    const {IdTipusIncidencia_IncidenciaFix} = (props); 
    let descTipusFixatge="";
    let estatValidat = false;
    if(dicIncidencies !=undefined){
    dicIncidencies.forEach((e) => {
        if(e.IdIncidencia == IdTipusIncidencia_IncidenciaFix){
            descTipusFixatge = e.desc_Incidencia;
        }
});
    }



    return (
        <>
            { descTipusFixatge }
        </>
    )
}



TipusIncidencia.propTypes = {
    IdTipusIncidencia_IncidenciaFix: PropTypes.number.isRequired
}
