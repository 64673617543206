import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addFixatgeAsync, JornadaComplerta } from '../../actions/controlPresenciaActions';
import { crearTotOkAction, uiAmagarDiaConsultatAction, uiAmagarIncidenciesAction, uiMostraIncidenciesAction } from '../../actions/ui';
import { FormulariIncidencia } from '../Incidencia/FormulariIncidencia';


//Aquest formulari es mostrarà només si l'estat del dia actual encara està marcat com pendent de rebre informació de inici de jornada
export const FormulariJornadaCompleta = (props) => {

    const [t, i18n] = useTranslation('generic');
    const dispatch = useDispatch(); //Per despachar accions
    const {ui} = useSelector(state => state);
    const {incidencia,diaConsultat} = ui;
    const { auth,configAplicacio,configUsuari } = useSelector(state => state);
    const [radioTipusControlPresencia, setradioTipusControlPresencia] = useState('');


 const handleChange =(e) => {
    
    setradioTipusControlPresencia(e.target.value)
  }



  


const enviarControlPresenciaEntrada = (e) =>
    {
        e.preventDefault();
    
        
        if(radioTipusControlPresencia !== '')
        {
            dispatch(JornadaComplerta(radioTipusControlPresencia,auth.tokenJwt,i18n.language,configUsuari.contingut.saltaUrlPortalComb));
        }else{
            dispatch(crearTotOkAction(t('FormulariFiIniciJornada.msgOkNoSelec')))

        }
       
    }
   


    return (
        <>
            <div className='row'>
            
            
            <div className='col-md-4'>
                <div className="form-check mb-3">
                <input type="radio" onChange={handleChange} id="tipusRegistreInicial_Presencial" className="form-check-input" name="tipusRegistreInicial" value={1} />
                <label className="form-check-label" htmlFor="tipusRegistreInicial_Presnecial">{t('FormulariFiIniciJornada.presencialComplet')}</label>
                </div>
            </div>
            <div className='col-md-4'>
                <div className="form-check mb-3">
                <input type="radio" onChange={handleChange} id="tipusRegistreInicial_Teletreball" className="form-check-input" name="tipusRegistreInicial" value={2} />
                <label className="form-check-label" htmlFor="tipusRegistreInicial_Teletreball">{t('FormulariFiIniciJornada.teletreballComplet')}</label>
                </div>
            </div>
            <div className='col-md-4'>
                <div className="form-check mb-3">
                <input type="radio" onChange={handleChange} id="tipusRegistreInicial_Eliminar" className="form-check-input" name="tipusRegistreInicial" value={3} />
                <label className="form-check-label" htmlFor="tipusRegistreInicial_Eliminar">{t('FormulariFiIniciJornada.DeleteComplet')}</label>
                </div>
            </div>
            </div>
            <div className='mb-3 text-center'>
            <button type="button" onClick={ enviarControlPresenciaEntrada } className="btn btn-primary btn-sm m-1">{t('FormulariFiIniciJornada.enviar')}</button>
            </div>
            

        </>
    )
}
