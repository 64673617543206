import React from 'react'
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

//Aquest component retornarà el tipus de fixatge, segons el id proporcionat a les propietats
export const TipusIncidenciaCalendar = (props) => {
    const [t, i18n] = useTranslation('generic');
    const { configAplicacio} = useSelector(state => state);
    const{descTipusincidenciaCalendari} = (configAplicacio);
    const {idTipusAlerta} = (props); 
    let descTipusincidenciaCalendariAlerta="";

    if(descTipusincidenciaCalendari !=undefined){
        descTipusincidenciaCalendari.forEach((e) => {
        if(e.IdAlertaDia == idTipusAlerta){
            if(t('Generics.idiomaDatetime') =="es"){
                descTipusincidenciaCalendariAlerta = e.desc_Alerta_es;
            }
            if(t('Generics.idiomaDatetime') == "ca"){
                descTipusincidenciaCalendariAlerta = e.desc_Alerta;
            }
        }
});
    }



    return (
        <>
            { descTipusincidenciaCalendariAlerta }
        </>
    )
}



TipusIncidenciaCalendar.propTypes = {
    idTipusAlerta: PropTypes.number.isRequired
}