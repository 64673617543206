import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Modal from 'react-modal';
import { DetallDiaScreen } from '../DetallDia/DetallDiaScreen';
import { uiAmagarCalendarModal, uiAmagarDiaConsultatAction, uiAmagarIncidenciesAction, uiAmagarIncidenciesRestaHoresAction } from '../../actions/ui';

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
};

const nowPlus1 = moment().minute(0).second(0).add(1,'hours');//10:00
const now = moment().minute(0).second(0).add(0,'hours');//10:00

const initEvent = {
    notes: '',
    start: now.toDate(),
    end: nowPlus1.toDate()
}

export const CalendarModal = (props) => {

     const { modalCalendar,incidencia,incidenciaRestaHores} = useSelector( state => state.ui );
     const dispatch = useDispatch();


    //si toquem a fora del modal ejecutem 
     const closeModal = () => {
        // TODO: cerrar el modal
        dispatch( uiAmagarCalendarModal() );
        dispatch(uiAmagarDiaConsultatAction())
        if(incidencia){
        dispatch(uiAmagarIncidenciesAction())
        }
        if(incidenciaRestaHores){
          dispatch(uiAmagarIncidenciesRestaHoresAction())
       }
    }
  
    


    return (
        <Modal id="modalCalendar"
          isOpen={ modalCalendar }
          onRequestClose={ closeModal }
          style={ customStyles }
          closeTimeoutMS={ 200 }
          className="modal fade bd-example-modal-xl show"
          ariaHideApp={false}
          overlayClassName="modal-fondo"
        >
          
          <button type="button" onClick={closeModal} className="btn-close align-right" data-bs-dismiss="modal" aria-label="Close"></button>
                <div className='modal-body'>
         <DetallDiaScreen /> 
                </div>
        </Modal>
    )
}
