import { crearErrorAction } from "./ui";
import { types } from "../types/types"

export const recuperarIncidenciesSemanaConsultada = (token,numSetmana,any) =>{
    //Aquesta funció (Middleware), retorna un callback. Just quan finalitza la petició, execitarà l'acció 
    return (dispatch) =>{   
        var dadesFormulari = {
            'numSetmana': numSetmana,
            'any': any
        };
        
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody
            };        
        fetch(process.env.REACT_APP_DOMINI_API_PORTALCOMB + '/incidencia/setmana',requestOptions)

        .then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json();
           
            if (response.status != 200) {
                // get error message from body or default to response status
                //const error = (data && data.message) || response.status;
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
            }else
            {            
                //Fem el dispatch de l'acció login amb les dades rebudes del servei
                if(data.ok)
                {
                    dispatch(IncidenciaSemanaConsultada(data));
                }else
                {
                    //El servei d'aunteticació reporta un error de login
                    
                }  
            }
            //element.innerHTML = data.id;
        })
        .catch(error => {
           // element.parentElement.innerHTML = `Error: ${error}`;
            dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
            console.error('There was an error!', error);
        });
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
       
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


} 
export const recuperarIncidenciaMesConsultat = (token,numMes,any) =>{
    //Aquesta funció (Middleware), retorna un callback. Just quan finalitza la petició, execitarà l'acció 
    return (dispatch) =>{   
        var dadesFormulari = {
            'numMes': numMes,
            'any': any
        };
        
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody
            };        
        fetch(process.env.REACT_APP_DOMINI_API_PORTALCOMB + '/incidencia/mes',requestOptions)

        .then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json();
           
            if (response.status != 200) {
                // get error message from body or default to response status
                //const error = (data && data.message) || response.status;
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
            }else
            {            
                //Fem el dispatch de l'acció login amb les dades rebudes del servei
                if(data.ok)
                {
                    dispatch(IncidenciaMesConsultat(data));
                }else
                {
                    //El servei d'aunteticació reporta un error de login
                    
                }  
            }
            //element.innerHTML = data.id;
        })
        .catch(error => {
           // element.parentElement.innerHTML = `Error: ${error}`;
            dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
            console.error('There was an error!', error);
        });
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
       
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


} 
export const recuperarFixatgesSemanaConsultada = (token,numSetmana,any) =>{
    //Aquesta funció (Middleware), retorna un callback. Just quan finalitza la petició, execitarà l'acció 
    return (dispatch) =>{   
        var dadesFormulari = {
            'numSetmana': numSetmana,
            'any': any
        };
        
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody
            };        
        fetch(process.env.REACT_APP_DOMINI_API_PORTALCOMB + '/incidencia/setmana',requestOptions)

        .then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json();
           
            if (response.status != 200) {
                // get error message from body or default to response status
                //const error = (data && data.message) || response.status;
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
            }else
            {            
                //Fem el dispatch de l'acció login amb les dades rebudes del servei
                if(data.ok)
                {
                    dispatch(FixatgesSemanaConsultada(data));
                }else
                {
                    //El servei d'aunteticació reporta un error de login
                    
                }  
            }
            //element.innerHTML = data.id;
        })
        .catch(error => {
           // element.parentElement.innerHTML = `Error: ${error}`;
            dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
            console.error('There was an error!', error);
        });
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
       
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


} 
export const recuperarFixatgesMesConsultat = (token,numMes,any) =>{
    //Aquesta funció (Middleware), retorna un callback. Just quan finalitza la petició, execitarà l'acció 
    return (dispatch) =>{   
        var dadesFormulari = {
            'numMes': numMes,
            'any': any
        };
        
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody
            };        
        fetch(process.env.REACT_APP_DOMINI_API_PORTALCOMB + '/incidencia/mes',requestOptions)

        .then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json();
            if (response.status != 200) {
                // get error message from body or default to response status
                //const error = (data && data.message) || response.status;
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
            }else
            {            
                //Fem el dispatch de l'acció login amb les dades rebudes del servei
                if(data.ok)
                {
                    dispatch(FixatgesMesConsultat(data));
                }else
                {
                    //El servei d'aunteticació reporta un error de login
                    
                }  
            }
            //element.innerHTML = data.id;
        })
        .catch(error => {
           // element.parentElement.innerHTML = `Error: ${error}`;
            dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
            console.error('There was an error!', error);
        });
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
       
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


} 

export const recuperarFixatgesEntreDates= (token,dataIniciConsulta,dataFiConsulta) =>{
    //Aquesta funció (Middleware), retorna un callback. Just quan finalitza la petició, execitarà l'acció 
    return (dispatch) =>{   
        var dadesFormulari = {
            'dataIniciConsulta': dataIniciConsulta,
            'dataFiConsulta': dataFiConsulta
        };
        
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody
            };        
        fetch(process.env.REACT_APP_DOMINI_API_PORTALCOMB + '/fixatge/entreDates',requestOptions)

        .then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json();
            if (response.status != 200) {
                // get error message from body or default to response status
                //const error = (data && data.message) || response.status;
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
            }else
            {            
                //Fem el dispatch de l'acció login amb les dades rebudes del servei
                if(data.ok)
                {
                    dispatch(FixatgesMesConsultat(data));
                }else
                {
                    //El servei d'aunteticació reporta un error de login
                    
                }  
            }
            //element.innerHTML = data.id;
        })
        .catch(error => {
           // element.parentElement.innerHTML = `Error: ${error}`;
            dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
            console.error('There was an error!', error);
        });
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
       
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


} 
export const recuperarIncidenciaEntreDates = (token,dataIniciConsulta,dataFiConsulta) =>{
    //Aquesta funció (Middleware), retorna un callback. Just quan finalitza la petició, execitarà l'acció 
    return (dispatch) =>{   
        var dadesFormulari = {
            'dataIniciConsulta': dataIniciConsulta,
            'dataFiConsulta': dataFiConsulta
        };
        
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody
            };        
        fetch(process.env.REACT_APP_DOMINI_API_PORTALCOMB + '/incidencia/entreDates',requestOptions)

        .then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json();
           
            if (response.status != 200) {
                // get error message from body or default to response status
                //const error = (data && data.message) || response.status;
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
            }else
            {            
                //Fem el dispatch de l'acció login amb les dades rebudes del servei
                if(data.ok)
                {
                    dispatch(IncidenciaMesConsultat(data));
                }else
                {
                    //El servei d'aunteticació reporta un error de login
                    
                }  
            }
            //element.innerHTML = data.id;
        })
        .catch(error => {
           // element.parentElement.innerHTML = `Error: ${error}`;
            dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
            console.error('There was an error!', error);
        });
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
       
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


} 
export const recuperarInfoEntreDates= (token,dataIniciConsulta,dataFiConsulta) =>{
    //Aquesta funció (Middleware), retorna un callback. Just quan finalitza la petició, execitarà l'acció 
    return (dispatch) =>{   
        var dadesFormulari = {
            'dataIniciConsulta': dataIniciConsulta,
            'dataFiConsulta': dataFiConsulta
        };
        
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody
            };        
        fetch(process.env.REACT_APP_DOMINI_API_PORTALCOMB + '/fixatge/infoEntreDates',requestOptions)

        .then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json();
            if (response.status != 200) {
                // get error message from body or default to response status
                //const error = (data && data.message) || response.status;
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
            }else
            {            
                //Fem el dispatch de l'acció login amb les dades rebudes del servei
                if(data.ok)
                {
                    dispatch(InfoFixatgesMesConsultat(data));
                }else
                {
                    //El servei d'aunteticació reporta un error de login
                    
                }  
            }
            //element.innerHTML = data.id;
        })
        .catch(error => {
           // element.parentElement.innerHTML = `Error: ${error}`;
            dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
            console.error('There was an error!', error);
        });
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
       
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


} 

export const recuperarVacancesEntreDates= (token,dataIniciConsulta,dataFiConsulta,llistatEmpresa,llistatTreb,llistatDep) =>{
    //Aquesta funció (Middleware), retorna un callback. Just quan finalitza la petició, execitarà l'acció 
    return (dispatch) =>{   
        var dadesFormulari = {
            'dataIniciConsulta': dataIniciConsulta,
            'dataFiConsulta': dataFiConsulta,
            'llistatEmpresa': llistatEmpresa,
            'llistatTreb': llistatTreb,
            'llistatDep':llistatDep
        };
        
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: JSON.stringify( {
                    'dataIniciConsulta': dataIniciConsulta,
                    'dataFiConsulta': dataFiConsulta,
                    'llistatEmpresa': llistatEmpresa,
                    'llistatTreb': llistatTreb,
                    'llistatDep':llistatDep
                }),
                mode: 'cors'
            };        
        fetch(process.env.REACT_APP_DOMINI_API_PORTALCOMB + '/vacances/entreDates',requestOptions)

        .then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json();
            if (response.status != 200) {
                // get error message from body or default to response status
                //const error = (data && data.message) || response.status;
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
            }else
            {            
                //Fem el dispatch de l'acció login amb les dades rebudes del servei
                if(data.ok)
                {
                    dispatch(VacancesMesConsultat(data));
                }else
                {
                    //El servei d'aunteticació reporta un error de login
                    
                }  
            }
            //element.innerHTML = data.id;
        })
        .catch(error => {
           // element.parentElement.innerHTML = `Error: ${error}`;
            dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
            console.error('There was an error!', error);
        });
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
       
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


} 

export const recuperarInfoVacancesEntreDates = (token) =>{
    //Aquesta funció (Middleware), retorna un callback. Just quan finalitza la petició, execitarà l'acció 
    return (dispatch) =>{
        //dispatch(ConfigUsuariRecuperarInfoIniciat(true));
        //dispatch(uiIniciarCarregaAction());      
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                mode: 'cors'
            };
            //TODO - Crear un fitxer on guardem constants segons l'entorn 
        fetch( process.env.REACT_APP_DOMINI_API_PORTALCOMB + '/vacances/infoEntreDates',requestOptions)
            .then(async response => {
               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                
                
                // check for error response
                if (!response.ok) {
                    
                    // get error message from body or default to response status
                    //const error = (data && data.message) || response.status;
                    //dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    if(data.ok)
                    {
                       
                        dispatch(VacancesInfoEntreDates(data));
                    }else
                    {

                    }
                }

                //dispatch(uiFinalitzarCarregaAction());
                //element.innerHTML = data.id;
            })
            .catch(error => {
               // element.parentElement.innerHTML = `Error: ${error}`;
               // dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
                console.error('There was an error!', error);
               // dispatch(uiFinalitzarCarregaAction());
            });
      
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


}
export const IncidenciaSemanaConsultada = (llistatIncidenciaSemanaConsultada) =>{
    return {
        type: types.calendariIncidenciaSemanaConsultada,
        llistatIncidenciaSemanaConsultada
    }

}

export const IncidenciaMesConsultat = (llistatIncidenciaMesConsultat) =>{
    return {
        type: types.calendariIncidenciaMesConsultat,
        llistatIncidenciaMesConsultat
    }

}

export const FixatgesSemanaConsultada = (llistatFixatgesSemanaConsultada) =>{
    return {
        type: types.calendariFixatgesSemanaConsultada,
        llistatFixatgesSemanaConsultada
    }

}

export const FixatgesMesConsultat = (llistatFixatgesMesConsultat) =>{
    return {
        type: types.calendariFixatgesMesConsultat,
        llistatFixatgesMesConsultat
    }
}

export const VacancesMesConsultat = (vacancesMesConsultat) =>{
    return {
        type: types.calendariVacancesMesConsultat,
        vacancesMesConsultat
    }
}

export const VacancesInfoEntreDates = (vacancesInfo) =>{
    return {
        type: types.calendariVacancesInfo,
        vacancesInfo
    }
}

export const InfoFixatgesMesConsultat = (infoFixatgesMesConsultat) =>{
    return {
        type: types.calendariInfoFixatgesMesConsultat,
        infoFixatgesMesConsultat
    }
}
export const IniciMesConsultat = (inici) =>{
        return {
            type: types.calendariIniciMesConsultat,
            inici
    }
}
export const FinalMesConsultat = (final) =>{
            return {
                type: types.calendariFinalMesConsultat,
                final
    }

}