
import {  recuperarDicFixatgesCalendari } from '../../actions/configAplicacioAction';
import { FinalMesConsultat, IniciMesConsultat, recuperarFixatgesEntreDates, recuperarIncidenciaEntreDates, recuperarInfoEntreDates, recuperarVacancesEntreDates } from '../../actions/configCalendariAction';
import { uiAmagarIncidenciesAction, uiAmagarIncidenciesRestaHoresAction } from '../../actions/ui';

//Aquest component recupera la informacio del mes en el primer clic
export  const RecuperarInfoVacances = (auth) => {
    
    return(dispatch)=>{
    
    let avui = new Date()
    let final = "";
    let inici = "";

        //si es aixi ens guardem l'inici i el final del mes actual
        inici = avui.getFullYear()+"-"+(avui.getMonth()+1)+"-01"
        if((avui.getMonth()+1)==12){
             final = (avui.getFullYear()+1)+"-01-01"
        }else{
             final = avui.getFullYear()+"-"+(avui.getMonth()+2)+"-01"
        }
        //i guardem aqui la informacio per poder utilizar-la per recuperar inf

        dispatch(recuperarVacancesEntreDates(auth.tokenJwt,inici,final,[],[],[]))
    }
    


}



