import { types } from "../types/types"
import { crearErrorAction, uiIniciarCarregaAction, uiFinalitzarCarregaAction } from "./ui";
import axios from 'axios';
import { alertaIncidenciaDiaAnterior, ConsultarIncidenciaDiaAnterior } from "./configIncidenciaAction";
import { ConfigUsuariRecuperarInfoFinalitzat, ConfigUsuariRecuperarInfoIniciat } from "./configUsuariAction";
import { ConsultarFixatgesSetmanaActualFinalitzat, JornadaIniciada } from "./controlPresenciaActions";


const peticioApi = axios.create({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json' },
    withCredentials: true,
    baseURL: process.env.REACT_APP_DOMINI_API_PORTALCOMB
 })







 export const  recuperarLoginActionAxios =   () => {
    //Aquesta funció (Middleware), retorna un callback. Just quan finalitza la petició, execitarà l'acció 
    return async dispatch =>{
        
        dispatch(uiIniciarCarregaAction());      
               
            peticioApi.get(`/auth/esticLoginat` )
            .then(response => {
                
             
              let data = response.data;
              //new Promise((resolve, reject) => setTimeout(() => resolve(), 1500));
              
              // check for error response
              if (response.status != 200) {
                  // get error message from body or default to response status
                  //const error = (data && data.message) || response.status;
                  dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                  return Promise.resolve(false);
                  
              }else
              {
                  //Fem el dispatch de l'acció login amb les dades rebudes del servei
                  if(data.ok)
                  {
                      let { token } = data;
                      let { idT,idE,nom,cognom,sexe,admin ,resp } = data.contingut;
                      dispatch(recuperarLogin(token,idT,idE,nom,cognom,sexe,admin,resp));
                      dispatch(uiFinalitzarCarregaAction());

                      return Promise.resolve(true);
                  }else
                  {
                      //El servei d'aunteticació reporta un error de login
                      dispatch(crearErrorAction(data.msg));
                      dispatch(uiFinalitzarCarregaAction());

                      return Promise.resolve(false);
                  }  
              }

              dispatch(uiFinalitzarCarregaAction());
              //element.innerHTML = data.id;
          })
          .catch(error => {
             // element.parentElement.innerHTML = `Error: ${error}`;
              dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
              console.error('There was an error!', error);
              dispatch(uiFinalitzarCarregaAction());
              return Promise.reject()
          });
            //TODO - Crear un fitxer on guardem constants segons l'entorn 
       
      
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


}





export const iniciarPeticioLoginAsincronaAxios = (email, clau) =>{
    //Aquesta funció (Middleware), retorna un callback. Just quan finalitza la petició, execitarà l'acció 
    return (dispatch) =>{
        dispatch(uiIniciarCarregaAction());      
        var dadesFormulari = {
            'usuari': email,
            'clau': clau
        };
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
        
        peticioApi.post(`/auth/login`, formBody )
        .then(response => {
            let data = response.data;
           
            if (response.status != 200) {
                // get error message from body or default to response status
                //const error = (data && data.message) || response.status;
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
            }else
            {
                //Fem el dispatch de l'acció login amb les dades rebudes del servei
                if(data.ok)
                {
                    let { token } = data;
                    let { idT,idE,nom,cognom,sexe ,admin,resp } = data.contingut;
                    dispatch(alertaIncidenciaDiaAnterior(token));
                    dispatch(login(token,idT,idE,nom,cognom,sexe,admin,resp));
                    
                }else
                {
                    //El servei d'aunteticació reporta un error de login
                    dispatch(crearErrorAction(data.msg));
                }  
            }

            dispatch(uiFinalitzarCarregaAction());
            //element.innerHTML = data.id;
        })
        .catch(error => {
           // element.parentElement.innerHTML = `Error: ${error}`;
            dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
            console.error('There was an error!', error);
            dispatch(uiFinalitzarCarregaAction());
        });
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
       
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


}
export const iniciarPeticioLoginAdminAsincronaAxios = (token) =>{
    //Aquesta funció (Middleware), retorna un callback. Just quan finalitza la petició, execitarà l'acció 
    return (dispatch) =>{
        dispatch(uiIniciarCarregaAction());      
        var dadesFormulari = {
            'token': token
        };
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
        
        peticioApi.post(`/auth/login/admin`, formBody )
        .then(response => {
            let data = response.data;
           
            if (response.status != 200) {
                // get error message from body or default to response status
                //const error = (data && data.message) || response.status;
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
            }else
            {
                //Fem el dispatch de l'acció login amb les dades rebudes del servei
                if(data.ok)
                {
                    let { token } = data;
                    let { idT,idE,nom,cognom,sexe ,admin,resp } = data.contingut;
                    dispatch(desloginarAction());
                    dispatch(alertaIncidenciaDiaAnterior(token));
                    dispatch(login());
                    dispatch(login(token,idT,idE,nom,cognom,sexe,admin,resp));
                    
                }else
                {
                    //El servei d'aunteticació reporta un error de login
                    dispatch(crearErrorAction(data.msg));
                }  
            }

            dispatch(uiFinalitzarCarregaAction());
            //element.innerHTML = data.id;
        })
        .catch(error => {
           // element.parentElement.innerHTML = `Error: ${error}`;
            dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
            console.error('There was an error!', error);
            dispatch(uiFinalitzarCarregaAction());
        });
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
       
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


}



export const iniciarPeticioLoginAsincrona = (email, clau) =>{
    //Aquesta funció (Middleware), retorna un callback. Just quan finalitza la petició, execitarà l'acció 
    return (dispatch) =>{
        dispatch(uiIniciarCarregaAction());      
      
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
        var dadesFormulari = {
            'usuari': email,
            'clau': clau
        };
        //Preparem els parametres post per enviar
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
        
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json' },
                body: formBody,
                credentials: 'include'
            };
            //TODO - Crear un fitxer on guardem constants segons l'entorn 
        fetch(process.env.REACT_APP_DOMINI_API_PORTALCOMB + '/auth/login',requestOptions)
            .then(async response => {
            
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
               
        
                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    //const error = (data && data.message) || response.status;
                    dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    //Fem el dispatch de l'acció login amb les dades rebudes del servei
                    if(data.ok)
                    {
                        let { token } = data;
                        let { idT,idE,nom,cognom,sexe,admin,resp  } = data.contingut;
                        dispatch(login(token,idT,idE,nom,cognom,sexe,admin,resp));
                    }else
                    {
                        //El servei d'aunteticació reporta un error de login
                        dispatch(crearErrorAction(data.msg));
                    }  
                }

                dispatch(uiFinalitzarCarregaAction());
                //element.innerHTML = data.id;
            })
            .catch(error => {
               // element.parentElement.innerHTML = `Error: ${error}`;
                dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
                console.error('There was an error!', error);
                dispatch(uiFinalitzarCarregaAction());
            });
      
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


}


export const recuperarLoginAction = () =>{
    //Aquesta funció (Middleware), retorna un callback. Just quan finalitza la petició, execitarà l'acció 
    return (dispatch) =>{
        
        dispatch(uiIniciarCarregaAction());      
                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
        
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json' },
            credentials: 'include'
        };
            //TODO - Crear un fitxer on guardem constants segons l'entorn 
        fetch(process.env.REACT_APP_DOMINI_API_PORTALCOMB + '/auth/esticLoginat',requestOptions)
            .then(async response => {
                
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
               
        
                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    //const error = (data && data.message) || response.status;
                    dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    //Fem el dispatch de l'acció login amb les dades rebudes del servei
                    if(data.ok)
                    {
                        let { token } = data;
                        let { idT,idE,nom,cognom,sexe,admin,resp  } = data.contingut;
                        dispatch(recuperarLogin(token,idT,idE,nom,cognom,sexe,admin,resp));
                    }else
                    {
                        //El servei d'aunteticació reporta un error de login
                        //dispatch(crearErrorAction(data.msg));
                    }  
                }

                dispatch(uiFinalitzarCarregaAction());
                //element.innerHTML = data.id;
            })
            .catch(error => {
               // element.parentElement.innerHTML = `Error: ${error}`;
                dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
                console.error('There was an error!', error);
                dispatch(uiFinalitzarCarregaAction());
            });
      
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


}

export const renovarTokenAction = (token) =>{
    //Aquesta funció (Middleware), retorna un callback. Just quan finalitza la petició, execitarà l'acció 
    return (dispatch) =>{
        
        //dispatch(uiIniciarCarregaAction());      

            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                mode: 'cors'
            };

            //TODO - Crear un fitxer on guardem constants segons l'entorn 
        fetch(process.env.REACT_APP_DOMINI_API_PORTALCOMB + '/auth/renewtoken',requestOptions)
            .then(async response => {
          
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
              
        
                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    //const error = (data && data.message) || response.status;
                    dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    //Fem el dispatch de l'acció login amb les dades rebudes del servei
                    if(data.ok)
                    {

                        let { token } = data;
                        let { idT,idE,nom,cognom,sexe,admin,resp  } = data.contingut;
                        dispatch(renovarToken(token,idT,idE,nom,cognom,sexe,admin,resp));
 
                    }else
                    {
                        dispatch(iniciDesloginar());
                        //El servei d'aunteticació reporta un error de login
                        //dispatch(crearErrorAction(data.msg));
                    }  
                }

                //dispatch(uiFinalitzarCarregaAction());
                //element.innerHTML = data.id;
            })
            .catch(error => {
               // element.parentElement.innerHTML = `Error: ${error}`;
                dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
                console.error('There was an error!', error);
                //dispatch(uiFinalitzarCarregaAction());
            });
      
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


}




export const desloginarAction =  () => {
    
    return  (dispatch) =>{
        
           
               
         peticioApi.get(`/auth/desloginar` )
            .then(response => {
                
   
              let data = response.data;
        
              // check for error response
              if (response.status != 200) {
                  // get error message from body or default to response status
                  //const error = (data && data.message) || response.status;
                  dispatch(crearErrorAction("Error del servei desloginar: " +  JSON.stringify(data)));
              }else
              {
                dispatch(ConfigUsuariRecuperarInfoFinalitzat(true,));
                dispatch(ConsultarFixatgesSetmanaActualFinalitzat());
                dispatch(ConsultarIncidenciaDiaAnterior());
                dispatch(JornadaIniciada());
                  //dispatch(iniciDesloginar());
              }

              dispatch(uiFinalitzarCarregaAction());
              //element.innerHTML = data.id;
          })
          .catch(error => {
             // element.parentElement.innerHTML = `Error: ${error}`;
              dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
              console.error('There was an error!', error);
              dispatch(uiFinalitzarCarregaAction());
          });
            //TODO - Crear un fitxer on guardem constants segons l'entorn 
      
    }


}



export const login = (tokenJwt, idT,idE,nom,cognom,sexe,admin,resp) =>{
    return {
        type: types.login,
        contingut:
        {
            tokenJwt,
            idT,
            idE,
            nom,
            cognom,
            sexe,
            admin,
            resp
        }
    }

}


export const recuperarLogin = (tokenJwt, idT,idE,nom,cognom,sexe,admin,resp) =>{
    return {
        type: types.recuperarLogin,
        contingut:
        {
            tokenJwt,
            idT,
            idE,
            nom,
            cognom,
            sexe,
            admin,
            resp
        }
    }
}

export const renovarToken = (tokenJwt, idT,idE,nom,cognom,sexe,admin,resp) =>{
    return {
        type: types.renovarToken,
        contingut:
        {
            tokenJwt,
            idT,
            idE,
            nom,
            cognom,
            sexe,
            admin,
            resp
        }
    }
}


export const iniciDesloginar = () => {
    return async (dispatch) =>{
        
        dispatch(desloginarAction());
        //Crear l'acció per desloginar l'usuari via API
        dispatch(logout());
    }
}


export const logout = () => {
    return { 
        type:types.logout
    }
}