import React from 'react'
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

//Aquest component retornarà el tipus de fixatge, segons el id proporcionat a les propietats
export const TipusFixatge = (props) => {
    const [t, i18n] = useTranslation('generic');
    const {idTipusFixatge, idTipusIncidencia} = (props); 
    let descTipusFixatge;
    switch(idTipusFixatge)
    {
        case 1:
            descTipusFixatge = t('TipusFixatge.EntradaPresencial');
            break;
        case 2:
            descTipusFixatge = t('TipusFixatge.EntradaTeletreball');
            break;
        case 3:
            descTipusFixatge = t('TipusFixatge.SortidaFijornada');
            break; 
        case 4:
            descTipusFixatge = t('TipusFixatge.SortidaDinar');
            break;
    }




    return (
        <>
            { descTipusFixatge }
        </>
    )
}



TipusFixatge.propTypes = {
    idTipusFixatge: PropTypes.number.isRequired,
    idTipusIncidencia: PropTypes.number
}
