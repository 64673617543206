import { min } from "moment";

    export const numeroHoresEntreDates = (dataInici,dataFi) =>
    {
    
        let replaceDataEntrada = dataInici.toString().replace(/\s/, 'T');
        let replaceDataSortida = dataFi.toString().replace(/\s/, 'T');

        let dataEntrada = new Date(replaceDataEntrada);
        let dataSortida = new Date(replaceDataSortida);

        const hores = parseInt(Math.abs(dataSortida - dataEntrada) / (1000 * 60 * 60) % 24);
        const minuts = parseInt(Math.abs(dataSortida.getTime() - dataEntrada.getTime()) / (1000 * 60) % 60);
        //const segons = parseInt(Math.abs(dataSortida.getTime() - dataEntrada.getTime()) / (1000) % 60); 
        return hores;

       
    
    }

    export const numeroSegonsEntreDates = (dataInici,dataFi) =>
    {
        //Aquesta funció ens retorna el número de segons entre dos dates doandes, per poder calcular el total d'hores i minuts d'una jornada.
        let replaceDataEntrada = dataInici.toString().replace(/\s/, 'T');
        let replaceDataSortida = dataFi.toString().replace(/\s/, 'T');

        let dataEntrada = new Date(replaceDataEntrada);
        let dataSortida = new Date(replaceDataSortida);

        //const hores = parseInt(Math.abs(dataSortida - dataEntrada) / (1000 * 60 * 60) % 24);
        //const minuts = parseInt(Math.abs(dataSortida.getTime() - dataEntrada.getTime()) / (1000 * 60) % 60);
        const segons = parseInt(Math.abs(dataSortida.getTime() - dataEntrada.getTime())); 

      

        return segons;
        convertirSegonsaHores(segons);
    
    }

    export const numeroSegonsEntreDates2 = (dataInici,dataFi) =>
    {
        //Aquesta funció ens retorna el número de segons entre dos dates doandes, per poder calcular el total d'hores i minuts d'una jornada.
        let replaceDataEntrada = dataInici.toString().replace(/\s/, 'T');
        
        var arr = replaceDataEntrada.split(/\D/); //Ho fem així, ja que de vegades el navegador no fa la conversió correctament.
        var dataEntrada = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]);

        //let dataEntrada = new Date(replaceDataEntrada);
        let dataSortida = new Date(dataFi);

        //const hores = parseInt(Math.abs(dataSortida - dataEntrada) / (1000 * 60 * 60) % 24);
        //const minuts = parseInt(Math.abs(dataSortida.getTime() - dataEntrada.getTime()) / (1000 * 60) % 60);
        const segons = parseInt(Math.abs(dataSortida.getTime() - dataEntrada.getTime())); 

      

        return segons;
        convertirSegonsaHores(segons);
    
    }
    export const provaSafari = (dataInici,dataFi) =>
    {
        let replaceDataEntrada = dataInici.toString().replace(/\s/, 'T'); //Afegit per evitar problemes de Safari
        
        var arr = replaceDataEntrada.split(/\D/);
        var dataEntrada = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]);
        //let dataEntrada = new Date(replaceDataEntrada);
        
        let dataIniciProva = new Date(dataInici);
        let dataSortida = new Date(dataFi);
        const segons = parseInt(Math.abs(dataSortida.getTime() - dataEntrada.getTime())); 
        return <> 
                <br/>   
                <br/> 
                <p> DataInici  { dataInici.toString() }</p> 
                <p> New Date amb dataInici  { dataIniciProva.toString() }</p> 
                <p> DataEntrada Replace { replaceDataEntrada }</p> 
                <p> DataEntrada {dataEntrada.toString()}</p>
                <p> DataSortida {dataSortida.toString()}</p> 
                <p> DataEntrada GetTime {dataEntrada.getTime().toString()}</p>
                <p> DataSortida GetTime {dataSortida.getTime().toString()}</p> 
                <p> Math.abs {Math.abs(dataSortida.getTime() - dataEntrada.getTime())} </p>
                <p> parseInt Segons { segons } </p>
             </>

    }

    export const convertirSegonsaHores= (segons) =>
    {
        //Aquesta funció retorna les hores i minuts dels segons enviats.

        //const hores = parseInt(Math.abs(dataSortida - dataEntrada) / (1000 * 60 * 60) % 24);
        //const minuts = parseInt(Math.abs(dataSortida.getTime() - dataEntrada.getTime()) / (1000 * 60) % 60);
        
        const hores = parseInt(segons / (1000 * 60 * 60));
        const minuts = parseInt(segons / (1000 * 60) % 60);
       

        return hores;
    }

    export const convertirSegonsaHoresSenseFormat24= (segons) =>
    {
        //Aquesta funció retorna les hores i minuts dels segons enviats.

        //const hores = parseInt(Math.abs(dataSortida - dataEntrada) / (1000 * 60 * 60) % 24);
        //const minuts = parseInt(Math.abs(dataSortida.getTime() - dataEntrada.getTime()) / (1000 * 60) % 60);
        
        const hores = parseInt(segons / (1000 * 60 * 60));
        const minuts = parseInt(segons / (1000 * 60) % 60);
       

        return hores;
    }



    export const convertirSegonsAMinuts= (segons) =>
    {
        //Aquesta funció retorna les hores i minuts dels segons enviats.

        //const hores = parseInt(Math.abs(dataSortida - dataEntrada) / (1000 * 60 * 60) % 24);
        //const minuts = parseInt(Math.abs(dataSortida.getTime() - dataEntrada.getTime()) / (1000 * 60) % 60);
        
        const minuts = parseInt(segons / (1000 * 60) % 60);
        return minuts;

    }

    export const convertirHoresaSegons= (hores) =>
    {
        //Aquesta funció retorna les hores i minuts dels segons enviats.

        //const hores = parseInt(Math.abs(dataSortida - dataEntrada) / (1000 * 60 * 60) % 24);
        //const minuts = parseInt(Math.abs(dataSortida.getTime() - dataEntrada.getTime()) / (1000 * 60) % 60);
        const segons = parseInt(hores * 3600  );
        
        //console.log('retorn de fixatges. Segons els segons  ' + segons + ' Les hores que han pasat son ' + hores + ' i minuts ' + minuts);
        return segons;
    }
    export const operacionsRestaHores = (segonsTotalDia,hores)=>{
        //Aquesta funcio retorna la resta amb segons despres de fer les operacions adient
        
        let horesTreballades =  parseInt(segonsTotalDia / (1000 * 60 * 60));
        let minutsTreballats =  parseInt(segonsTotalDia / (1000 * 60) % 60);


        let segonsATreballar = parseInt(hores * 3600 );

        let segonsTreballats = horesTreballades * 3600 + minutsTreballats * 60;

        

        let segonsRestants = segonsATreballar - segonsTreballats;

        return segonsRestants;

    }
    export const convertirComputHores = (computMinutsTreballador)=>{
        //Aquesta funcio converteix els minuts del comput hores setmanal
        // en segons que ha de fer el treballador en 1 dia
        
        let segons = computMinutsTreballador*60*1000/5;
        return segons;

    }

    export const convertirMinutsHores = (computMinuts)=>{
        //Aquesta funcio converteix els minuts a Hores    
        let hores= parseInt(computMinuts / 60 );
        return hores;
    }
    export const convertirMinutsMinuts = (computMinuts)=>{
        //Aquesta funcio retorna el minuts que restan del convertir a hores
        let minuts= parseInt((computMinuts)% 60);
        return minuts;
    }
    export const convertirMilisHores = (comput)=>{
        //Aquesta funcio retorna el minuts que restan del convertir a hores
        let hores= parseInt(comput / (60 * 60) / 1000);
        return hores;
    }
    export const convertirMilisMinuts = (comput)=>{
        //Aquesta funcio retorna el minuts que restan del convertir a hores
        let minuts = parseInt(comput  / (1000 * 60) % 60  );
        return minuts;
    }
    
    export const convertirSegonsHores = (comput)=>{
        //Aquesta funcio converteix els segons a Hores    
        let hores= parseInt(comput / (60 * 60));
        return hores;
    }
    export const convertirSegonsMinuts = (comput)=>{
        //Aquesta funcio retorna el minuts que restan del convertir segons a hores
        let minuts= parseInt((comput)/ (60) % 60);
        return minuts;
    }

  

