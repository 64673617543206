import React from 'react';
import { Switch, Routes,Route, Navigate } from 'react-router-dom';
import { LoginScreen } from '../Component/login/LoginScreen';
import PropTypes from 'prop-types';
import { LoginAdmin } from '../Component/login/LoginAdmin';
import { LoginResp } from '../Component/login/LoginResp';


export const PublicRoute = () => {
    
   

    return (
        <>
  
        <div className="container content-space-t-1 content-space-t-lg-0 content-space-b-2 content-space-b-lg-0">
            <div className="mx-lg-auto">
                <div className="d-flex justify-content-center align-items-center flex-column min-vh-lg-100">    
       
                    <Routes>
                    <Route path="/" element={<LoginScreen />} />
                    <Route exact path="/admin/:tokenAcces" element={<LoginAdmin />}/>
                    <Route exact path="/resp/:tokenAcces" element={<LoginResp />}/>
                    </Routes>
                    
                </div>  
            </div> 
        </div>
        </>
            
        
    )
}
