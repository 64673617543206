
import { types } from "../types/types";
import { recuperarIncidenciesAsync } from "./controlPresenciaActions";
import { crearErrorAction, uiIniciarCarregaAction, uiFinalitzarCarregaAction, uiMostraIncidenciaDiaAnterior, uiAmagarIncidenciesAction, crearTotOkAction, uiAmagarIncidenciesRestaHoresAction, esborrarErrorAction } from "./ui";


//ara
export const addIncidenciaAsync = (tipusIncidencia,token,dataInici,dataFi,observacions,fitxer,idioma='ca',admin) =>{
    //Aquesta action la farem servir per incidencies que hagin de pujar fitxer
    return (dispatch) =>{
        //dispatch(uiIniciarCarregaAction());      
       
        llegirFitxer(fitxer).then(async fitxerBase64 => {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
        var dadesFormulari = {
            'DataInici_IncidenciaFix': dataInici,
            'DataFi_IncidenciaFix': dataFi,
            'IdTipusIncidencia_IncidenciaFix': tipusIncidencia,
            'Observacions_IncidenciaFix': observacions,
            'JustificacioFitxer_IncidenciaFix': fitxerBase64,
            'nomFitxer': fitxer.name,
            'admin':admin
        };
        
        //Preparem els parametres post per enviar
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
           
            const requestOptions = {
                method: 'POST',
                headers: { 'Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: JSON.stringify( {
                    'DataInici_IncidenciaFix': dataInici,
                    'DataFi_IncidenciaFix': dataFi,
                    'IdTipusIncidencia_IncidenciaFix': tipusIncidencia,
                    'Observacions_IncidenciaFix': observacions,
                    'JustificacioFitxer_IncidenciaFix': fitxerBase64,
                    'nomFitxer': fitxer.name,
                    'admin':admin
                })
            };
           // console.log(requestOptions);
            //TODO - Crear un fitxer on guardem constants segons l'entorn 
        fetch(process.env.REACT_APP_DOMINI_API_PORTALCOMB + '/incidencia/add',requestOptions)
            .then(async response => {               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
               
                //Anem a recuperar els fixatges, després de fer la crida al servei add
                 dispatch(recuperarIncidenciesAsync(token));
        
                // check for error response
                if (!response.ok) {
                    if(idioma =="ca"){
                        dispatch(crearErrorAction(data.msg));
                    }else if(idioma =="es"){
                        dispatch(crearErrorAction(data.msg_es));
                    }
                    // get error message from body or default to response status
                    //const error = (data && data.message) || response.status;
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    //Fem el dispatch de l'acció login amb les dades rebudes del servei
                    if(data.ok)
                    {
                        dispatch(ConsultarIncidenciaDiaAnterior());
                        dispatch(uiAmagarIncidenciesAction())
                        if(idioma =="ca"){
                            dispatch(crearTotOkAction("Incidència realitzada correctament"));
                        }else if(idioma =="es"){
                            dispatch(crearTotOkAction("Incidencia realizada correctamente"));
                        }
                        dispatch(esborrarErrorAction());

                        
                        //let { token } = data;
                        //let { idT,idE,nom,cognom,sexe  } = data.contingut;
                        //dispatch(login(token,idT,idE,nom,cognom,sexe));
                    }else
                    {
                        //El servei d'aunteticació reporta un error de login
                        if(idioma =="ca"){
                            dispatch(crearErrorAction(data.msg));
                        }else if(idioma =="es"){
                            dispatch(crearErrorAction(data.msg_es));
                        }
                        //estatIncidencia(true)
                    }  
                }

                //dispatch(uiFinalitzarCarregaAction());
                //element.innerHTML = data.id;
            })
            .catch(error => {
               // element.parentElement.innerHTML = `Error: ${error}`;
                //dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
                console.error('There was an error!', error);
                //dispatch(uiFinalitzarCarregaAction());
            });
      
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/

        })
    }


}
export const addIncidenciaAsync2 = (tipusIncidencia,token,dataInici,dataFi,observacions,idioma='ca',admin) =>{
    
        //dispatch(uiIniciarCarregaAction());     

        return (dispatch) => {  
        
            
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
            var dadesFormulari = {
                'DataInici_IncidenciaFix': dataInici,
                'DataFi_IncidenciaFix': dataFi,
                'IdTipusIncidencia_IncidenciaFix': tipusIncidencia,
                'Observacions_IncidenciaFix': observacions,
                'admin':admin
            };
           
            //Preparem els parametres post per enviar
            var formBody = [];
                for (var property in dadesFormulari) {
                var encodedKey = encodeURIComponent(property);
                var encodedValue = encodeURIComponent(dadesFormulari[property]);
                formBody.push(encodedKey + "=" + encodedValue);
                }
                formBody = formBody.join("&");

                
            
            const requestOptions = {
                method: 'POST',
                headers: { 'Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: JSON.stringify( {
                    'DataInici_IncidenciaFix': dataInici,
                    'DataFi_IncidenciaFix': dataFi,
                    'IdTipusIncidencia_IncidenciaFix': tipusIncidencia,
                    'Observacions_IncidenciaFix': observacions,
                    'admin':admin
                })
            };
                //TODO - Crear un fitxer on guardem constants segons l'entorn 
            fetch(process.env.REACT_APP_DOMINI_API_PORTALCOMB + '/incidencia/add',requestOptions)
                .then(async response => {               
                    const isJson = response.headers.get('content-type')?.includes('application/json');
                    const data = isJson && await response.json();
                    
                    //Anem a recuperar els fixatges, després de fer la crida al servei add
                    // dispatch(recuperarFixatgesAsync(token));
            
                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response status
                        //const error = (data && data.message) || response.status;
                    dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                    }else
                    {
                        //Fem el dispatch de l'acció login amb les dades rebudes del servei
                        if(data.ok)
                        {
                            dispatch(ConsultarIncidenciaDiaAnterior());
                            dispatch(uiAmagarIncidenciesAction());
                            if(idioma =="ca"){
                                dispatch(crearTotOkAction("Incidència realitzada correctament"));
                            }else if(idioma =="es"){
                                dispatch(crearTotOkAction("Incidencia realizada correctamente"));
                            }
                            dispatch(esborrarErrorAction());
                            //let { token } = data;
                            //let { idT,idE,nom,cognom,sexe  } = data.contingut;
                            //dispatch(login(token,idT,idE,nom,cognom,sexe));
                        }else
                        {
                            //El servei d'aunteticació reporta un error de login
                            if(idioma =="ca"){
                                dispatch(crearErrorAction(data.msg));
                            }else if(idioma =="es"){
                                dispatch(crearErrorAction(data.msg_es));
                            }
                            //estatIncidencia(true)
                        }  
                    }

                    //dispatch(uiFinalitzarCarregaAction());
                    //element.innerHTML = data.id;
                })
                .catch(error => {
                // element.parentElement.innerHTML = `Error: ${error}`;
                    //dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
                    console.error('There was an error!', error);
                    //dispatch(uiFinalitzarCarregaAction());
                });
        
            /*setTimeout(() => {
                    //Als 3 segons, farem un dispatch del l'acció Login. 
                    
                    dispatch(login(123,345,"German, usuari autenticat"));

                }, 3500);*/
            }
        
    


}

export const alertaIncidenciaDiaAnterior = (token) =>{
    //Aquesta funció (Middleware), retorna un callback. Just quan finalitza la petició, execitarà l'acció 
    return (dispatch) =>{
        //dispatch(ConfigUsuariRecuperarInfoIniciat(true));
        //dispatch(uiIniciarCarregaAction());      
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                mode: 'cors'
            };
           
            //TODO - Crear un fitxer on guardem constants segons l'entorn 
        fetch( process.env.REACT_APP_DOMINI_API_PORTALCOMB + '/treballador/alertaDiaAnterior',requestOptions)
            .then(async response => {
               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                
               
                // check for error response
                if (!response.ok) {
                    
                    // get error message from body or default to response status
                    //const error = (data && data.message) || response.status;
                    //dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                
                let { dataDiaAnterior,missatge_ca,missatge_es,tipusMissatge,existeixError,hores,minuts  } = data;   
                dispatch(ConsultarIncidenciaDiaAnterior(dataDiaAnterior,missatge_ca,missatge_es,tipusMissatge,existeixError,hores,minuts));
                }

                //dispatch(uiFinalitzarCarregaAction());
                //element.innerHTML = data.id;
            })
            .catch(error => {
               // element.parentElement.innerHTML = `Error: ${error}`;
               // dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
                console.error('There was an error!', error);
               // dispatch(uiFinalitzarCarregaAction());
            });
      
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


}
export const addIncidenciaRestaHores = (id,token,dataInici,dataFi,idioma='ca') =>{
    
    //dispatch(uiIniciarCarregaAction());     

    return (dispatch) => {  
        var dadesFormulari = {
            'idFixatge':id,
            'DataInici_FixatgeFix': dataInici,
            'DataFi_FixatgeFix': dataFi
        };
        //Preparem els parametres post per enviar
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
        
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody,
                mode: 'cors'
            };

            //TODO - Crear un fitxer on guardem constants segons l'entorn 
        fetch(process.env.REACT_APP_DOMINI_API_PORTALCOMB + '/incidencia/restaHores/add',requestOptions)
            .then(async response => {               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                
                //Anem a recuperar els fixatges, després de fer la crida al servei add
                // dispatch(recuperarFixatgesAsync(token));
        
                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    //const error = (data && data.message) || response.status;
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    //Fem el dispatch de l'acció login amb les dades rebudes del servei
                    if(data.ok)
                    { 
                        dispatch(uiAmagarIncidenciesRestaHoresAction());
                        if(idioma =="ca"){
                            dispatch(crearTotOkAction("Incidència realitzada correctament"));
                        }else if(idioma =="es"){
                            dispatch(crearTotOkAction("Incidencia realizada correctamente"));
                        }
                        
                        //let { token } = data;
                        //let { idT,idE,nom,cognom,sexe  } = data.contingut;
                        //dispatch(login(token,idT,idE,nom,cognom,sexe));
                    }else
                    {
                        //El servei d'aunteticació reporta un error de login
                        if(idioma =="ca"){
                            dispatch(crearErrorAction(data.msg));
                        }else if(idioma =="es"){
                            dispatch(crearErrorAction(data.msg_es));
                        }
                        //estatIncidencia(true)
                    }  
                }

                //dispatch(uiFinalitzarCarregaAction());
                //element.innerHTML = data.id;
            })
            .catch(error => {
            // element.parentElement.innerHTML = `Error: ${error}`;
                //dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
                console.error('There was an error!', error);
                //dispatch(uiFinalitzarCarregaAction());
            });
    
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
        }
    



}
export const addIncidenciaRestaHoresUltimFixatge = (id,token,dataEntrada,dataSortida,idioma='ca') =>{
    
    //dispatch(uiIniciarCarregaAction());     

    return (dispatch) => {  
        var dadesFormulari = {
            'idFixatge':id,
            'Data_FixatgeFixEntrada': dataEntrada,
            'Data_FixatgeFixSortida': dataSortida
        };
        //Preparem els parametres post per enviar
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
        
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody,
                mode: 'cors'
            };

            //TODO - Crear un fitxer on guardem constants segons l'entorn 
        fetch(process.env.REACT_APP_DOMINI_API_PORTALCOMB + '/incidencia/restaHoresUltim/update',requestOptions)
            .then(async response => {               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                
                //Anem a recuperar els fixatges, després de fer la crida al servei add
                // dispatch(recuperarFixatgesAsync(token));
        
                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    //const error = (data && data.message) || response.status;
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    //Fem el dispatch de l'acció login amb les dades rebudes del servei
                    if(data.ok)
                    {
                        dispatch(uiAmagarIncidenciesRestaHoresAction());
                        if(idioma =="ca"){
                            dispatch(crearTotOkAction("Incidència realitzada correctament"));
                        }else if(idioma =="es"){
                            dispatch(crearTotOkAction("Incidencia realizada correctamente"));
                        }
                        
                        //let { token } = data;
                        //let { idT,idE,nom,cognom,sexe  } = data.contingut;
                        //dispatch(login(token,idT,idE,nom,cognom,sexe));
                    }else
                    {
                        //El servei d'aunteticació reporta un error de login
                        if(idioma =="ca"){
                            dispatch(crearErrorAction(data.msg));
                        }else if(idioma =="es"){
                            dispatch(crearErrorAction(data.msg_es));
                        }
                        //estatIncidencia(true)
                    }  
                }

                //dispatch(uiFinalitzarCarregaAction());
                //element.innerHTML = data.id;
            })
            .catch(error => {
            // element.parentElement.innerHTML = `Error: ${error}`;
                //dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
                console.error('There was an error!', error);
                //dispatch(uiFinalitzarCarregaAction());
            });
    
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
        }
    



}


const llegirFitxer = async (fitxer) => {
    const file = fitxer;
   
    const base64 = await convertBase64(fitxer);
    return base64;
  }


const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  export const ConsultarIncidenciaDiaAnterior = (dataDiaAnterior,missatge_ca,missatge_es,tipusMissatge,existeixError,hores,minuts) =>{
    return {
        type: types.alertaIncidenciaDiaAnterior,
        contingut:
        {
            dataDiaAnterior,
            missatge_ca,
            missatge_es,
            tipusMissatge,
            existeixError,
            hores,
            minuts
        }
        
        
    }
}

