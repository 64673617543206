import React from 'react'
import { useSelector } from 'react-redux';
import { uiAmagarCalendarModal } from '../../actions/ui';

export const LogoCapcaleraGlobal = () => {
  const { auth,ui } = useSelector(state => state);
    return (
        <>
        {auth.admin == 0 && !ui.resp?
        <>
        <div className='col-md-5 col-4'>
       
        <a className="navbar-brand mx-auto" href="../index.html" >
          <img className="navbar-brand-logo" src="../assets/img/logo-comb.png" alt="COMB" />
        </a>
        
      </div>
      <div className='col-4 col-md-6 align-right'>
    
        <a className="navbar-brand mx-auto" href="../index.html" >
          <img className="navbar-brand-logo" src="../assets/img/logo-med.png" alt="Med" />
        </a>
        
      </div>
      </>
      :
      <>
        <div className='col-md-5 col-4'>
       
        <a className="navbar-brand mx-auto"  >
          <img className="navbar-brand-logo" src="../assets/img/logo-comb.png" alt="COMB" />
        </a>
        
      </div>
      <div className='col-4 col-md-6 align-right'>
    
        <a className="navbar-brand mx-auto"  >
          <img className="navbar-brand-logo" src="../assets/img/logo-med.png" alt="Med" />
        </a>
        
      </div>
      </>
      }
       {/*<button type="button" className="navbar-toggler ms-auto" data-bs-toggle="collapse" data-bs-target="#navbarNavMenuDark" aria-label="Toggle navigation" aria-expanded="false" aria-controls="navbarNavMenuDark">
        <span className="navbar-toggler-default">
          <i className="bi-list"></i>
        </span>
        <span className="navbar-toggler-toggled">
          <i className="bi-x"></i>
        </span>
      </button> */} 
        </>
    )
}
