import React, { useEffect, useState } from 'react'

import * as moment from 'moment'

import 'moment-timezone';
import {  numeroSegonsEntreDates, convertirSegonsAMinuts, convertirSegonsaHores, numeroSegonsEntreDates2, operacionsRestaHores, convertirMilisMinuts, convertirMilisHores } from '../../Helpers/funcionsDates'


import {  useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';


export const ResumHoresDiaConsultat = (props) => {
    const [contadorJornadaIniciada, setContadorJornadaIniciada] = useState(new Date());
    const { cp, configUsuari,ui } = useSelector(state => state);
    const { incidenciaDiaConsultat, fixatgeDiaConsultat } = cp;
    const { dataAvui } = (props);
    const { contingut } = configUsuari

    let retornNumHoresJornada = "";
    const [t, i18n] = useTranslation('generic');
    let segonsTotalJornada = 0;
    let segonsJustificats = 0;
    let segonsNoJustificats = 0;
    let segonsNoAceptats = 0;
    let segonsTreballats = 0;

    let segonsTeleTreballTotalSetmana = 0;
    let segonsPresencialTotalSetmana = 0;

    let horesTreballadesTeletreball = 0;
    let horesTreballadesPresencial = 0;

    let minutsTreballatsTeletreball = 0;
    let minutsTreballatsPresencial = 0;

    let horesTreballades = 0;
    let minutsTreballats = 0;
    let horesTreballadesTotals = 0;
    let minutsTreballatsTotals = 0;

    let horesNoJustificades = 0;
    let minutsNoJustificats = 0;
    let horesNoAceptats = 0;
    let minutsNoAceptats = 0;
    let horesJustificades = 0;
    let minutsJustificats = 0;
    let computHores = 0;
    let computMinutsTreballador = 0;
    let computSegons = 0;
    let computSegonsTreballatsDeMes = 0;
    let horesDesMes = 0;
    let minutsDeMes = 0;

    let minutsBossaHores = 0;
    let horesBossaHores = 0;
    let segonsBossaHores = 0;

    let deMes = false;

    useEffect(() => {

        var dataActual = new Date();
        var offset = dataActual.getTimezoneOffset();
        //Si el offset de la data es 0, el navegador ens està retornant la hora en UTC. Haurem de sumar 1 hora o 2, depenen de si es horari d'estiu o hivern.
        //TODO Mirar si es horari d'hivern (+1) o estiu (+2) i fer la suma pertinent 
        //Haurem d'incorporar un paràmetre al configUsuari per establir la suma i recuperar-ho al state.
        if (offset == 0) {
            dataActual.setHours(dataActual.getHours() + contingut.offsetUTC / 60);

        }


        //S'executa cada 10 segons, per actualitzar els minuts, si la jornada està iniciada
        const interval = setInterval(
            () => setContadorJornadaIniciada(dataActual),
            10000
        )

        //console.log(Date());




        return () => {
            clearInterval(interval);
        }
    }, [contadorJornadaIniciada]);






    if (incidenciaDiaConsultat !== undefined) {
        incidenciaDiaConsultat.incidencies.forEach((e, index) => {
            if(e.contahores_Incidencia == 1 ){

            if (e.Justificat_IncidenciaFix == 1) {
                if(e.IdTipusIncidencia_IncidenciaFix == 1 || e.IdTipusIncidencia_IncidenciaFix == 4 ||  e.IdTipusIncidencia_IncidenciaFix== 12){
                    segonsJustificats = segonsJustificats + numeroSegonsEntreDates(e.DataInici_IncidenciaFix,e.DataFi_IncidenciaFix);
                }else{
                    if((contingut.computHores/5/60) >(numeroSegonsEntreDates(e.DataInici_IncidenciaFix,e.DataFi_IncidenciaFix)/ (60 * 60) / 1000)){
                        segonsJustificats = segonsJustificats + numeroSegonsEntreDates(e.DataInici_IncidenciaFix,e.DataFi_IncidenciaFix);
                    }else{
                        segonsJustificats = segonsJustificats + (contingut.computHores/5)*60*60*1000/60;
                    }
                }
            } else {
                if (e.Justificat_IncidenciaFix == null) {
                if((contingut.computHores/5/60) >(numeroSegonsEntreDates(e.DataInici_IncidenciaFix,e.DataFi_IncidenciaFix)/ (60 * 60) / 1000)){
                    segonsNoJustificats = segonsNoJustificats + numeroSegonsEntreDates(e.DataInici_IncidenciaFix, e.DataFi_IncidenciaFix);
                }else{
                    segonsNoJustificats = segonsNoJustificats + (contingut.computHores/5)*60*60*1000/60;
                }
                }else{
                    if((contingut.computHores/5/60) >(numeroSegonsEntreDates(e.DataInici_IncidenciaFix,e.DataFi_IncidenciaFix)/ (60 * 60) / 1000)){
                        segonsNoAceptats = segonsNoAceptats + numeroSegonsEntreDates(e.DataInici_IncidenciaFix, e.DataFi_IncidenciaFix);
                    }else{
                        segonsNoAceptats = segonsNoAceptats + (contingut.computHores/5)*60*60*1000/60;
                    }
                }
                
            }
            //segonsNoJustificats = segonsNoJustificats + numeroSegonsEntreDates(e.DataInici_IncidenciaFix,e.DataFi_IncidenciaFix);      
        }
    })
    }
    if(configUsuari!== undefined){
        if(configUsuari.contingut!== undefined){
        configUsuari.contingut.llistatDiesBorsaHores.diesBorsa.forEach((e,index) => {
            if(e.dataBorsa ===  ui.diaConsultat.dia  && e.estat === '1'){
        
                segonsBossaHores = segonsBossaHores + e.minutsDemanats * 60 *1000;
                
            }
        })
        }
    }




    if (fixatgeDiaConsultat !== undefined) {
        if (fixatgeDiaConsultat.fixatges !== undefined) {
        computMinutsTreballador = fixatgeDiaConsultat.computHores;
        //Com sempre d'una entrada i ha d'haver un valor de sortida, mirarem si el llistat de fixatges es parell o senar. Si es senar, vol dir que el treballador encara te una entrada sense sortida
        if (fixatgeDiaConsultat.fixatges.length % 2 === 0) {

            //La jornada te principis i finals. Podem calcular el número d'hores totals

            fixatgeDiaConsultat.fixatges.forEach((d, index) => {

                if (d.IdTipus_Fixatge === "1" || d.IdTipus_Fixatge === "2") {
                    //Quan entrada es Teletreball, Presencial o Normal:

                    segonsTotalJornada = segonsTotalJornada + numeroSegonsEntreDates(d.DataHora_Fixatge, fixatgeDiaConsultat.fixatges[index + 1].DataHora_Fixatge);
                    if (d.IdTipus_Fixatge === "2") {
                        segonsTeleTreballTotalSetmana = segonsTeleTreballTotalSetmana + numeroSegonsEntreDates(d.DataHora_Fixatge, fixatgeDiaConsultat.fixatges[index + 1].DataHora_Fixatge);

                    }
                    if (d.IdTipus_Fixatge === "1") {
                        segonsPresencialTotalSetmana = segonsPresencialTotalSetmana + numeroSegonsEntreDates(d.DataHora_Fixatge, fixatgeDiaConsultat.fixatges[index + 1].DataHora_Fixatge);

                    }
                }
            });
            segonsTreballats = segonsTotalJornada;



        } else {
            fixatgeDiaConsultat.fixatges.forEach((d, index) => {
                if ((d.IdTipus_Fixatge === "1" || d.IdTipus_Fixatge === "2") && (index) < fixatgeDiaConsultat.fixatges.length-1) {
                    

                    
                    //Quan entrada es Teletreball, Presencial o Normal:
                    if (d.IdTipus_Fixatge === "2") {
                        segonsTotalJornada = segonsTotalJornada + numeroSegonsEntreDates2(d.DataHora_Fixatge, fixatgeDiaConsultat.fixatges[index + 1].DataHora_Fixatge);

                        segonsTeleTreballTotalSetmana = segonsTeleTreballTotalSetmana + numeroSegonsEntreDates(d.DataHora_Fixatge, fixatgeDiaConsultat.fixatges[index + 1].DataHora_Fixatge);
                    }
                    if (d.IdTipus_Fixatge === "1") {
                        segonsTotalJornada = segonsTotalJornada + numeroSegonsEntreDates2(d.DataHora_Fixatge, fixatgeDiaConsultat.fixatges[index + 1].DataHora_Fixatge);

                        segonsPresencialTotalSetmana = segonsPresencialTotalSetmana + numeroSegonsEntreDates(d.DataHora_Fixatge, fixatgeDiaConsultat.fixatges[index + 1].DataHora_Fixatge);
                    }
                } else {

                    //Si es el dia Actual contem ultim fixatge, i anem sumant minuts al contador
                    //Si no es el dia Actual incidencia ja que es va deixar el fixatge obert
                    if (moment(dataAvui).format("DD/MM/YYYY") === moment(fixatgeDiaConsultat.dia).format("DD/MM/YYYY")) {

                        if (d.IdTipus_Fixatge === "2") {
                            if (fixatgeDiaConsultat.fixatges[fixatgeDiaConsultat.fixatges.length - 1] === fixatgeDiaConsultat.fixatges[index]) {
                                segonsTotalJornada = segonsTotalJornada + numeroSegonsEntreDates2(fixatgeDiaConsultat.fixatges[fixatgeDiaConsultat.fixatges.length - 1].DataHora_Fixatge, contadorJornadaIniciada);

                                segonsTeleTreballTotalSetmana = segonsTeleTreballTotalSetmana + numeroSegonsEntreDates2(fixatgeDiaConsultat.fixatges[fixatgeDiaConsultat.fixatges.length - 1].DataHora_Fixatge, contadorJornadaIniciada);
                            }
                        }
                        if (d.IdTipus_Fixatge === "1") {
                            if (fixatgeDiaConsultat.fixatges[fixatgeDiaConsultat.fixatges.length - 1] === fixatgeDiaConsultat.fixatges[index]) {
                                segonsTotalJornada = segonsTotalJornada + numeroSegonsEntreDates2(fixatgeDiaConsultat.fixatges[fixatgeDiaConsultat.fixatges.length - 1].DataHora_Fixatge, contadorJornadaIniciada);

                                segonsPresencialTotalSetmana = segonsPresencialTotalSetmana + numeroSegonsEntreDates2(fixatgeDiaConsultat.fixatges[fixatgeDiaConsultat.fixatges.length - 1].DataHora_Fixatge, contadorJornadaIniciada);
                            }
                        }

                    }
                }


            });
            //A partir d'aquí, tenim el número total de segons dels trams de jornada que tenen final. Ara haurem de calcular la diferencia de segons amb el temps actual (Quan es renderitza el component amb el useEffect cada 10 segons)

            segonsTreballats = segonsTotalJornada;



        }
    }
}
    //console.log(segonsNoJustificats);
   
    
    segonsTotalJornada = segonsTotalJornada + segonsJustificats;
    segonsTotalJornada = segonsTotalJornada + segonsBossaHores;
    horesTreballades = convertirSegonsaHores(segonsTreballats);
    minutsTreballats = convertirSegonsAMinuts(segonsTreballats);
    horesTreballadesTotals = convertirSegonsaHores(segonsTotalJornada);
    minutsTreballatsTotals = convertirSegonsAMinuts(segonsTotalJornada);

    horesBossaHores = convertirMilisHores(segonsBossaHores)
    minutsBossaHores = convertirMilisMinuts(segonsBossaHores)

    horesTreballadesPresencial = convertirSegonsaHores(segonsPresencialTotalSetmana);
    minutsTreballatsPresencial = convertirSegonsAMinuts(segonsPresencialTotalSetmana);

    horesTreballadesTeletreball = convertirSegonsaHores(segonsTeleTreballTotalSetmana);
    minutsTreballatsTeletreball = convertirSegonsAMinuts(segonsTeleTreballTotalSetmana);

    horesNoJustificades = convertirSegonsaHores(segonsNoJustificats);
    minutsNoJustificats = convertirSegonsAMinuts(segonsNoJustificats);

    horesNoAceptats = convertirSegonsaHores(segonsNoAceptats);
    minutsNoAceptats = convertirSegonsAMinuts(segonsNoAceptats);

    horesJustificades = convertirSegonsaHores(segonsJustificats);
    minutsJustificats = convertirSegonsAMinuts(segonsJustificats);

    
    //segonsTotalJornada = 34200000;

    // 9 hores = 32400000;
    // 9h i 30m = 34200000;
    //28800 segons Dia a fer
    //13330
    //computHores si algun dia es necessita hem de agafar-lo del Redux ara mateix esta hardcode

    computSegons = operacionsRestaHores(segonsTotalJornada, computHores / 5);
    
    if(computSegons > 0){
         
         deMes = false;
    }else{
     deMes = true;
     computSegonsTreballatsDeMes = computSegons*-1;
     computSegons=0;
     horesDesMes = parseInt(computSegonsTreballatsDeMes / (60 * 60));
     minutsDeMes = parseInt(computSegonsTreballatsDeMes / (60) % 60);


    }



    let horesRestants = parseInt(computSegons / (60 * 60));
    let minutsRestants = parseInt(computSegons / (60) % 60);


    return (
        <>
        {horesJustificades>0||minutsJustificats>0 ?
            <p><strong>{t('ResumHores.justificades')} </strong> {horesJustificades} {t('Generics.hores')} {t('Generics.caracIoY')} {minutsJustificats} {t('Generics.minuts')}</p>
        :null
        }
        {horesNoJustificades>0||minutsNoJustificats>0 ?
            <p><strong>{t('ResumHores.noJustificades')}</strong> {horesNoJustificades} {t('Generics.hores')} {t('Generics.caracIoY')} {minutsNoJustificats} {t('Generics.minuts')}</p>
        :null
        }
        {horesNoAceptats>0||minutsNoAceptats>0 ?
            <p><strong>{t('ResumHores.noAceptades')}</strong> {horesNoAceptats} {t('Generics.hores')} {t('Generics.caracIoY')} {minutsNoAceptats} {t('Generics.minuts')}</p>
        :null
        }
  
        {horesTreballadesTeletreball>0||minutsTreballatsTeletreball>0 ?
            <p><strong>{t('ResumHores.teletreball')}</strong> {horesTreballadesTeletreball} {t('Generics.hores')} {t('Generics.caracIoY')} {minutsTreballatsTeletreball} {t('Generics.minuts')}</p>
        :null
        }
        {horesTreballadesPresencial>0||minutsTreballatsPresencial>0 ?
            <p><strong>{t('ResumHores.presencial')} </strong> {horesTreballadesPresencial} {t('Generics.hores')} {t('Generics.caracIoY')}  {minutsTreballatsPresencial} {t('Generics.minuts')}</p>
            :null
        } 
        {horesBossaHores>0||minutsBossaHores>0 ?
            <p><strong>{t('ResumHores.bossaHores')}</strong> {horesBossaHores} {t('Generics.hores')} {t('Generics.caracIoY')}  {minutsBossaHores} {t('Generics.minuts')}</p>
            :null
        }   
 
        {/* <p><strong>{t('ResumHores.horesTreball')} </strong> {horesTreballades} {t('Generics.hores')} {t('Generics.caracIoY')}  {minutsTreballats} {t('Generics.minuts')}</p> */}
        <p><strong>{t('ResumHores.diaTotal')} </strong> {horesTreballadesTotals} {t('Generics.hores')} {t('Generics.caracIoY')}  {minutsTreballatsTotals} {t('Generics.minuts')}</p>

        {/* {deMes?
            <p><strong>Hores de Mes: </strong> {horesDesMes} {t('Generics.hores')} {t('Generics.caracIoY')}  {minutsDeMes} {t('Generics.minuts')}</p>
           :<p><strong>{t('ResumHores.restants')} </strong> {horesRestants} {t('Generics.hores')} {t('Generics.caracIoY')}  {minutsRestants} {t('Generics.minuts')}</p>

        } */}
        </>
    )
}
