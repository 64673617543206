import { MenuPrincipal } from "../Component/ui/MenuPrincipal"
import { Routes, Route, useNavigate } from "react-router-dom";
import { RegistreScreen } from '../Component/registre/RegistreScreen';
import { PreferenciesScreen } from "../Component/preferencies/PreferenciesScreen";
import { CalendarScreen } from "../Component/Calendar/CalendarScreen";
import { CalendarVacancesScreen } from "../Component/CalendariVacances/CalendarVacancesScreen";
import { FooterPublic } from "../Component/ui/FooterPublic";
import { DetallDiaScreen } from "../Component/DetallDia/DetallDiaScreen";
import { HeaderPublic } from "../Component/ui/HeaderPublic";
import { ManualsScreen } from "../Component/Manuals/ManualsScreen";


export const DashboardRoutes = () => {
    
   

    return (
        <>
        <HeaderPublic />
        <div className="container">

        <Routes>
        <Route path="/" element={<RegistreScreen />} />
        <Route path="registre" element={<RegistreScreen />} />
        <Route path="calendar" element={<CalendarScreen />} />
        <Route path="calendariVacances" element={<CalendarVacancesScreen />} /> 
        <Route path="manuals" element={<ManualsScreen />} />
        <Route path="preferencies" element={<PreferenciesScreen />} />
        <Route path="diadetall" element={<DetallDiaScreen />} />
        </Routes>
           
           
        </div>

        <FooterPublic  />
        </>
            
        
    )
}

