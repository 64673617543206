import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'
import { useNavigate } from 'react-router-dom'
import 'moment-timezone';

import { useTranslation } from 'react-i18next';
import {numeroSegonsEntreDates, convertirMilisMinuts, convertirMilisHores, numeroSegonsEntreDates2 } from '../../Helpers/funcionsDates'


import { useDispatch, useSelector } from 'react-redux';
import { esborrarErrorAction, uiAmagarIncidenciesAction, uiMostraDiaConsultatAction } from '../../actions/ui';
import moment from 'moment-timezone';



export const ContadorJornadaHoresSetmana = (props) => {
    const state = useSelector(state => state);
    const { configUsuari } = useSelector(state => state);
    const {contingut} = configUsuari;
    const { cp } = useSelector(state => state);
    const dispatch = useDispatch(); //Per despachar accions
    const { auth } = useSelector(state => state);
    const {diaAmbFixatges} = (props); 
    const {ui} = useSelector(state => state);
    const {incidencia} = ui;
    let diaAmbIncidencia = false;
    let diaAvui = false;
    let tipusDia = "Laborable";
    let segonsTotalDia = 0;
    let sumaMinuts = 0;
    let borsaHoresDia;
    let navigate = useNavigate();
    let horesTreballades = 0;
    let minutsTreballats = 0;
    const [t, i18n] = useTranslation('generic');
    let dataAvui = new Date();
    const [contadorJornadaIniciada, setContadorJornadaIniciada] = useState(new Date());

    useEffect(() => {

        var dataActual = new Date();
        var offset = dataActual.getTimezoneOffset();
        //Si el offset de la data es 0, el navegador ens està retornant la hora en UTC. Haurem de sumar 1 hora o 2, depenen de si es horari d'estiu o hivern.
        //TODO Mirar si es horari d'hivern (+1) o estiu (+2) i fer la suma pertinent 
        //Haurem d'incorporar un paràmetre al configUsuari per establir la suma i recuperar-ho al state.
        if (offset == 0) {
            dataActual.setHours(dataActual.getHours() + contingut.offsetUTC / 60);

        }


        //S'executa cada 10 segons, per actualitzar els minuts, si la jornada està iniciada
        const interval = setInterval(
            () => setContadorJornadaIniciada(dataActual),
            10000
        )

        //console.log(Date());




        return () => {
            clearInterval(interval);
        }
    }, [contadorJornadaIniciada]);

    if(configUsuari !== undefined)
    {
        //Mirem si el dia enviat, te alguna particularitat especial.
        let date = new Date(diaAmbFixatges.dia);
        var dayOfWeek = date.getDay();
        var isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
        if( isWeekend){
            tipusDia = "Finde";
        }

        if (configUsuari.contingut.llistatFestius.diesFestius.some(e => e.dataFestiu ===  diaAmbFixatges.dia )) 
        {
            tipusDia = "Festiu";
        }
        if (configUsuari.contingut.llistatVacances.diesVacances.some(e => e.dataVacances ===  diaAmbFixatges.dia )) 
        {
            tipusDia = "Vacances";
        }
        borsaHoresDia = configUsuari.contingut.llistatDiesBorsaHores.diesBorsa.some(e => e.dataBorsa ===  diaAmbFixatges.dia );
        if (borsaHoresDia) 
        {
            
            tipusDia = "Borsa hores";
            //Aquí hem de rebre els minuts i sumar-los
            sumaMinuts = borsaHoresDia.minutsDemanats;
        }



    }
    diaAmbFixatges.fixatges.forEach((d,index) =>  {
               
        if(d.IdTipus_Fixatge === "1" || d.IdTipus_Fixatge === "2" ){
            //Quan entrada es Teletreball, Presencial o Normal:
            if(diaAmbFixatges.fixatges[index+1] === undefined)
            {
                //Si es el dia d'avui, no el marcaram com incidencia. 
                dataAvui.setHours(0,0,0,0); //Eliminem les hores per poder comparar entre dates
                let dataDiaRebut = new Date(diaAmbFixatges.dia);
                dataDiaRebut.setHours(0,0,0,0);
               
                if(dataAvui.getTime() !== dataDiaRebut.getTime())
                {
                    diaAmbIncidencia = true;
                }else
                {
                    diaAvui = true;
                } 
 
                if(moment(d.DataHora_Fixatge).format("YYYY-MM-DD") == moment(dataAvui).format("YYYY-MM-DD")){
                    segonsTotalDia = segonsTotalDia + numeroSegonsEntreDates2(d.DataHora_Fixatge,contadorJornadaIniciada);     
                }
            }else
            {
                segonsTotalDia = segonsTotalDia + numeroSegonsEntreDates(d.DataHora_Fixatge,diaAmbFixatges.fixatges[index+1].DataHora_Fixatge);
            }


            
        }
        });
       
        if(cp.llistatIncidenciesSetmana !== undefined) {
            cp.llistatIncidenciesSetmana.llistatIncidencies.forEach((e,index) => {
                if(diaAmbFixatges.dia >= moment(e.DataInici_IncidenciaFix).format("YYYY-MM-DD") && diaAmbFixatges.dia <= moment(e.DataFi_IncidenciaFix).format("YYYY-MM-DD")   ){
                if(e.contahores_Incidencia== 1 ){
                    if(e.Justificat_IncidenciaFix == 1 && tipusDia== "Laborable"){
                        if(e.IdTipusIncidencia_IncidenciaFix == 1 || e.IdTipusIncidencia_IncidenciaFix == 4 ||  e.IdTipusIncidencia_IncidenciaFix== 12){
                            segonsTotalDia = segonsTotalDia + numeroSegonsEntreDates(e.DataInici_IncidenciaFix,e.DataFi_IncidenciaFix);
                        }else{
                            if((props.hores/5)>(numeroSegonsEntreDates(e.DataInici_IncidenciaFix,e.DataFi_IncidenciaFix)/ (60 * 60) / 1000)){
                                segonsTotalDia = segonsTotalDia + numeroSegonsEntreDates(e.DataInici_IncidenciaFix,e.DataFi_IncidenciaFix);
                            }else{
                                segonsTotalDia = segonsTotalDia + (props.hores/5)*60*60*1000;
                            }

                        }
                        
                    }
                } 
                }   
            })  
            }
        if(configUsuari!== undefined){
            configUsuari.contingut.llistatDiesBorsaHores.diesBorsa.forEach((e,index) => {
                
                if(e.dataBorsa ===  diaAmbFixatges.dia  && e.estat === '1'){

                    segonsTotalDia =segonsTotalDia + e.minutsDemanats * 60*1000;
                }
            })
        }
        
         horesTreballades = convertirMilisHores(segonsTotalDia)
         minutsTreballats = convertirMilisMinuts(segonsTotalDia)


        function handleClick(dia) {
            dispatch(uiMostraDiaConsultatAction({ dia }))
          }
          let descTipusAlertaFixatge;
          if(diaAmbFixatges.dia==moment(dataAvui).format('YYYY-MM-DD')){
            descTipusAlertaFixatge = "DiaAvui";

          }else{

          
          if(diaAmbFixatges.esLaborable){
          switch(diaAmbFixatges.idTipusAlerta)
          {
              case 0:
                  descTipusAlertaFixatge = "DiaComplet";
                  break;
              case 1:
                  descTipusAlertaFixatge = "DiaFestiu";
                  break;
              case 2:
                  descTipusAlertaFixatge = "Vacances";
                  break;
              case 3:
                  if(diaAmbFixatges.dia < moment(dataAvui).format('YYYY-MM-DD')){
                    descTipusAlertaFixatge = "Incidencia";
                  }
                  
                  break; 
              case 4:
                if(diaAmbFixatges.dia < moment(dataAvui).format('YYYY-MM-DD')){
                    descTipusAlertaFixatge = "Incidencia";
                  }
                  break;
          }
        }else{
            if(diaAmbFixatges.idTipusAlerta == 0){
                descTipusAlertaFixatge = "CapSetmana";
            }
        }
    }
       


//Aquest component serveix per mostrar el total d'hores d'un dia de la setmana actual. Es verifica que aquets dies no tinguin cap incidencia.
    return (

        <tr className={descTipusAlertaFixatge}>
            <td> <a className='underline'  onClick={() => {
                handleClick(diaAmbFixatges.dia) 
                if(incidencia){
                    dispatch(uiAmagarIncidenciesAction())
                    dispatch(esborrarErrorAction())
                  }
                }}>    <Moment format="DD/MM/YYYY" date={diaAmbFixatges.dia} />  </a>   </td>
           
            <td className={`hores  ${diaAmbIncidencia ? "incidenciaDia" : ""} ${diaAvui ? "diaAvui" : ""}`}> { horesTreballades}  {t('Generics.hores')} {t('Generics.caracIoY')} { minutsTreballats}  {t('Generics.minuts')}</td>
           
        </tr>

          
   
    )
}
