import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import "flatpickr/dist/themes/material_green.css";
import "react-datetime/css/react-datetime.css";
import { useNavigate } from 'react-router-dom'
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/ca';
import Flatpickr from "react-flatpickr";
import Moment from 'react-moment';
import { TipusFixatge } from '../ControlPresencia/TipusFixatge';
import { crearErrorAction, esborrarErrorAction,uiAmagarIncidenciesRestaHoresFiJornadaAction } from '../../actions/ui';
import {  addIncidenciaRestaHoresUltimFixatge } from '../../actions/configIncidenciaAction';
import { recuperarFixatgesAsync, recuperarFixatgesSetmanaActual } from '../../actions/controlPresenciaActions';



export const FormulariIncidenciaRestaHoresFiJornada = (props) => {

  const dispatch = useDispatch(); //Per despachar accions
  const state = useSelector(state => state);
  const { auth } = useSelector(state => state);
  const { ui } = useSelector(state => state);
  const { loading, msgError } = useSelector(state => state.ui);
  const { configAplicacio, cp } = useSelector(state => state);
  const {fixatgeDiaConsultat } = cp;
  const [t, i18n] = useTranslation('generic');
  const { diaConsultat } = (props);
  const [tipusFixatge, setTipusFixatge] = useState('');
  const [tipusFixatgeSortida, setTipusFixatgeSortida] = useState('');

  const [dataInici, setDataInici] = useState(new Date());
  const [dataFinal, setDataFinal] = useState(new Date());
  const [dataFixatgeSortida, setDataFixatgeSortida] = useState(new Date());
  const [dataFixatgeEntrada, setDataFixatgeEntrada] = useState(new Date());

  const [dataIniciMin, setDataIniciMin] = useState();
  const [dataFinalMax, setDataFinalMax] = useState();
  const [dataIniciMinSortida, setDataIniciMinSortida] = useState();
  const [dataFinalMaxSortida, setDataFinalMaxSortida] = useState();

  const [fitxer, setFitxer] = useState(null);

  const navegacio = useNavigate();
  let idiomaDatetime = t('Generics.idiomaDatetime');
  let listItems = "";
  let listFixatges = [];
  let listFixatgesSortida = [];
  let fixatge="";
  let fixatgeSortida= "";
  let listUltimFixatge="";


  useEffect(() => {
    //Mirem si la propietat estaLoginat canvia. Si es així, redireccionem a la part privada
    if (auth.tokenJwt == undefined) {
      navegacio('/');
    }

  }, [auth]);
  if(fixatgeDiaConsultat !== undefined){

    if(fixatgeDiaConsultat.fixatges !== undefined){
  
      listItems = fixatgeDiaConsultat.fixatges.map((d) => ({
        IdFixatge : d.IdFixatge,
        IdTipus_Fixatge : d.IdTipus_Fixatge,
        IdTipusIncidencia_Fixatge : d.IdTipusIncidencia_Fixatge,
        DataHora_Fixatge : d.DataHora_Fixatge
  })); 
            for (let index = 0; index < listItems.length-1; index += 2) {
                let IdFixatge = listItems[index].IdFixatge + "-" + listItems[index+1].IdFixatge
                let IdTipus_Fixatge1 = listItems[index].IdTipus_Fixatge
                let IdTipus_Fixatge2 = listItems[index+1].IdTipus_Fixatge
                let DataHora_Fixatge1 = listItems[index].DataHora_Fixatge
                let DataHora_Fixatge2 = listItems[index+1].DataHora_Fixatge
                let IdTipusIncidencia_Fixatge1 = listItems[index].IdTipusIncidencia_Fixatge
                let IdTipusIncidencia_Fixatge2 = listItems[index+1].IdTipusIncidencia_Fixatge
            
                fixatge =
                <div className='form-check mb-1' key={IdFixatge}>
                    <input type="radio" id={"tipusIncidenciaRadio" + IdFixatge} className="form-check-input" name="tipusIncidencia" value={IdFixatge}
                        onChange={(e) => { setTipusFixatge(e);setDataIniciMin(DataHora_Fixatge1);setDataFinalMax(DataHora_Fixatge2)}} />
                    <label className="form-check-label" htmlFor={"tipusFixatgeRadio" + IdFixatge}>
                        
                    <TipusFixatge idTipusFixatge={Number(IdTipus_Fixatge1)} idTipusIncidencia={Number(IdTipusIncidencia_Fixatge1)} /> - <Moment format="HH:mm" date={DataHora_Fixatge1}></Moment><> </>
                    <TipusFixatge idTipusFixatge={Number(IdTipus_Fixatge2)} idTipusIncidencia={Number(IdTipusIncidencia_Fixatge2)} /> - <Moment format="HH:mm" date={DataHora_Fixatge2}></Moment></label>
                </div>
                listFixatges.push(fixatge);
            }
            for (let index = 0; index < listItems.length-1; index += 2) {
              let IdFixatge = listItems[index+1].IdFixatge
              let IdTipus_Fixatge1 = listItems[index].IdTipus_Fixatge
              let IdTipus_Fixatge2 = listItems[index+1].IdTipus_Fixatge
              let DataHora_Fixatge1 = listItems[index].DataHora_Fixatge
              let DataHora_Fixatge2 = listItems[index+1].DataHora_Fixatge
              let IdTipusIncidencia_Fixatge1 = listItems[index].IdTipusIncidencia_Fixatge
              let IdTipusIncidencia_Fixatge2 = listItems[index+1].IdTipusIncidencia_Fixatge
          
              fixatgeSortida =
              <div className='form-check mb-1' key={IdFixatge}>
                  <input type="radio" id={"tipusIncidenciaRadio" + IdFixatge} className="form-check-input" name="tipusIncidencia" value={IdFixatge}
                      onChange={(e) => { setTipusFixatgeSortida(e);setDataIniciMinSortida(DataHora_Fixatge1);setDataFinalMaxSortida(DataHora_Fixatge2);setDataFixatgeEntrada(DataHora_Fixatge1)}} />
                  <label className="form-check-label" htmlFor={"tipusFixatgeRadio" + IdFixatge}>
                      
                  <TipusFixatge idTipusFixatge={Number(IdTipus_Fixatge1)} idTipusIncidencia={Number(IdTipusIncidencia_Fixatge1)} /> - <Moment format="HH:mm" date={DataHora_Fixatge1}></Moment><> </>
                  <TipusFixatge idTipusFixatge={Number(IdTipus_Fixatge2)} idTipusIncidencia={Number(IdTipusIncidencia_Fixatge2)} /> - <Moment format="HH:mm" date={DataHora_Fixatge2}></Moment></label>
              </div>
              listFixatgesSortida.push(fixatgeSortida);
          }

    }
  }
  //funcio que realitza el dispatch per pujar l'incidencia a la base de dades

  const handleUltimFixatge = (e) => {
   
    e.preventDefault();
            dispatch(addIncidenciaRestaHoresUltimFixatge(tipusFixatgeSortida.target._wrapperState.initialValue,auth.tokenJwt,moment(diaConsultat).format('YYYY-MM-DD ') +moment(dataFixatgeEntrada).format('HH:mm:ss'),moment(diaConsultat).format('YYYY-MM-DD ') +moment(dataFixatgeSortida).format('HH:mm:ss')));
            dispatch(uiAmagarIncidenciesRestaHoresFiJornadaAction());
            dispatch(recuperarFixatgesAsync(auth.tokenJwt));
            dispatch(recuperarFixatgesSetmanaActual(auth.tokenJwt));


  }

  //Funcio que ens retorna un boolean per saber si l'estat del form es correcte 
  //tant les obseervacions estan omplertes com tenim una incidencia selecionad
  const isFormValid = () => {
    let esCorrecte = true;
    if(tipusFixatge == ''){
        dispatch(crearErrorAction('Cap Selec'));
        esCorrecte = false;
    }else{
        if(dataInici>=dataFinal){
            dispatch(crearErrorAction('Data Inici mes gran a Data Final'));
            esCorrecte = false;
        }else{
        //pasem Data Inicial Minima i Final Maximaa new Date per poder comparar
            if(moment(dataInici).format('HH:mm:ss') <= moment(dataIniciMin).format('HH:mm:ss') || moment(dataFinal).format('HH:mm:ss')>=moment(dataFinalMax).format('HH:mm:ss')){
                esCorrecte = false;
                dispatch(crearErrorAction('Les dates No estan entre el rang definit previamnet '));
            }else{
                dispatch(esborrarErrorAction());
            }
    
         }
    }
return esCorrecte;
}


  return (
    <>
    

    {/* Aqui esta la Segona part de resta Hores */}
    {listItems !=undefined ?
    <div className='row'>
    <h5>{t('FormulariIncidencies.crear')}</h5>
    {i18n.language =="ca" ?
      <p>Modifica el darrer fixatge del dia </p>
    
    : <p>Modifica el último fichaje del día  </p>
  }
    <div className='mx-4'>
      
    {listFixatgesSortida}
    </div>

    {(tipusFixatgeSortida !== '' )?   
    <div className='container mt-5'  >
  
          <div className="row mb-4">
          <div className='col-md-4'>
              <label className="form-label align-right" >Hora de la nova sortida</label>
          </div>
              <div className='col-md-8'>
                  <Flatpickr
                   options={{dateFormat:"H:i ",enableTime: true,
                   noCalendar: true,time_24hr: true,
                   locale: idiomaDatetime,minDate:moment(dataIniciMinSortida).format('HH:mm:ss'),maxDate:moment(dataFinalMaxSortida).format('HH:mm:ss')}}
                   
                  data-enable-time
                  //per definir el valor inicial
                  value={new Date(dataFixatgeSortida)}
                  onChange={(dataFixatgeSortida) => setDataFixatgeSortida(new Date(dataFixatgeSortida))} 
                  /> 
              </div>
          </div>
        
      
      <div className="text-center">
        <button onClick={handleUltimFixatge} className="btn btn-primary btn-sm m-1" disabled={loading} >{t('FormulariIncidencies.btnDadesIncidencia')}</button>
      </div>
      
    </div>
    : null}
  </div>
  :null}
  </>
  
  )
}
