import React, { useEffect, useState } from 'react'
import 'moment-timezone';
import {numeroSegonsEntreDates, convertirSegonsAMinuts, convertirSegonsaHores, numeroSegonsEntreDates2 } from '../../Helpers/funcionsDates'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { recuperarFixatgesAsync, recuperarIncidenciesAsync } from '../../actions/controlPresenciaActions';

//Aquest component mostrarà les hores de la jornada actual


//Mostrar missatge si la jornada encara no ha començat.
//Mostrar missatge i contador quan la jornada ha començat i no ha finalitzat.
//Mostrar missatge i numero d' hores totals de la jornada, quan aquesta ha finalitzat o existeix un fixatge de sortida.

export const ContadorHoresJornada = (props) => {
    const dispatch = useDispatch(); //Per despachar accions
    const { auth } = useSelector(state => state);
    const [contadorJornadaIniciada, setContadorJornadaIniciada] = useState(new Date());
    const { cp,configUsuari ,ui} = useSelector(state => state);
    const {incidencia,incidenciaRestaHores,incidenciaRestaHoresFiJornada} = ui;
    const { jornadaIniciada, llistatFixatges, jornadaFinalitzada,llistatIncidencies } = cp;
    const [t, i18n] = useTranslation('generic');
    const {contingut} = props
    let segonsTotalJornada = 0;
    let segonsJustificats = 0;
    let horesTreballades = 0;
    let minutsTreballats = 0;
    

    useEffect(() => {
        if(contingut!=undefined){

        
        var dataActual = new Date();
        var offset = dataActual.getTimezoneOffset();
        //Si el offset de la data es 0, el navegador ens està retornant la hora en UTC. Haurem de sumar 1 hora o 2, depenen de si es horari d'estiu o hivern.
        //TODO Mirar si es horari d'hivern (+1) o estiu (+2) i fer la suma pertinent 
        //Haurem d'incorporar un paràmetre al configUsuari per establir la suma i recuperar-ho al state.
        if(offset == 0)
        {
            dataActual.setHours( dataActual.getHours() + contingut.offsetUTC/60 );
           
        }
       
        
        //S'executa cada 10 segons, per actualitzar els minuts, si la jornada està iniciada
        const interval = setInterval(
          () => setContadorJornadaIniciada(dataActual),
          10000
        )
       
        return () => {
          clearInterval(interval);
        }
    }
      }, [contadorJornadaIniciada]);

useEffect(() => {
    dispatch(recuperarFixatgesAsync(auth.tokenJwt));
    dispatch(recuperarIncidenciesAsync(auth.tokenJwt));
}, [incidenciaRestaHores,incidenciaRestaHoresFiJornada,incidencia])

if(cp !== undefined){

    if(llistatIncidencies !== undefined) {
    cp.llistatIncidencies.forEach((e,index) => {
        if(e.IdTipusIncidencia_IncidenciaFix !=="6" && e.IdTipusIncidencia_IncidenciaFix !=="8" ){
            if(e.Justificat_IncidenciaFix == 1){
                if(e.IdTipusIncidencia_IncidenciaFix == 1 || e.IdTipusIncidencia_IncidenciaFix == 4 ||  e.IdTipusIncidencia_IncidenciaFix== 12){
                    segonsJustificats = segonsJustificats + numeroSegonsEntreDates(e.DataInici_IncidenciaFix,e.DataFi_IncidenciaFix);
                }else{
                    if((contingut.computHores/5/60) >(numeroSegonsEntreDates(e.DataInici_IncidenciaFix,e.DataFi_IncidenciaFix)/ (60 * 60) / 1000)){
                        segonsJustificats = segonsJustificats + numeroSegonsEntreDates(e.DataInici_IncidenciaFix,e.DataFi_IncidenciaFix);
                    }else{
                        segonsJustificats = segonsJustificats + (contingut.computHores/5)*60*60*1000;
                    }
                }
            }
        }     //segonsNoJustificats = segonsNoJustificats + numeroSegonsEntreDates(e.DataInici_IncidenciaFix,e.DataFi_IncidenciaFix);      
    })  
    }


if(jornadaIniciada || jornadaFinalitzada)
{

    if(llistatFixatges !== undefined){
        
        
        //Com sempre d'una entrada i ha d'haver un valor de sortida, mirarem si el llistat de fixatges es parell o senar. Si es senar, vol dir que el treballador encara te una entrada sense sortida
        if(llistatFixatges.length % 2 === 0){

            //La jornada te principis i finals. Podem calcular el número d'hores totals
           
            llistatFixatges.forEach((d,index) =>  {
               
                if(d.IdTipus_Fixatge === "1" || d.IdTipus_Fixatge === "2" ){
                    //Quan entrada es Teletreball, Presencial o Normal:
                    
                    segonsTotalJornada = segonsTotalJornada + numeroSegonsEntreDates(d.DataHora_Fixatge,llistatFixatges[index+1].DataHora_Fixatge);
                }
                });
                segonsTotalJornada = segonsTotalJornada + segonsJustificats/60;
                horesTreballades = convertirSegonsaHores(segonsTotalJornada);
                minutsTreballats = convertirSegonsAMinuts(segonsTotalJornada);


                return <p className='alert alert-soft-dark'>{t('ResumHores.resumAvui')} <strong>{ horesTreballades } {t('Generics.hores')} {t('Generics.caracIoY')} {minutsTreballats} {t('Generics.minuts')}</strong> </p> 
        }else
        {
            llistatFixatges.forEach((d,index) => {
                
                if((d.IdTipus_Fixatge === "1" || d.IdTipus_Fixatge === "2" ) && index !== llistatFixatges.length-1){
                    //Quan entrada es Teletreball, Presencial o Normal:
                    segonsTotalJornada = segonsTotalJornada + numeroSegonsEntreDates(d.DataHora_Fixatge,llistatFixatges[index+1].DataHora_Fixatge);
                }

                
                });
                //A partir d'aquí, tenim el número total de segons dels trams de jornada que tenen final. Ara haurem de calcular la diferencia de segons amb el temps actual (Quan es renderitza el component amb el useEffect cada 10 segons)
                
                segonsTotalJornada = segonsTotalJornada + numeroSegonsEntreDates2(llistatFixatges[llistatFixatges.length-1].DataHora_Fixatge,contadorJornadaIniciada);
                segonsTotalJornada = segonsTotalJornada + segonsJustificats/60;


                horesTreballades = convertirSegonsaHores(segonsTotalJornada);
                minutsTreballats = convertirSegonsAMinuts(segonsTotalJornada);
                return <p className='alert alert-soft-dark'>{t('ResumHores.resumAvui')} <strong>{ horesTreballades } {t('Generics.hores')} {t('Generics.caracIoY')} {minutsTreballats} {t('Generics.minuts')}</strong> 
                    <br/>
                    <small> { contadorJornadaIniciada.toString()  } </small>
                    
                </p> 
                
        }


        
       
    }
}else{

    return (
        //No mostrem cap element, si encara no tenim iniciada la jornada
        <>
            
        </>
    )

}


}else
{
    return <p>Sense dades al state</p>
}



    return (
        <>
            
        </>
    )
}
