import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MenuPrincipal } from '../ui/MenuPrincipal'
import { UsuariLoginat } from '../ui/UsuariLoginat';

export const ManualsScreen = () => {
    const [t, i18n] = useTranslation('generic');


  return (
    <div className="position-relative" >   



        <div className="card card-shadow card-login">
              <div className="row">
                <div className="col-md-6">
                  <div className="card-body">
                  <div className='visible-xs'>
                    <UsuariLoginat />
                    <hr />
                        </div>
                     <MenuPrincipal />
                      <div className="">
                            <div className="mb-5">
                                <h3 className="card-title">{t('Header.Manuals')}</h3>
                                <p className='mt-4'>{t('Manuals.introTutorials')}</p>
                                <ul>
                                  <li>{t('Manuals.acces')} <a href="https://youtu.be/gKUpmkzqFeg" target="_blank"><u>tutorial</u></a><small> ({t('Manuals.versioCat')})</small></li>
                                  <li>{t('Manuals.acces')} <a href="https://youtu.be/QmxMm6FoMaw" target="_blank"><u>tutorial</u></a><small> ({t('Manuals.versioEsp')} )</small></li>
                                </ul>
                                <p>{t('Manuals.appTittle')} </p>
                                <ul>
                                  <li>{t('Manuals.appTxt')}
                                    <ul>
                                      <li><a href="https://play.google.com/store/apps/details?id=com.simplescan.scanner&hl=es&gl=US" target="_blank"><u>Simple Scan - Android</u></a></li>
                                      <li><a href="https://play.google.com/store/apps/details?id=com.intsig.camscanner" target="_blank"><u>CamScanner - Android</u></a></li>
                                      <li><a href="https://apps.apple.com/es/app/scanner-pro-esc%C3%A1ner-de-pdf/id333710667" target="_blank"><u>Scanner Pro - iPad o iPhone</u></a></li>
                                      <li><a href="https://apps.apple.com/es/app/youtube/id544007664" target="_blank"><u>CamScanner - iPad o iPhone</u></a></li>
                        
                                      </ul>
                                    </li>
                                </ul>
                                <p>{t('Manuals.mesInfoTitle')} </p>
                                <ul>
                                  <li>{t('Manuals.acces')}  <a href="https://web.gencat.cat/ca/actualitat/reportatges/calendarilaboral/calendari-laboral-2022/" target="_blank"><u>{t('Manuals.calendariLaboral')} </u></a></li>
                                  <li>{t('Manuals.acces')}  <a href="https://ajuntament.barcelona.cat/calendarifestius/ca/" target="_blank"><u>{t('Manuals.calendariFestes')} </u></a></li>
                                </ul>
                            </div>
                        </div>
                     
                    </div>
                </div>
                <div className="col-md-6 d-md-flex flex-column bg-soft-primary p-8 p-md-5 bg-comb">
                <div className='hidden-xs'>
                    <UsuariLoginat />
                        <hr />
                  </div>
                </div>

          </div>
        </div>
        <figure className="position-absolute top-0 end-0 zi-n1 d-none d-sm-block mt-n7 me-n10 width4rem">
              <img className="img-fluid" src="../assets/svg/components/pointer-up.svg" alt="Image Description" />
            </figure>
           
            <figure className="position-absolute bottom-0 start-0 d-none d-sm-block ms-n10 mb-n10 width15rem">
              <img className="img-fluid" src="../assets/svg/components/curved-shape.svg" alt="Image Description" />
            </figure>
        </div>
  )
}
