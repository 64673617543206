import React from 'react'
import { useTranslation } from 'react-i18next';
import { MenuPrincipal } from '../ui/MenuPrincipal';
import { UsuariLoginat } from '../ui/UsuariLoginat';

export const PreferenciesScreen = () => {
    const [t, i18n] = useTranslation('generic');
    return (
        <div className="position-relative" >   
    
    
    
            <div className="card card-shadow card-login">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="card-body">
                      <div className='visible-xs'>
                        <UsuariLoginat />
                        <hr />
                            </div>
                         <MenuPrincipal />
                          
                         
                        </div>
                    </div>
                    <div className="col-md-6 d-md-flex flex-column bg-soft-primary p-8 p-md-5 bg-comb">
                    <div className='hidden-xs'>
                        <UsuariLoginat />
                            <hr />
                      </div>
                    </div>
    
              </div>
            </div>
            <figure className="position-absolute top-0 end-0 zi-n1 d-none d-sm-block mt-n7 me-n10 width4rem">
                  <img className="img-fluid" src="../assets/svg/components/pointer-up.svg" alt="Image Description" />
                </figure>
               
                <figure className="position-absolute bottom-0 start-0 d-none d-sm-block ms-n10 mb-n10 width15rem">
                  <img className="img-fluid" src="../assets/svg/components/curved-shape.svg" alt="Image Description" />
                </figure>
            </div>
      )
}
