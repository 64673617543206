
import {  recuperarDicFixatgesCalendari } from '../../actions/configAplicacioAction';
import { FinalMesConsultat, IniciMesConsultat, recuperarFixatgesEntreDates, recuperarIncidenciaEntreDates, recuperarInfoEntreDates } from '../../actions/configCalendariAction';
import { uiAmagarIncidenciesAction, uiAmagarIncidenciesRestaHoresAction } from '../../actions/ui';

//Aquest component recupera la informacio del mes en el primer clic
export  const RecuperarInfoCalendar = (auth,ui,calendari) => {
    
    return(dispatch)=>{
    
    let avui = new Date()
    let final = "";
    let inici = "";

    if(calendari.inici === undefined || calendari.final === undefined ){
        //si es aixi ens guardem l'inici i el final del mes actual
        inici = avui.getFullYear()+"-"+(avui.getMonth()+1)+"-01"
        if((avui.getMonth()+1)==12){
             final = (avui.getFullYear()+1)+"-01-01"
        }else{
             final = avui.getFullYear()+"-"+(avui.getMonth()+2)+"-01"
        }
        //i guardem aqui la informacio per poder utilizar-la per recuperar inf
        dispatch(IniciMesConsultat(inici));
        dispatch(FinalMesConsultat(final));
    }else{
        //si no es el primer cop (li donem a Historic dins el propi calendari) utilitzem la informacio ja definida anteriorment
        inici = dispatch(IniciMesConsultat(calendari.inici));
        final = dispatch(FinalMesConsultat(calendari.final));
    }
    dispatch(recuperarDicFixatgesCalendari(auth.tokenJwt))
    dispatch(recuperarFixatgesEntreDates(auth.tokenJwt,inici,final));
    dispatch(recuperarIncidenciaEntreDates(auth.tokenJwt,inici,final));
    dispatch(recuperarInfoEntreDates(auth.tokenJwt,inici,final));
    if(ui.incidencia){
        dispatch(uiAmagarIncidenciesAction())
    }
    if(ui.incidenciaRestaHores){
        dispatch(uiAmagarIncidenciesRestaHoresAction())
    }
}
}


