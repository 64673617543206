import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom'
import { login, iniciarPeticioLoginAsincrona, recuperarLoginAction, iniciarPeticioLoginAsincronaAxios, recuperarLoginActionAxios} from '../../actions/auth';
import { useForm } from '../../hooks/useForm';
import isEmail from 'validator/lib/isEmail';
import { esborrarErrorAction, crearErrorAction } from '../../actions/ui.js'
import { uiFinalitzarCarrega } from '../../types/types'
import { HeaderPublic } from '../ui/HeaderPublic';
import { FooterPublic } from '../ui/FooterPublic';
import {useTranslation} from "react-i18next";



export const LoginScreen = (props) => {

const dispatch = useDispatch(); //Per despachar accions
const state = useSelector(state => state); // Això retorna l'esta actual de l'aplicació, per poder accedir a tot el contingut emmagatzemat allà
const { ui } = useSelector(state => state); // Per desestructuració d¡objectes, podem agafar l'objecte desitjat del nostre state
const { loading, msgError } = useSelector(state => state.ui); //Per desestructuració, podem agafar propietats d'objectes directament
const { auth } = useSelector(state => state);
  //Farem servir useEffect. Es una eina de REact que permet executar una acció automàticament, afegint una condició de dependencia d'estat o no
const [passwordShown, setPasswordShown] = useState(false);    
const [t, i18n] = useTranslation('generic');
const [msgError2, setMsgError2] = useState(false);   
const navegacio = useNavigate();



const [ formValues, handleInputChange] = useForm({
    email : '',
    clau: ''
})

const { email, clau} = formValues;


useEffect(() => {
  //Mirem si la propietat estaLoginat canvia. Si es així, redireccionem a la part privada
 if(auth.tokenJwt !== undefined)
 {

  navegacio('/');
  setMsgError2(false);
 }
 
}, [auth]);



const handleLogin = (e) =>
{
    e.preventDefault();
    if(isFormValid()){
      setMsgError2(true);
       //Todo Cridar a API de login. SI tot es OK, crearem l'acció de Login amb les dades de l'usuari rebudes del servei
       dispatch(iniciarPeticioLoginAsincronaAxios(email,clau));
       
      //  navegacio('/');
      //dispatch(iniciarPeticioLoginAsincrona(email,clau));
    }else{
    }

}
const handleKeyPress = (e) => {
  if(e.key === 'Enter'){
    e.preventDefault();
    if(isFormValid()){
        setMsgError2(true);
       //Todo Cridar a API de login. SI tot es OK, crearem l'acció de Login amb les dades de l'usuari rebudes del servei
       dispatch(iniciarPeticioLoginAsincronaAxios(email,clau));    
      
      //  navegacio('/');
      //dispatch(iniciarPeticioLoginAsincrona(email,clau));
    }else{
    }
  }
}

const togglePassword = () => {
  setPasswordShown(!passwordShown);
};


const isFormValid = ()=>{
  let esCorrecte = true;
  if(email.trim().length === 0 || clau.trim().lenght === 0){
    setMsgError2(true);
    dispatch(crearErrorAction('Camps obligatoris'));
      esCorrecte = false;
  }else if(!isEmail(email))
  {
    setMsgError2(true);
    dispatch(crearErrorAction('Email incorrecto'));
      esCorrecte = false;
  }else{
    setMsgError2(false);
    dispatch(esborrarErrorAction());
  }
  
 
  return esCorrecte;
}

    return (

        <>  
        <HeaderPublic />
        <div className='position-relative' >    
        <div className="card card-shadow card-login">
              <div className="row">
                <div className="col-md-10">
                  <div className="card-body">
          
          { msgError!=null && msgError2 ?  //això es una condició, si es diferent de null
              <div className='alert alert-danger'>
              { msgError}
              </div>    
            
             :null  
          }

          

       
                <div className="text-center">
                  <div className="mb-5">
                    <h3 className="card-title">{t('login.title')}</h3>
                  
                  </div>
                  
                  
                </div>

                
                <div className="mb-4">
                  <label className="form-label" htmlFor="signinSrEmail">{t('login.txtEmail')}</label>
                  <input type="email" value={ email } className="form-control form-control-lg" name="email" id="signinSrEmail" tabIndex="1" placeholder={t('login.txtEmail')} aria-label="email@address.com" onChange={ handleInputChange } required />
                  <span className="invalid-feedback">Please enter a valid email address.</span>
                </div>
               

               
                <div className="mb-4">
                  <label className="form-label" htmlFor="signinSrPassword" tabIndex="0">{t('login.txtClau')}</label>

                  <div className="input-group-merge">
                    <input type={passwordShown ? "text" : "password"} className="js-toggle-password form-control form-control-lg" name="clau" id="signinSrPassword" placeholder={t('login.txtClau')} aria-label="8+ characters required" onChange={ handleInputChange } required minLength="8"
                           onKeyDown={handleKeyPress}
                           data-hs-toggle-password-options='{
                             "target": "#changePassTarget",
                             "defaultClass": "bi-eye-slash",
                             "showClass": "bi-eye",
                             "classChangeTarget": "#changePassIcon"
                           }' value={ clau } />
                    <a id="changePassTarget" className="input-group-append input-group-text" onClick={togglePassword} >
                      <i id="changePassIcon" className="bi-eye"></i>
                    </a>

                    <span className="invalid-feedback">Please enter a valid password.</span>
                  </div>
                </div>
             

               

                <div className="d-grid gap-4">
                  <button onClick={ handleLogin } className="btn btn-primary btn-lg" disabled={ loading } >{t('login.btnIniciar')}</button>
                
                </div>
                </div>
                
                </div>
                <div className="col-md-2 d-md-flex justify-content-center flex-column bg-soft-primary p-8 p-md-5 bg-comb">

                 
                  <p> </p>
                  

                 
                </div>
                </div>
                </div> 
                <figure className="position-absolute top-0 end-0 zi-n1 d-none d-sm-block mt-n7 me-n10 width4rem">
              <img className="img-fluid" src="../assets/svg/components/pointer-up.svg" alt="Image Description" />
            </figure>
           
            <figure className="position-absolute bottom-0 start-0 d-none d-sm-block ms-n10 mb-n10 width15rem">
              <img className="img-fluid" src="../assets/svg/components/curved-shape.svg" alt="Image Description" />
            </figure>
                
            
              
            
        </div>
        
            
            
        <FooterPublic />
        </>

    )
}
