
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import 'moment-timezone';


import ReactTooltip from "react-tooltip";

export const ToolTipInfoIncidencia = (props) => {
    const [t, i18n] = useTranslation('generic');
    const { ui } = useSelector(state => state);
    const dispatch = useDispatch();
 
  
    return (
      <>
        <a  data-event='click focus' data-tip={props.desc_Incidencia} > <i className="bi bi-info-circle-fill"></i> </a>
        <ReactTooltip globalEventOff='click' getContent={(dataTip) => 
            <span>{dataTip}</span>  }place="right" effect='solid' type="info" >
        </ReactTooltip>      
     </>

    );
  
}
