import React from 'react'


export const CalendarVacancesEvent = ({ event }) => {

    const { nomTreballador,cognomTreballador } = event;
    return (
        <div>
        {nomTreballador} {cognomTreballador}
        </div>
    )
}
