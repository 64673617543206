import React, { useEffect, useState } from 'react'
import 'moment-timezone';
import { numeroSegonsEntreDates,  operacionsRestaHores, convertirMilisHores, convertirMilisMinuts, convertirSegonsHores, convertirSegonsMinuts, numeroSegonsEntreDates2 } from '../../Helpers/funcionsDates'
import { useTranslation } from 'react-i18next';
import {useSelector } from 'react-redux';
import moment from 'moment';




export const ResumTotalHoresSetmana = (props) => {

    const { configUsuari,cp } = useSelector(state => state);
    const {contingut} = configUsuari;
    const [contadorJornadaIniciada, setContadorJornadaIniciada] = useState(new Date());
    const {llistatFixatgesSetmana} = cp;
    let diaAmbIncidencia = false;
    let diaAvui = false;
    let tipusDia = "Laborable";
    let segonsTotalSetmana = 0;
    let segonsTeleTreballTotalSetmana = 0;
    let segonsPresencialTotalSetmana = 0;
    let horesTreballades = 0;
    let horesRestants = 0;
    let horesTreballadesTeletreball = 0;
    let horesTreballadesPresencial = 0;
    let minutsTreballats = 0;
    let minutsRestants = 0;
    let minutsTreballatsTeletreball = 0;
    let minutsTreballatsPresencial = 0;
    let segonsRestants = 0;
    let computSegonsTreballatsDeMes = 0;
    let horesDesMes = 0;
    let minutsDeMes = 0;
    let minutsBossaHores = 0;
    let horesBossaHores = 0;
    let minutBossaHores = 0;

    let deMes = false;

    const [t, i18n] = useTranslation('generic');
    useEffect(() => {

        var dataActual = new Date();
        var offset = dataActual.getTimezoneOffset();
        //Si el offset de la data es 0, el navegador ens està retornant la hora en UTC. Haurem de sumar 1 hora o 2, depenen de si es horari d'estiu o hivern.
        //TODO Mirar si es horari d'hivern (+1) o estiu (+2) i fer la suma pertinent 
        //Haurem d'incorporar un paràmetre al configUsuari per establir la suma i recuperar-ho al state.
        if (offset == 0) {
            dataActual.setHours(dataActual.getHours() + contingut.offsetUTC / 60);

        }


        //S'executa cada 10 segons, per actualitzar els minuts, si la jornada està iniciada
        const interval = setInterval(
            () => setContadorJornadaIniciada(dataActual),
            10000
        )

        //console.log(Date());




        return () => {
            clearInterval(interval);
        }
    }, [contadorJornadaIniciada]);

    let borsaHoresDia;
    if(cp.llistatIncidenciesSetmana !== undefined){
        //1000 ja que son miliSegons
        segonsTotalSetmana = segonsTotalSetmana + cp.llistatIncidenciesSetmana.computMinuts*1000*60;
    }
    if(configUsuari!== undefined){
        configUsuari.contingut.llistatDiesBorsaHores.diesBorsa.forEach((e,index) => {
            if( e.estat === '1' && e.dataBorsa>=llistatFixatgesSetmana.dia_inici_setmana &&e.dataBorsa<=llistatFixatgesSetmana.dia_final_setmana){
  
                minutsBossaHores = minutsBossaHores + e.minutsDemanats * 60 *1000;

            }
        })
        segonsTotalSetmana = segonsTotalSetmana + minutsBossaHores;
    }
    props.llistatFixatgesSetmana.fixatges_setmanals.forEach(e => {
        e.fixatges.forEach((d, index) => {

            if (d.IdTipus_Fixatge === "1" || d.IdTipus_Fixatge === "2") {
                //Quan entrada es Teletreball, Presencial o Normal:
                if (e.fixatges[index + 1] === undefined) {
                    //Si es el dia d'avui, no el marcaram com incidencia. 
                    let dataAvui = new Date();
                    dataAvui.setHours(0, 0, 0, 0); //Eliminem les hores per poder comparar entre dates
                    let dataDiaRebut = new Date(e.dia);
                    dataDiaRebut.setHours(0, 0, 0, 0);
                    if (dataAvui.getTime() !== dataDiaRebut.getTime()) {
                        diaAmbIncidencia = true;
                    } else {
                        diaAvui = true;
                    }
                    if(moment(d.DataHora_Fixatge).format("YYYY-MM-DD") == moment(dataAvui).format("YYYY-MM-DD")){
                    
                        segonsTotalSetmana = segonsTotalSetmana + numeroSegonsEntreDates2(d.DataHora_Fixatge,contadorJornadaIniciada);
                        if (d.IdTipus_Fixatge === "2") {
                            segonsTeleTreballTotalSetmana = segonsTeleTreballTotalSetmana + numeroSegonsEntreDates2(d.DataHora_Fixatge, contadorJornadaIniciada);

                        }
                        if (d.IdTipus_Fixatge === "1") {
                            segonsPresencialTotalSetmana = segonsPresencialTotalSetmana + numeroSegonsEntreDates2(d.DataHora_Fixatge,contadorJornadaIniciada);

                        } 
                    }


                } else {
                    //Sumatori de tots els segons realitzats per semana
                    //Mitjançant if tambe obtenim els segons Totals de Teletreball i presencial
                    segonsTotalSetmana = segonsTotalSetmana + numeroSegonsEntreDates(d.DataHora_Fixatge, e.fixatges[index + 1].DataHora_Fixatge);
                    if (d.IdTipus_Fixatge === "2") {
                       
                        segonsTeleTreballTotalSetmana = segonsTeleTreballTotalSetmana + numeroSegonsEntreDates(d.DataHora_Fixatge, e.fixatges[index + 1].DataHora_Fixatge);

                    }
                    if (d.IdTipus_Fixatge === "1") {
                        segonsPresencialTotalSetmana = segonsPresencialTotalSetmana + numeroSegonsEntreDates(d.DataHora_Fixatge, e.fixatges[index + 1].DataHora_Fixatge);

                    }


                }

            }
        });
        
        //144.000
        //obtenim els segons restants del treballador a complir en el que resta de setmana
        //I els pasem a hores i minuts
        segonsRestants = operacionsRestaHores(segonsTotalSetmana, props.hores)

        if(segonsRestants > 0){ 
            deMes = false;
       }else{
        deMes = true;
        computSegonsTreballatsDeMes = segonsRestants*-1;
        segonsRestants=0;
        horesDesMes = convertirSegonsHores(computSegonsTreballatsDeMes)
        minutsDeMes = convertirSegonsMinuts(computSegonsTreballatsDeMes)
   
   
       }


        horesRestants = parseInt(segonsRestants / (60 * 60));
        minutsRestants = parseInt(segonsRestants / (60) % 60);

        horesTreballades = convertirMilisHores(segonsTotalSetmana)
        minutsTreballats = convertirMilisMinuts(segonsTotalSetmana)

        //Els miliSegons obtinguts anteriorment els pasem a minuts i hores
        horesTreballadesPresencial = convertirMilisHores(segonsPresencialTotalSetmana)
        minutsTreballatsPresencial = convertirMilisMinuts(segonsPresencialTotalSetmana)

        horesBossaHores = convertirMilisHores(minutsBossaHores)
        minutBossaHores = convertirMilisMinuts(minutsBossaHores)

        horesTreballadesTeletreball = convertirMilisHores(segonsTeleTreballTotalSetmana)
        minutsTreballatsTeletreball = convertirMilisMinuts(segonsTeleTreballTotalSetmana)


    });


    return (
        <div>
            {/* <p><strong>{t('ResumHoresSetmana.total')}</strong> {props.hores} {t('Generics.hores')}</p>*/}
            {horesTreballadesTeletreball>0||minutsTreballatsTeletreball>0 ?
            <p><strong>{t('ResumHores.teletreball')}</strong> {horesTreballadesTeletreball} {t('Generics.hores')} {t('Generics.caracIoY')} {minutsTreballatsTeletreball} {t('Generics.minuts')}</p>
            :null
            } 
            {horesTreballadesPresencial>0||minutsTreballatsPresencial>0 ?
            <p><strong>{t('ResumHores.presencial')}</strong> {horesTreballadesPresencial} {t('Generics.hores')} {t('Generics.caracIoY')}  {minutsTreballatsPresencial} {t('Generics.minuts')}</p>
            :null
            } 
            {horesBossaHores>0||minutBossaHores>0 ?
            <p><strong>{t('ResumHores.bossaHores')}</strong> {horesBossaHores} {t('Generics.hores')} {t('Generics.caracIoY')}  {minutBossaHores} {t('Generics.minuts')}</p>
            :null
            } 
            {/* {deMes? 
              <p><strong>Hores de Mes: </strong> {horesDesMes} {t('Generics.hores')} {t('Generics.caracIoY')}  {minutsDeMes} {t('Generics.minuts')}</p> 
            :
           <p><strong>{t('ResumHores.restants')} </strong> {horesTreballades} {t('Generics.hores')} {t('Generics.caracIoY')} {minutsTreballats} {t('Generics.minuts')} </p>
           } */}
           {horesTreballades>0||minutsTreballats>0 ?
           <p><strong>{t('ResumHores.horesTreball')} </strong> {horesTreballades} {t('Generics.hores')} {t('Generics.caracIoY')} {minutsTreballats} {t('Generics.minuts')} </p>
           :null
            } 
            
           {horesRestants>0||minutsRestants>0 ?
           <p><strong>{t('ResumHores.restants')} </strong> {horesRestants} {t('Generics.hores')} {t('Generics.caracIoY')} {minutsRestants} {t('Generics.minuts')} </p>
           :null
            } 
        </div>
    );
}