import { types } from "../types/types";

export const configAplicacioReducer = (state = {},action) => {

    switch (action.type){
        case types.configAplicacioRecuperarInfo:
            return {...state,
                iniciat : action.iniciada
        }
            break;
        case types.configAplicacioRecuperarDicIncidencies:
            return {...state,
                dicIncidencies : action.contingut
                }
        case types.configAplicacioRecuperarDicIncidenciaCalendari:
            return {...state,
                descTipusincidenciaCalendari : action.contingut
                }
        case types.configAplicacioRecuperarInfoFinalitzada:
        return {...state,
            
            finalitzat : action.finalitzada
            }
        default:
            return state;
    }

}

//configAplicacioRecuperarDicIncidencies