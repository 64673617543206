import React, { cloneElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Calendar, momentLocalizer } from 'react-big-calendar';

import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import { messagesCa, messagesEs } from '../../Helpers/calendar-messages-es';
import { CalendarVacancesEvent } from './CalendarVacancesEvent';
import { useDispatch, useSelector } from 'react-redux';
import { FinalMesConsultat, IniciMesConsultat, recuperarIncidenciaMesConsultat, recuperarVacancesEntreDates } from '../../actions/configCalendariAction';
import { useNavigate } from 'react-router-dom';
import { MenuPrincipal } from '../ui/MenuPrincipal';
import { UsuariLoginat } from '../ui/UsuariLoginat';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';


export const CalendarVacancesScreen = () => {
  

    
    const localizer = momentLocalizer(moment); // or globalizeLocalizer
    const { calendari } = useSelector(state => state);
    const { auth,ui } = useSelector(state => state);
    const dispatch = useDispatch(); //Per despachar accions
    const navegacio = useNavigate();
    const animatedComponents = makeAnimated();

    const [t, i18n] = useTranslation('generic');
    moment.locale(t('Generics.idiomaDatetime'))
    const [lastView, setLastView] = useState( localStorage.getItem('lastView') || 'month' );

    const [inici, setInici] = useState("");
    const [final, setFinal] = useState("");
    const [llistatEm, setLlistatEm] = useState([]);
    const [llistatTr, setLlistatTr] = useState([]);
    const [llistatDep, setLlistatDep] = useState([]);
    const [primerCop, setPrimerCop] = useState(true);    
    const consultaDia= moment(new Date())

    let events = [];
    let llistatEmpresa = [];
    let llistatTreb = [];
    let llistatDepartament = [];

    


    useEffect(() => {
        if( primerCop)
       {
            setPrimerCop(false);
       }else{
        dispatch(recuperarVacancesEntreDates(auth.tokenJwt,inici,final,llistatEm,llistatTr,llistatDep))

       }
    }, [llistatEm,llistatTr,llistatDep,inici]) 
        //Mostrem sempre Mes
    const onViewChange = (e) => {
        setLastView(e);
        localStorage.setItem('lastView', e);
    }

    //Quan selec un dia et retorna un obj, fem format i li pasem a DiaConsultat per tindre la data al Redux i poder treballar amb ella
    //mostrem el Modal
    

    //Rang de dates del pag actual
    const onRangeChange = (e) => {
        if(e.start != undefined){
            let inici = moment(e.start).format("YYYY-MM-DD");
            setInici(inici);
            let final = moment(e.end).format("YYYY-MM-DD");
            setFinal(final);  
        }else{
            if(e.length <= 1){
                let inici = moment(e[0]).format("YYYY-MM-DD HH:ss") 
                setInici(inici);
                let final = moment(e[0]).format("YYYY-MM-DD HH:ss")
                setFinal(final);  
            }else{
                let inici = moment(e[0]).format("YYYY-MM-DD") 
                setInici(inici);
                let final = moment(e[6]).format("YYYY-MM-DD")
                setFinal(final); 
            }
       }
        
        
        dispatch(IniciMesConsultat(inici));
        dispatch(FinalMesConsultat(final));
        //aixo al inicar calendari
       // dispatch(recuperarInfoVacancesEntreDates(auth.tokenJwt))
    }
    //Color del event i estil  si entra al if de isMine es el color de fixatges sino d incidencies
    const eventStyleGetter = ( events, start, end, isSelected ) => {
        
        let newStyle  = {
            //color incidencia
            backgroundColor: "#ff9103", //taronja
            borderRadius: '0px',
            opacity: 1,
            color: '#2d374b'

        }
        //si no es incidencia
        //no inci no alerta no laborable
        
        if (events.Accepta_Vacances == 1){
            newStyle.backgroundColor = "#00c884" //verd
          }
        //no inci si alerta no laborable
        if (events.Accepta_Vacances == 0){
            newStyle.backgroundColor = "#e44554" // vermell
            newStyle.color = "#FFFFFF" 
           
          }

        return {
            style:newStyle 
        }
    };
    


    if(calendari !=undefined && calendari.vacancesMesConsultat !=undefined){

        events = calendari.vacancesMesConsultat.contingut.map((d) =>
   ({ dataInici:moment(d.Data_Vacances_I).toDate(),
      dataFinal:moment(d.Data_Vacances_F).toDate(),
      Accepta_Vacances:d.Accepta_Vacances, 
      DinsAny_Vacances:d.DinsAny_Vacances, 
      nomTreballador:d.Titol_Element,
      cognomTreballador:d.Cognom_Treb,
      IdTipusVacances:d.IdTipusVacances, 
      }))
      
      
    }
    if(calendari !=undefined && calendari.vacancesInfo !=undefined){
        if(calendari.vacancesInfo.contingut.llistatEmpresa !=undefined){
           
            llistatEmpresa = calendari.vacancesInfo.contingut.llistatEmpresa.map((d)=>({
                value: d.IdEmpresa, 
                label: d.Desc_Empresa 
            }))
        }
    }

    if(calendari !=undefined && calendari.vacancesInfo !=undefined){
        if(calendari.vacancesInfo.contingut.llistatTreb !=undefined){
           
            llistatTreb = calendari.vacancesInfo.contingut.llistatTreb.map((d)=>({
                value: d.IdElement, 
                label: d.Titol_Element +" " +d.Cognom_Treb
            }))
        }
    }
    if(calendari !=undefined && calendari.vacancesInfo !=undefined){
        if(calendari.vacancesInfo.contingut.llistatDepartament !=undefined){
           
            llistatDepartament = calendari.vacancesInfo.contingut.llistatDepartament.map((d)=>({
                value: d.IdDepartament, 
                label: d.Desc_Departament
            }))
        }
    }
 let llista=[];
    const handleChange = (event) =>{
        llista=event.map((d)=>({
            value:d.value,
            label:d.label      
        }))
        setLlistatEm(llista) 
    }
    
    const handleChangeTreb = (event) =>{
        llista=event.map((d)=>({
            value:d.value,
            label:d.label 
        }))
        setLlistatTr(llista) 
    }
    const handleChangeDep = (event) =>{
        llista=event.map((d)=>({
            value:d.value,
            label:d.label 
        }))
        setLlistatDep(llista) 
    }
    const selectStyles = {
        menu: base => ({
          ...base,
          zIndex: 100
        })
      };
    return (
        
            <div className='position-relative'>
                <div className='card card-shadow card-login'>
                    <div className='row'>
                   
                            <div className="card-body">

                            <div className='hidden-xs'>
                        <UsuariLoginat />
                            </div>
                                <div className='visible-xs'>
                                     <UsuariLoginat />
                                     <hr />
                                </div>
                                
                                    <MenuPrincipal />
                                   
                                    <h3>{t('Header.Historic')}</h3>
                                    {/* aqui esta el button per navegar a la pagina principal */}
                                    <div className='row mb-3'>
                                    <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        styles={selectStyles}
                                    // defaultValue={[colourOptions[4], colourOptions[5]]}
                                        placeholder ={"Empresa"}
                                        isMulti
                                        options={llistatEmpresa}
                                        onChange={handleChange}
                                        className='col-4'
                                        />
                                        <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        styles={selectStyles}
                                    // defaultValue={[colourOptions[4], colourOptions[5]]}
                                    placeholder ={"Treballador"}
                                        isMulti
                                        options={llistatTreb}
                                        onChange={handleChangeTreb}
                                        className='col-4'
                                        />
                                        <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                    // defaultValue={[colourOptions[4], colourOptions[5]]}
                                    placeholder ={"Departament"}
                                        isMulti
                                        styles={selectStyles}
                                        options={llistatDepartament}
                                        onChange={handleChangeDep}
                                        className='col-4'
                                        />
                                    <br/>
                                   </div>
                                    <div className="calendar-screen">
                                        {calendari.vacancesInfo != undefined ?
                                        
                                        i18n.language =="ca" ?
                                        
                                        <Calendar
                                            localizer={localizer}
                                            events={events}
                                            startAccessor="dataInici"
                                            endAccessor="dataFinal"
                                            eventPropGetter={ eventStyleGetter }
                                            messages={messagesCa}
                                            onRangeChange={onRangeChange}
                                            selectable={ true }
                                            views={['month','day']}
                                            onView={ onViewChange }
                                            components={{
                                                event: CalendarVacancesEvent
                                            }}
                                        />
                                        :
                                        <Calendar
                                            localizer={localizer}
                                            events={events}
                                            startAccessor="dataInici"
                                            endAccessor="dataFinal"
                                            eventPropGetter={ eventStyleGetter }
                                            messages={messagesEs}
                                            onRangeChange={onRangeChange}
                                            selectable={ true }
                                            views={['month','day']}
                                            onView={ onViewChange }
                                            components={{
                                                event: CalendarVacancesEvent
                                            }}
                                        />
                                        :null}
                                    </div>
                            </div>
                        </div>
                 
                </div>
                <figure className="position-absolute top-0 end-0 zi-n1 d-none d-sm-block mt-n7 me-n10 width4rem">
              <img className="img-fluid" src="../assets/svg/components/pointer-up.svg" alt="Image Description" />
            </figure>
           
            <figure className="position-absolute bottom-0 start-0 d-none d-sm-block ms-n10 mb-n10 width15rem">
              <img className="img-fluid" src="../assets/svg/components/curved-shape.svg" alt="Image Description" />
            </figure>
            </div>
            
        
        
    )
}

