import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import "flatpickr/dist/themes/material_green.css";
import "react-datetime/css/react-datetime.css";
import { useForm } from '../../hooks/useForm';
import { useNavigate } from 'react-router-dom'
import { crearErrorAction, esborrarErrorAction} from '../../actions/ui';
import { addIncidenciaAsync, addIncidenciaAsync2 } from '../../actions/configIncidenciaAction';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/ca';
import Flatpickr from "react-flatpickr";
import 'flatpickr/dist/l10n/es.js';
import 'flatpickr/dist/l10n/cat.js';


import { ToolTipInfoIncidencia } from './ToolTipInfoIncidencia';




export const FormulariIncidencia = (props) => {

  const dispatch = useDispatch(); //Per despachar accions
  const state = useSelector(state => state);
  const { auth } = useSelector(state => state);
  const { ui } = useSelector(state => state);
  const { loading, msgError } = useSelector(state => state.ui);
  const { configAplicacio, cp } = useSelector(state => state);
  const [t, i18n] = useTranslation('generic');
  const [tipusIncidencies, setTipusIncidencies] = useState('');
  const { diaConsultat } = (props);
  const [mostrafitxer, setMostraFitxer] = useState(0);
  const [tipusDateFormat, setTipusDateFormat] = useState(0);
  const [fitxer, setFitxer] = useState(null);
  const [dateFormat, setDateFormat] = useState("d/m/Y H:i");
  const [mostraMesIncidencies, setMostraMesIncidencies] = useState(false);
  const [hores, setHores] = useState(props.hores);
  const [minuts, setMinuts] = useState(props.minuts);
  
  const [dataInici, setDataInici] = useState(moment(diaConsultat).add(hores, 'h').add(minuts,'m').format('DD/MM/YYYY H:mm'));
  let suma = parseInt(hores) + 1
  const [dataFinal, setDataFinal] = useState(moment(diaConsultat).add(suma, 'h').add(minuts,'m').format('DD/MM/YYYY H:mm'));


  //Flatpickr.localize(Catalan);
  //new GER**********************************************************************************************************************************
   //const [dataInici, setDataInici] = useState(new Date());
   //const [dataFinal, setDataFinal] = useState(new Date());
  // ****************************************************************************************************************************************

  let listRadio = "";
  let listSempre = "";
  let listDiaConsultat = "";
  let listAdmin = "";
  let parseDataFinal;
  let parseDataInici;
  const navegacio = useNavigate();
  let idiomaDatetime = t('Generics.idiomaDatetime');


  const content = ( props ) => {
    const { IdIncidencia,fitxer_Incidencia,hores_Incidencia,desc_Incidencia,desc_Incidencia_es,info_Incidencia_es,info_Incidencia} = (props);
    return(
          
            <div className='form-check mb-1' key={IdIncidencia}>
                  <input type="radio" id={"tipusIncidenciaRadio" + IdIncidencia} className="form-check-input" name="tipusIncidencia" value={IdIncidencia}
                    onChange={(e) => { setTipusIncidencies(e); setMostraFitxer(fitxer_Incidencia);setTipusDateFormat(hores_Incidencia); {hores_Incidencia == 0 ? setDateFormat("d/m/Y"):setDateFormat("d/m/Y H:i")}}} />
                  <label className="form-check-label" htmlFor={"tipusIncidenciaRadio" + IdIncidencia}> {i18n.language =="ca" ? desc_Incidencia:i18n.language =="es"?desc_Incidencia_es:null} </label>       
                  <ToolTipInfoIncidencia desc_Incidencia={i18n.language =="ca" ? info_Incidencia :i18n.language =="es"?info_Incidencia_es:null}/>
                </div>
          
          )}
  if (configAplicacio !== undefined && configAplicacio.dicIncidencies !== undefined) {

    listSempre = configAplicacio.dicIncidencies.map((d) =>
    d.esVisiblePerDefecte == 1 ?
    content(d)    
    : null

  )
    if (!cp.jornadaIniciada) {

      listRadio = configAplicacio.dicIncidencies.map((d) =>
        d.entrada_Incidencia == 1 && d.esVisiblePerDefecte == 0 && d.admin == 0?
        content(d)
          : null

      )
    } else if (cp.jornadaIniciada) {

      listRadio = configAplicacio.dicIncidencies.map((d) =>
        d.sortida_Incidencia == 1  && d.esVisiblePerDefecte == 0 && d.admin == 0?
        content(d)
          : null
      )
    }
    if (diaConsultat != undefined) {
    
      listDiaConsultat = configAplicacio.dicIncidencies.map((d) =>
      d.esVisiblePerDefecte == 0 && d.admin == 0 ?
      content(d)
        :null
      )
    }
    
      listAdmin = configAplicacio.dicIncidencies.map((d) =>
      
      content(d)
      )
    

  }


  const [formValues, handleInputChange] = useForm({
    observacions: ''
  })

  const { observacions } = formValues;


  useEffect(() => {
    //Mirem si la propietat estaLoginat canvia. Si es així, redireccionem a la part privada
    if (auth.tokenJwt == undefined) {
      navegacio('/');
    }

  }, [auth]);

  //funcio que realitza el dispatch per pujar l'incidencia a la base de dades
  const handleLogin = (e) => {
    e.preventDefault();
   

   
    //comprobem que el form es correcte i que ho no tenim fitxer o si el tenim esta ben pujat

    if (isFormValid() && (pujarFitxer() || mostrafitxer == 0)) {

      if(typeof dataInici == "string"){
                                
        var dateParts = dataInici.split(" ");
        var dateDiaMesAny = dateParts[0].split("/");
        var dateHoraMinut = dateParts[1].split(":");
       // var dateObject = new Date(+dateDiaMesAny[2], dateDiaMesAny[1] - 1, +dateDiaMesAny[0],dateHoraMinut[0],dateHoraMinut[1]);
        if(tipusDateFormat == '0'){
          
          parseDataInici = (moment(new Date(+dateDiaMesAny[2], dateDiaMesAny[1] - 1, +dateDiaMesAny[0],'7','00')).format('YYYY-MM-DD HH:mm:ss'))
        
        }else{
          parseDataInici = (moment(new Date(+dateDiaMesAny[2], dateDiaMesAny[1] - 1, +dateDiaMesAny[0],dateHoraMinut[0],dateHoraMinut[1])).format('YYYY-MM-DD HH:mm:ss'))
          
        }
      }else{
        if(tipusDateFormat==='0'){
          
          parseDataInici=moment(dataInici[0]).format('YYYY-MM-DD')+moment("2022-01-01 7:00:00").format(' HH:mm:ss');
          
        }else{
         parseDataInici=moment(dataInici[0]).format('YYYY-MM-DD HH:mm:ss');
        }
      }
      if(typeof dataFinal == "string"){
        var dateParts2 = dataFinal.split(" ");
        var dateDiaMesAny2 = dateParts2[0].split("/");
        var dateHoraMinut2 = dateParts2[1].split(":");
   // var dateObject = new Date(+dateDiaMesAny2[2], dateDiaMesAny2[1] - 1, +dateDiaMesAny2[0],dateHoraMinut2[0],dateHoraMinut2[1]);
            if(tipusDateFormat == '0'){
              parseDataFinal = (moment(new Date(+dateDiaMesAny2[2], dateDiaMesAny2[1] - 1, +dateDiaMesAny2[0],'22','00')).format('YYYY-MM-DD HH:mm:ss'))
              
            }else{
              parseDataFinal = (moment(new Date(+dateDiaMesAny2[2], dateDiaMesAny2[1] - 1, +dateDiaMesAny2[0],dateHoraMinut2[0],dateHoraMinut2[1])).format('YYYY-MM-DD HH:mm:ss'))
              
            }
      }else{
          if(tipusDateFormat == '0'){
            parseDataFinal=moment(dataFinal[0]).format('YYYY-MM-DD')+moment("2022-01-01 22:00:00").format(' HH:mm:ss');
            
          }else{
            parseDataFinal=moment(dataFinal[0]).format('YYYY-MM-DD HH:mm:ss');

          }
      }
      //tenim fitxer o no com hem fet la compro abans de que esta ben pujat aqui no fa falta
      if (diaConsultat !== undefined) {
              if (moment(parseDataInici).format('YYYY-MM-DD') !== diaConsultat) {
                if(i18n.language =="ca"){
                  dispatch(crearErrorAction('No és del dia Consultat'));
                }else if(i18n.language =="es"){
                  dispatch(crearErrorAction('No es del día Consultado'));
                }
                            
              }                                                 
      }

      if (mostrafitxer == 1) {
        dispatch(addIncidenciaAsync(tipusIncidencies.target._wrapperState.initialValue,auth.tokenJwt,parseDataInici,parseDataFinal,observacions,fitxer,i18n.language,auth.admin));
      } else {
        dispatch(addIncidenciaAsync2(tipusIncidencies.target._wrapperState.initialValue,auth.tokenJwt,parseDataInici,parseDataFinal,observacions,i18n.language,auth.admin));
      }
      //dispatch(recuperarIncidenciesAsync(auth.tokenJwt));
      //dispatch(recuperarIncidenciesSetmanaActual(auth.tokenJwt));


    } else {

    }

  }

  //Funcio que ens retorna un boolean per saber si l'estat del form es correcte 
  //tant les obseervacions estan omplertes com tenim una incidencia selecionad
  const isFormValid = () => {
    let esCorrecte = true;
    if (observacions.trim().length <= 0 ) {
      if(i18n.language =="ca"){
        dispatch(crearErrorAction('Camp Observacions Obligatori'));
    }else if(i18n.language =="es"){
      dispatch(crearErrorAction('Campo Observaciones Obligatorio'));
    }
      esCorrecte = false;
    } 
    if (tipusIncidencies == '') {
      if(i18n.language =="ca"){
        dispatch(crearErrorAction('Cap incidència marcada'));
    }else if(i18n.language =="es"){
      dispatch(crearErrorAction('Ninguna incidencia marcada'));
    }
      esCorrecte = false;
    } 
    
    return esCorrecte;
  }

  useEffect(() => {
    setFitxer(null)
  }, [mostrafitxer])


  const pujarFitxer = (e) => {
    //setFitxer(e);
    let fitxervalid = false;


    if (fitxer !== null) {

      var fileExtension = fitxer.name.split('.').pop();
      if (fileExtension === "pdf" || fileExtension === "PDF") {
        //Mirem que el fitxer no sigui mes gran que

        if (fitxer.size < 5120000) {

          fitxervalid = true;
        } else {
          if(i18n.language =="ca"){
              dispatch(crearErrorAction('Arxiu mes gran que 5MB'));
              }else if(i18n.language =="es"){
              dispatch(crearErrorAction('Archivo mas grande que 5MB'));
          }
          fitxervalid = false
          setFitxer(null)
        }
      } else {
        
        if(i18n.language =="ca"){
          dispatch(crearErrorAction('Arxiu no vàlid (PDF)'));
          }else if(i18n.language =="es"){
            dispatch(crearErrorAction('Archivo no válido (PDF)'));
          }
      }
    } else {
      if(mostrafitxer == 1){
          if(i18n.language =="ca"){
            dispatch(crearErrorAction('Cap arxiu seleccionat'));
          }else if(i18n.language =="es"){
            dispatch(crearErrorAction('Ningún archivo seleccionado'));
      }
      }
    }
    return fitxervalid;
  }
  if(msgError !== null){
    setTimeout(()=>dispatch(esborrarErrorAction()),10000); 
    }  
  return (
    <div className='row'>
      <h5>{t('FormulariIncidencies.crear')}</h5>
      <div className='mx-4'>
        {
          <>
        {listSempre} 
        {!mostraMesIncidencies ?
        <button onClick={e=>setMostraMesIncidencies(!mostraMesIncidencies)} className="btn btn-primary btn-sm m-1"  >+</button>
        :null}
        </>
        }
        {mostraMesIncidencies ?
        auth.admin==1?
        <>
              {listAdmin}
              <button onClick={e=>setMostraMesIncidencies(!mostraMesIncidencies)} className="btn btn-primary btn-sm m-1"  >-</button>
              </>
        :
          diaConsultat != undefined ?
              <>
              {listDiaConsultat}
              <button onClick={e=>setMostraMesIncidencies(!mostraMesIncidencies)} className="btn btn-primary btn-sm m-1"  >-</button>
              </>
            :
              <>
              {listRadio}
              <button onClick={e=>setMostraMesIncidencies(!mostraMesIncidencies)} className="btn btn-primary btn-sm m-1"  >-</button>
              </>
          :null
        }
        </div>
      {msgError &&  //això es una condició, si es diferent de null
        <div className='alert alert-danger'>
          {msgError}
        </div>
      }
      <div className='container mt-5' >
        <div className="row mb-4">
          <div className='col-md-4'>
            <label className="form-label align-right" >{t('FormulariIncidencies.txtObservacions')}</label>
          </div>
          <div className='col-md-8'>
            <textarea value={observacions} name="observacions" className="form-control form-control-lg" id="signinSrEmail" tabIndex="1" placeholder={t('FormulariIncidencies.placeholderObservacions')} onChange={handleInputChange} required />
            <span className="invalid-feedback">Please enter a valid infomation .</span>
          </div>
        </div>



        <div className="row mb-4">
          <div className='col-md-4'>
            <label className="form-label align-right" >{t('FormulariIncidencies.txtDataInici')}:</label>
          </div>
       
          <div className='col-md-8'>

             <Flatpickr
              options={
                {dateFormat:dateFormat,enableTime: true,locale:idiomaDatetime,
                parseDate:false,defaultDate:dataInici}
              }
              data-enable-time
              //per definir el valor inicial
              // value={new Date(dataInici)}
              onChange={(dataInici) => setDataInici(dataInici)} 
              /> 
          </div>
        </div>

        <div className="row mb-4">
          <div className='col-md-4'>
            <label className="form-label align-right" >{t('FormulariIncidencies.txtDataFinal')}:</label>
          </div>
          <div className='col-md-8'>
          <Flatpickr
          options={
            {dateFormat:dateFormat,
            time_24hr: true,
            parseDate:false,
            locale: idiomaDatetime,
            defaultDate:dataFinal}
          }
          data-enable-time       
          onChange={(dataFinal) => setDataFinal(dataFinal)} /> 

          </div>
        </div>
        {
            mostrafitxer == 1 ?
        <div className='row'><div className='col-md-12 m-4'>
         
              <input type="file"
                id="file"
                name="file"
                onChange={(e) => setFitxer(e.target.files[0])} />
             
        </div>
        </div>
         : null
        }

        <div className="text-center">
          <button onClick={handleLogin} className="btn btn-primary btn-sm m-1" disabled={loading} >{t('FormulariIncidencies.btnDadesIncidencia')}</button>
        </div>
      </div>


    </div>
  )
}
