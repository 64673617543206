import React from 'react';
import ReactDOM from 'react-dom';
import { TipusRegistreAcces_Test } from './Component/TipusRegistreAcces_test';


import { FixatgeApp } from './FixatgeApp';

import './index.css';



ReactDOM.render(

  
   <FixatgeApp />,

  document.getElementById('content')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

